import { Component, OnInit } from '@angular/core';
import { SettingService } from '../../services/setting.service';
import { ActivatedRoute } from '@angular/router';
import { Background } from 'src/app/models/background.model';

@Component({
    selector: 'confirm-mail',
    templateUrl: './confirm-mail.component.html',
    styleUrls: ['./confirm-mail.component.css']
})
export class ConfirmMailComponent implements OnInit {
    elementLoading:boolean;
    email: string;
    background = new Background();
    
    constructor(
        private settingService: SettingService,
        private route: ActivatedRoute) { }

    ngOnInit() {
        this.elementLoading = false;
        this.route.params.subscribe(params => {
            if (params['email'])
                this.email = ""+params['email'];
        });
        this.settingService.getBackground()
            .subscribe(data => {
                if (data) {
                    this.background = data;
                }
            })
    }
}
