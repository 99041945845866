<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">{{model.title}}</h5>
    <button type="button" class="close" data-dismiss="modal" (click)="bsModalRef.hide()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h5 style="white-space: pre-wrap;">{{model.content}}</h5>
  </div>
  <div class="modal-footer">
    <button class="btn btn-success btn-fw" type="button" (click)="btnClicked()"><i
        class="mdi mdi-checkbox-marked-circle">{{model.mainActionButtonText}}</i></button>
  </div>
</div>