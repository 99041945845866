import { Component, OnInit } from '@angular/core';
import { DropdownModel } from '../../../models/dropdown.model';
import { ActivatedRoute } from '@angular/router';
import { DepartmentService } from '../../../services/department.service';
import { DepartmentDetailModel, GetDepartmentModel } from '../../../models/department.models';
import { LocalService } from 'src/app/services/common/local.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-view-department',
    templateUrl: './view-department.component.html',
    styleUrls: ['./view-department.component.css']
})
export class ViewDepartmentComponent implements OnInit {
    model = new DepartmentDetailModel();
    status: DropdownModel[];
    departmentId: number = 0;
    getModel = new GetDepartmentModel();
    constructor(private route: ActivatedRoute,
        private router: Router,
        private departmentService: DepartmentService) {
    }

    ngOnInit() {
        this.route.params.subscribe(params => {
          this.departmentId = +params['id'];
        });

        this.getModel = new GetDepartmentModel(LocalService.getUserId(), this.departmentId);

        this.departmentService.getDepartments(this.getModel)
            .subscribe(department => {
                this.model = department[0];
            });
    }

    goToProfile(e, id: number) {
      if (e.target.localName === 'a' || e.target.parentElement.localName === 'a'
        || e.target.localName === 'button' || e.target.parentElement.localName === 'button'
        || e.target.localName === 'input') {
        return;
      }
      this.router.navigate(['user-profile', id]);
    }
}
