import { Injectable } from '@angular/core';
import { BaseService } from './common/base.service';
import { HttpClient } from '@angular/common/http';
import { ErrorService } from './common/error.service';
import { GlobalService } from './global.service';
import { Observable } from 'rxjs';
import { API_ENDPOINT } from '../app.constants';
import { GetProjectModel, ProjectDetailModel } from '../models/project.models';

@Injectable({
  providedIn: 'root'
})
export class ProjectService extends BaseService {

  constructor(protected http: HttpClient,
    protected errorHandler: ErrorService,
    protected globalService: GlobalService) {
    super(http, errorHandler, globalService);

  }

  addProject(project: any): Observable<any> {
    return this.postFormData(API_ENDPOINT.add_project, project, true);
  }

  editProject(project: any): Observable<any> {
    return this.postFormData(API_ENDPOINT.edit_project, project, true);
  }


  getById(id: number): Observable<any> {
    return this.get(`${API_ENDPOINT.list_project}?id=${id}`);
  }

  deleteProject(id: number) {
    return this.post(`${API_ENDPOINT.delete_project}?projectId=${id}`);
  }

  getProjects(model: GetProjectModel): Observable<any> {
    return this.post(API_ENDPOINT.list_project, model, true);
  }
  getUserProjectManagement(): Observable<any> {
    return this.get(API_ENDPOINT.get_user_project_role, null, true);
  }
  getProjectDetail(dateTime: string, projectId: number): Observable<ProjectDetailModel> {
    return this.get(`${API_ENDPOINT.detail_project}?dateTime=${dateTime}&projectId=${projectId}`, null, true);
  }
}
