<div class="row">

  <!-- Right Sidebar -->
  <div class="col-lg-12">
      <div class="card-box">
          <!-- Left sidebar -->
          <div class="inbox-leftbar">

            <a routerLink="/reward-compose" class="btn btn-danger btn-block waves-effect waves-light">Soạn mẫu</a>

            <div class="mail-list mt-4">
              <a routerLink="/reward-list" class="list-group-item border-0"><i
                  class="mdi mdi-inbox font-18 align-middle mr-2"></i>Biểu mẫu<span
                  class="badge badge-danger float-right ml-2 mt-1"></span></a>

              <a routerLink="/reward-list/2" class="list-group-item border-0"><i
                  class="mdi mdi-file-document-box font-18 align-middle mr-2"></i>Bản nháp<span
                  class="badge badge-info float-right ml-2 mt-1"></span></a>
              <a [routerLink]="['/reward-list', 1, true ]" class="list-group-item border-0">
                <i class="mdi mdi-send font-18 align-middle mr-2"></i>Đã gửi</a>

            </div>

          </div>
          <!-- End Left sidebar -->

          <div class="inbox-rightbar">

              <div class="">

                  <form #form="ngForm" (submit)="sendSaveOrSave(form, true)" autocomplete="on">

                    <div class="form-group mb-2">
                        <p class="label mb-1 mt-4 text-dark">Chọn nhân viên</p>

                        <select class="select2 form-control select2-multiple" multiple="multiple" id="selectUser" multiple data-placeholder="Chọn ...">
                            <option *ngFor="let user of users" [value]="user.id">{{user.fullName}}</option>
                        </select>
                    </div>
                    <div class="form-group mb-2">
                        <app-text-box class="app-text mt-4" type="text" name ="subject" title="Tiêu đề"
                        [(ngModel)]="model.subject" placeholder="Nhập số nội dung tiêu đề....." >

                        </app-text-box>
                    </div>
                    <div class="form-group ">
                        <app-text-box class="app-text mt-4" type="number" name="value" title="Tiền thưởng"
                        [(ngModel)]="model.value" placeholder="Nhập số tiền thưởng....." >

                        </app-text-box>
                    </div>
                      
                      <div class="form-group mt-4">
                          <div id="summernote" class="summernote">
                          </div>
                      </div>

                      <div class="form-group m-b-0">
                          <div class="text-right">
                              <button type="button" class="btn btn-success waves-effect waves-light mr-2" (click)="sendSaveOrSave(form, false)" title="Lưu">
                                  <i class="fa fa-floppy-o"></i></button>
                              <button type="button" class="btn btn-danger waves-effect waves-light mr-2" (click)="deleteTemplate()" title="Xóa mẫu">
                                  <i class="fa fa-trash-o"></i></button>
                              <button class="btn btn-custom submit-btn waves-effect waves-light"> <span>Gửi  </span> 
                                <i class="fa fa-send m-l-10"></i> </button>
                          </div>
                      </div>

                  </form>
              </div> <!-- end card-->

          </div>

          <div class="clearfix"></div>
      </div>

  </div> <!-- end Col -->

</div>
