import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ForgotPasscodeService } from '../../services/forgotpasscode.service';
import { Router } from '@angular/router';
import { SettingService } from '../../services/setting.service';
import { Background } from 'src/app/models/background.model';

@Component({
    selector: 'forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
    elementLoading: boolean;
    email: string;
    background = new Background();
    constructor(
        private forgotPascodeService: ForgotPasscodeService,
        private router: Router,
        private settingService: SettingService) { }

    ngOnInit() {
        this.elementLoading = false;
        this.settingService.getBackground()
            .subscribe(data => {
                if (data) {
                    this.background = data;
                }
            })
    }

    submit(form: NgForm) {
        if (form.invalid) {
            return;
        }
        this.elementLoading = true;
        this.forgotPascodeService.forgotPasscodeRequest(this.email)
            .subscribe(res => {
                this.router.navigate(['confirm-mail', this.email]);
            },
                error => {
                    this.elementLoading = false;
                    this.email = "";
                });
    }

}
