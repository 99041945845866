<div *ngIf="isAdmin" class="row">
  <div class="col-sm-4">
    <button type="button" class="btn btn-custom btn-rounded w-md waves-effect waves-light mb-4" routerLink="/add-project"><i class="mdi mdi-plus-circle"></i> Tạo dự án</button>
  </div>
  <div class="col-sm-8">
    <div class="project-sort float-right">
      <div class="project-sort-item">
        <form class="form-inline">
          <div class="form-group">
            <label for="phase-select">Tình trạng :</label>
            <select class="form-control ml-2 form-control-sm" id="phase-select" (change)="getProjectByStatus($event.target.value)">
              <option value="0">Tất cả dự án({{originList?.length > 0 ? originList.length : 0}})</option>
              <option value="1">Đang mở</option>
              <option value="2">Tạm dừng</option>
              <option value="3">Hoàn thành</option>
              <option value="4">Đã đóng</option>
            </select>
          </div>
          <div class="form-group">
            <label for="sort-select">Sắp xếp :</label>
            <select class="form-control ml-2 form-control-sm" id="sort-select" (change)="sortListProject($event.target.value)">
              <option value="byProjectName">Tên dự án</option>
              <option value="byStatus">Trạng thái</option>
              <option value="byCustomerName">Khách hàng</option>
            </select>
          </div>
        </form>
      </div>
    </div>
  </div>

</div>
<div *ngIf="projects" class="row">
    <div class="col-md-6 col-lg-4" *ngFor="let proj of projects">

        <div class="card-box project-box ribbon-box">

            <div *ngIf="proj.status == 1" class="ribbon-two ribbon-two-custom"><span>{{proj.statusModel.name}}</span></div>
            <div *ngIf="proj.status == 2" class="ribbon-two ribbon-two-warning"><span>{{proj.statusModel.name}}</span></div>
            <div *ngIf="proj.status == 3" class="ribbon-two ribbon-two-danger"><span>{{proj.statusModel.name}}</span></div>
            <div *ngIf="proj.status == 4" class="ribbon-two ribbon-two-dark"><span>{{proj.statusModel.name}}</span></div>

            <div *ngIf="isAllowModify(proj.id)" class="dropdown float-right">

                <a class="dropdown-toggle card-drop arrow-none" data-toggle="dropdown" aria-expanded="false">
                    <h3 class="m-0 text-muted"><i class="mdi mdi-dots-horizontal"></i></h3>
                </a>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="btnGroupDrop1">
                    <a class="dropdown-item" [routerLink]="['/edit-project', proj.id]"><span class="mdi mdi-table-edit"></span>    Chỉnh sửa</a>
                    <a *ngIf="isAdmin" class="dropdown-item" [routerLink]="['/detail-project', proj.id]"><span class="mdi mdi-account-card-details"></span>    Thông tin chi tiết</a>
                    <a class="dropdown-item" [routerLink]="['/scrum-pocker', proj.id]"><span class="mdi mdi-timetable"></span>    Rooms</a>
                    <a *ngIf="isAdmin" class="dropdown-item" (click)="deleteProject(proj.id)"><span class="mdi mdi-delete-circle"></span>    Xóa</a>
                </div>

            </div>
            <div *ngIf="!isAllowModify(proj.id)" class="dropdown float-right">

                <a class="dropdown-toggle card-drop arrow-none" data-toggle="dropdown" aria-expanded="false">
                    <h3 class="m-0 text-muted"><i class="mdi mdi-dots-horizontal"></i></h3>
                </a>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="btnGroupDrop1">
                    <a class="dropdown-item" [routerLink]="['/view-project', proj.id]"><span class="mdi mdi-information-outline"></span>    Xem thông tin</a>
                    <a class="dropdown-item" [routerLink]="['/scrum-pocker', proj.id]"><span class="mdi mdi-timetable"></span>    Rooms</a>
                </div>

            </div>

            <p class="project-hide-overflow ml-3">

                <span class="thumb-lg member-thumb mr-15 m-b-10 d-inline-block">
                  <app-shared-image [srcImg]="proj.projectLogo | image"
                                    [classImg]="'rounded-circle img-thumbnail'"
                                    [altImg]="'friend'"
                                    [titleImg]="proj.projectName">
                  </app-shared-image>
                </span>

                <span class="projectNameDisplay mt-4">

                  <span class="text-dark font-15 text-bold">{{proj.projectName}}
                    </span>
                    <span class="text-dark font-13 text-uppercase">{{proj.customerName}}
                    </span>

                </span>

            </p>

            <p class="text-dark font-13 description-hide-overflow" title="{{proj.description}}">
                {{proj.description}}
            </p>

            <div class="project-members mt-4 memnber-hide-overflow">
                <label class="mr-3">Thành viên: </label>
                <div *ngFor="let user of proj.userInfos">
                    <a [routerLink]="['/user-profile', user.id]" data-toggle="tooltip" data-placement="top" title="{{user.fullName}}">
                        <div class="thumb-md member-thumb">
                            <app-shared-image [srcImg]="user.avatar | image" [classImg]="'rounded-circle img-thumbnail ' + (user.isLeader ? 'project-leader-mark' : '')" [altImg]="'friend'">
                            </app-shared-image>
                        </div>
                    </a>
                </div>
            </div>

        </div>
    </div>
</div>

<div *ngIf="isAdmin" class="text-right mb-3">
  <nz-pagination [nzPageIndex]="pageIndex" [nzTotal]="totalProject" [nzPageSize]="limit" (nzPageIndexChange)="onPageIndexChange($event)" (nzPageSizeChange)="onPageSizeChange($event)" nzShowSizeChanger></nz-pagination>
</div>

<div *ngIf="projects.length == 0" class="row">
    <p class="text-muted font-18">Hiện tại không có dự án nào !!!</p>
</div>
