<div id="metting" class="card">
  <div class="card-body">
    <full-calendar *ngIf="options" #fullcalendar [editable]="options.editable" [events]="eventsModel"
      [eventLimit]="options.eventLimit" [header]="options.header" (dateClick)="dateClick($event)"
      [buttonText]="options.buttonText" [aspectRatio]="options.aspectRatio" (eventDrop)="onChangeDay($event)"
      [plugins]="options.plugins" (eventClick)="eventClick($event)" (eventResize)="eventResize($event)"
      [locale]="options.locale" (eventRender)="eventRender($event)" [customButtons]="options.customButtons">
    </full-calendar>
  </div>
</div>

<ng-template #modalCreateMeeting let-close="close" class="modal-demo">
  <button type="button" class="close" (click)="closeModal()">
    <span>&times;</span><span class="sr-only">Close</span>
  </button>
  <h4 class="custom-modal-title">Tạo cuộc họp</h4>
  <div class="custom-modal-text">
    <form #form="ngForm" autocomplete="false">

      <div class="form-group m-b-20">
        <p *ngIf="isAdmin" class="label mb-1 text-dark">Chọn nhân viên</p>
        <p *ngIf="!isAdmin" class="label mb-1 text-dark">Chọn đồng nghiệp</p>

        <select class="select2 form-control select2-multiple" multiple="multiple" id="selectUser" multiple
          data-placeholder="Chọn thành viên tham gia họp">
          <option *ngFor="let user of users" [value]="user.id">{{user.fullName}}</option>
        </select>
        
      </div>

      <div class="row form-group">
        <div class="col-5">
          <label for="timeStart">Thời gian bắt đầu họp</label>
          <nz-date-picker name="modalShowData.timeStart" nzSize="default" nzFormat="{{formatDateConstant}}"
            [(ngModel)]="modalShowData.timeStart">
          </nz-date-picker>
          <timepicker [(ngModel)]="modalShowData.timeStart" [showMeridian]="false" name="timeStart"
            class="timepicker-modal text-center"></timepicker>
        </div>
        <div class="col-7">
          <label for="content">Nội dung cuộc họp</label>
          <textarea required name="content" placeholder="Nội dung cuộc họp" [(ngModel)]="modalShowData.content"
            rows="5">
                      </textarea>
          <div invalidmessage="content">
            <p *invalidType="'required'">Bạn vui lòng nhập nội dung nhé!</p>
          </div>
        </div>

      </div>

      <div class="form-group row text-right float-right button-group">
        <div class="col-12">
          <button *ngIf="!isEdit" class="btn btn-submit btn-custom waves-effect waves-light mr-2"
            (click)="createMeeting(form)">Xác Nhận</button>
          <button *ngIf="!isEdit" class="btn waves-effect waves-light btn-danger" (click)="closeModal()">Hủy</button>
          <button *ngIf="isEdit" class="btn waves-effect waves-light btn-danger mr-2"
            (click)="deleteMeeting(modalShowData.id)">Xóa</button>
          <button *ngIf="isEdit" class="btn waves-effect waves-light btn-custom mr-2"
            (click)="editMeeting(form, modalShowData.id)">Lưu</button>
          <button *ngIf="isEdit" class="btn waves-effect waves-light btn-light" (click)="closeModal()">Đóng</button>
        </div>
      </div>

    </form>
  </div>
</ng-template>

<ng-template #dialogModal let-close="close">
  <app-confirm-dialog [model]="dialogModel"></app-confirm-dialog>
</ng-template>