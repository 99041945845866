<div class="account-pages">

  <!-- Begin page -->
  <div class="accountbg"
    [ngStyle]="{'background-image': 'url(' + background.backgroundImage + ')', 'background-repeat': 'no-repeat', 'background-size': 'cover', 'background-position': 'center'}">
  </div>

  <div class="wrapper-page account-page-full">

    <div class="card">
      <div class="card-block">

        <div class="account-box">

          <div class="card-box p-5">
            <h2 class="text-uppercase text-center pb-4">
              <a href="" class="text-success">
                <span>
                  <app-shared-image [srcImg]="'assets/images/logo.png'"
                                    [altImg]="'image'"
                                    [height]="72">
                  </app-shared-image>
                </span>
              </a>
            </h2>

            <form #form="ngForm" (submit)="submit(form)" autocomplete="on">

              <div class="form-group m-b-20 row">
                <div class="col-12">
                  <app-text-box type="password" title="Mật khẩu mới" name="newPassCode" placeholder="*********"
                    required="true" [(ngModel)]="model.newPassCode" password="true" validateEqual="confPassCode"
                    reverse="true">
                    <div invalidmessage="newPassCode">
                      <p *invalidType="'required'">Bạn vui lòng nhập mật khẩu mới</p>
                      <p *invalidType="'password'">mật khẩu mới không hợp lệ</p>
                    </div>
                  </app-text-box>
                </div>
              </div>

              <div class="form-group m-b-20 row">
                <div class="col-12">
                  <app-text-box type="password" title="Xác nhận lại mật khẩu" name="confPassCode"
                    placeholder="*********" required password="true" validateEqual="newPassCode" reverse="false"
                    [(ngModel)]="model.confPassCode">
                    <div invalidmessage="confPassCode">
                      <p *invalidType="'required'">Bạn vui lòng nhập lại mật khẩu mới</p>
                      <p *invalidType="'password'">Vui lòng xác nhận lại mật khẩu mới hợp lệ</p>
                      <p *invalidType="'validateEqual'">Xác nhận lại mật khẩu không đúng</p>
                    </div>
                  </app-text-box>
                </div>
              </div>

              <div class="form-group row m-t-10">
                <div class="col-12 text-center">
                  <button class="btn btn-block btn-custom waves-effect waves-light">Gửi yêu cầu</button>
                </div>
              </div>

              <div class="form-group d-flex justify-content-between">
                <div class="col-sm-12 text-center">
                  <p class="text-muted">Quay về trang <a routerLink="/login" class="text-dark m-l-5"><b>Đăng Nhập</b></a></p>
                </div>
              </div>

              <p class="text-dark font-14 mt-2">
                - Mật khẩu phải có độ dài từ 6-12 kí tự.<br />
                - Mật khẩu phải có ít nhất một kí tự viết hoa.<br />
                - Mật khẩu phải có ít nhất một kí tự viết thường.<br />
                - Mật khẩu phải có ít nhất một kí tự đặc biệt (@, !, #, $, ...)<br />
                - Mật khẩu phải có ít nhất một kí tự số.
              </p>

            </form>
          </div>
        </div>

      </div>
    </div>

    <div class="m-t-40 text-center">
      <p class="account-copyright">{{background.footerText}}</p>
    </div>

  </div>

</div>
