import { formatDate } from '@angular/common';
import { FORMAT_DATE_CONSTANT, MOMENT_FORMAT } from '../app.constants';
import * as moment from 'moment';

export class DatetimeUtils {

  public static setTimeOffset(date: Date): Date {
    let time = new Date();
    time.setHours(date.getHours());
    time.setMinutes(date.getMinutes());
    time.setSeconds(date.getSeconds());
    return time;
  }

  public static toShortDateTimeFormat(date: string): string {
    return formatDate(date, FORMAT_DATE_CONSTANT.short_date_time_format, 'en');
  }

  public static toShortDateFormat(date: string): string {
    return formatDate(date, FORMAT_DATE_CONSTANT.short_date_format, 'en');
  }

  public static toShortDateStandard(date: string): string {
    return formatDate(date, FORMAT_DATE_CONSTANT.short_date_standard, 'en');
  }

  public static toShortDateTimeStandard(date: string): string {
    return formatDate(date, FORMAT_DATE_CONSTANT.short_date_time_standard, 'en');
  }

  public static toDateFormatMM_DD_YYYY_HH_MM_SS(date: string): string {
    return formatDate(date, FORMAT_DATE_CONSTANT.date_format_mm_dd_yyyy_HH_mm_ss, 'en');
  }

  public static toDateFormatTwelveHours(date: string): string {
    return formatDate(date, FORMAT_DATE_CONSTANT.date_time_format_twelve_hours, 'en');
  }

  public static toDateStandard(date: string, format: string): string {
    return formatDate(date, format, 'en');
  }
  public static isLeapYear(year) {
    return (((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0));
  }

  public static getDaysInMonth(year, month) {
    return [31, (DatetimeUtils.isLeapYear(year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
  }

  public static addMonths(date, value) {
    var d = new Date(date),
      n = date.getDate();
    d.setDate(1);
    d.setMonth(d.getMonth() + value);
    d.setDate(Math.min(n, DatetimeUtils.getDaysInMonth(d.getFullYear(), d.getMonth())));
    return d;
  }

  //////////////////////// use moment js //////////////////////////////////

  public static convertServerTimeString(date: Date): string {
    return moment(date).format(MOMENT_FORMAT.server_time);
  }

  public static convertServerDateString(date: Date): string {
    return moment(date).format(MOMENT_FORMAT.server_date);
  }

  public static convertServerDateTimeString(date: Date, time: Date): string {
    return DatetimeUtils.convertServerDateString(date) + ' ' + DatetimeUtils.convertServerTimeString(time);
  }

}
