import { Pipe, PipeTransform } from '@angular/core';
//
import { DEFAULT_IMAGE } from '../utils/constants';

@Pipe({
  name: 'image',
})
export class ImagePipe implements PipeTransform {
  transform(value: string) {
      if(!value || value === ''){
        value = DEFAULT_IMAGE;
      }
    return value;
  }
}
