<div *ngIf="isAdmin" class="row">
  <div class="col-sm-4">
    <button type="button" class="btn btn-custom btn-rounded w-md waves-effect waves-light mb-4" routerLink="/add-department"><i class="mdi mdi-plus-circle"></i> Tạo phòng ban</button>
  </div>
  <div class="col-sm-8">
    <div class="project-sort float-right">
      <div class="project-sort-item">
        <form class="form-inline">
          <div class="form-group">
            <label for="phase-select">Tình trạng :</label>
            <select class="form-control ml-2 form-control-sm" id="phase-select" (change)="getDepartmentByStatus($event.target.value)">
              <option value="0">Tất cả phòng ban({{originList?.length > 0 ? originList.length : 0}})</option>
              <option value="1">Đang hoạt động</option>
              <option value="2">Dừng hoạt động</option>
              <option value="3">Sớm ra mắt</option>
            </select>
          </div>
          <div class="form-group">
            <label for="sort-select">Sắp xếp :</label>
            <select class="form-control ml-2 form-control-sm" id="sort-select" (change)="sortListDepartment($event.target.value)">
              <option value="0">Tên phòng ban</option>
              <option value="1">Trạng thái</option>
            </select>
          </div>
        </form>
      </div>
    </div>
  </div>

</div>
<div *ngIf="departments" class="row">
  <div class="col-md-6 col-lg-4" *ngFor="let dept of departments">

    <div class="card-box project-box ribbon-box">

      <div *ngIf="dept.status == 1" class="ribbon-two ribbon-two-success"><span>{{dept.statusModel.name}}</span></div>
      <div *ngIf="dept.status == 2" class="ribbon-two ribbon-two-danger"><span>{{dept.statusModel.name}}</span></div>
      <div *ngIf="dept.status == 3" class="ribbon-two ribbon-two-warning"><span>{{dept.statusModel.name}}</span></div>

      <div *ngIf="isAdmin" class="dropdown float-right">

        <a class="dropdown-toggle card-drop arrow-none" data-toggle="dropdown" aria-expanded="false">
          <h3 class="m-0 text-muted"><i class="mdi mdi-dots-horizontal"></i></h3>
        </a>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="btnGroupDrop1">
          <a class="dropdown-item" [routerLink]="['/edit-department', dept.id]"><span class="mdi mdi-table-edit"></span>    Chỉnh sửa</a>
          <a class="dropdown-item" (click)="deleteDepartment(dept.id)"><span class="mdi mdi-delete-circle"></span>    Xóa</a>
        </div>

      </div>
      <div *ngIf="!isAdmin" class="dropdown float-right">

        <a class="dropdown-toggle card-drop arrow-none" data-toggle="dropdown" aria-expanded="false">
          <h3 class="m-0 text-muted"><i class="mdi mdi-dots-horizontal"></i></h3>
        </a>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="btnGroupDrop1">
          <a class="dropdown-item" [routerLink]="['/view-department', dept.id]"><span class="mdi mdi-information-outline"></span>    Xem thông tin</a>
        </div>

      </div>

      <p class="project-hide-overflow ml-3">
        <span class="thumb-lg member-thumb mr-15 m-b-10 d-inline-block">
          <app-shared-image [srcImg]="dept.departmentLogo | image"
                            [classImg]="'rounded-circle img-thumbnail'"
                            [altImg]="'friend'"
                            [titleImg]="dept.departmentName">
          </app-shared-image>
        </span>
        <span class="projectNameDisplay mt-4">
            <span class="text-dark font-15 text-bold">
              {{dept.departmentName}}
            </span>
      </span>

      </p>

      <p class="text-dark font-13 description-hide-overflow" title="{{dept.description}}">
        {{dept.description}}
      </p>

      <div class="project-members mt-4 memnber-hide-overflow">
        <label class="mr-3">Thành viên: </label>
        <div *ngFor="let user of dept.usersInfo">
          <a [routerLink]="['/user-profile', user.id]" data-toggle="tooltip" data-placement="top" title="{{user.fullName}}">
            <div class="thumb-md member-thumb">
              <app-shared-image [srcImg]="user.avatar | image"
                                [classImg]="'rounded-circle img-thumbnail ' + (user.roleId == 1 ? 'project-leader-mark' : '')"
                                [altImg]="'friend'">
              </app-shared-image>
            </div>
          </a>
        </div>
      </div>

    </div>
  </div>
</div>

<div *ngIf="departments && (originList?.length >= 2) && (!isLoadMore)" class="row">
  <div class="col-12">
    <div class="text-center mb-3">
      <a class="btn btn-info btn-lg waves-effect waves-light" (click)="loadMoreDepartments()"><i class="mdi mdi-refresh"></i>Tải thêm phòng ban</a>
    </div>
  </div>
</div>

<div *ngIf="departments.length == 0" class="row">
  <p class="text-muted font-18">Hiện tại không có phòng ban nào !!!</p>
</div>
