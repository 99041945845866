<div class="account-pages">

    <!-- Begin page -->
    <div class="accountbg"
        [ngStyle]="{'background-image': 'url(' + background.backgroundImage + ')', 'background-repeat': 'no-repeat', 'background-size': 'cover', 'background-position': 'center'}">
    </div>

    <div class="wrapper-page account-page-full">

        <div class="card">
            <div class="card-block">

                <div class="account-box">

                    <div class="card-box p-5">
                        <h2 class="text-uppercase text-center pb-4">
                            <a href="" class="text-success">
                                <span>
                                  <app-shared-image [srcImg]="background.logo"
                                              [altImg]="'image'"
                                              [height]="72">
                                  </app-shared-image>
                                </span>
                            </a>
                        </h2>

                        <form #form="ngForm" (submit)="login(form)" autocomplete="on">

                            <div class="form-group m-b-20 row">
                                <div class="col-12">
                                    <app-text-box type="email" title="Tên đăng nhập" name="email" email="true"
                                        placeholder="Tên đăng nhập" required="true" [(ngModel)]="model.email">
                                        <div invalidmessage="email">
                                            <p *invalidType="'required'">Bạn vui lòng nhập email</p>
                                            <p *invalidType="'email'">Vui lòng nhập email hợp lệ</p>
                                        </div>
                                    </app-text-box>
                                </div>
                            </div>

                            <div class="form-group row m-b-20">
                                <div class="col-12">
                                    <a routerLink="/forgot-password" class="text-muted float-right"><small>Quên mật khẩu
                                            của bạn?</small></a>
                                    <app-text-box type="password" title="Mật khẩu" name="passCode"
                                        placeholder="*********" required="true" [(ngModel)]="model.passCode">
                                        <div invalidmessage="passCode">
                                            <p *invalidType="'required'">Bạn vui lòng nhập mật khẩu</p>
                                            <p *invalidType="'password'">Vui lòng nhập mật khẩu hợp lệ</p>
                                        </div>
                                    </app-text-box>
                                </div>
                            </div>

                            <div class="form-group row text-center m-t-10">
                                <div class="col-12">
                                    <button class="btn btn-block btn-custom waves-effect waves-light">Đăng nhập</button>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>

            </div>
        </div>

        <div class="m-t-40 text-center">
            <p class="account-copyright">{{background.footerText}}</p>
        </div>

    </div>

</div>

<ng-template #customLoadingTemplate>
    <div class="custom-class">
    </div>
</ng-template>
<ngx-loading [show]="elementLoading" [template]="customLoadingTemplate">
</ngx-loading>
