import {Component, NgZone, OnInit} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RewardService } from 'src/app/services/reward.service';
import { RewardResponseModel } from 'src/app/models/reward.model';
import { RewardStatus } from 'src/app/models/enums';
import { DialogService } from 'src/app/services/dialog.service';
declare var $: any;
@Component({
    selector: 'reward-list',
    templateUrl: './reward-list.component.html',
    styleUrls: ['./reward-list.component.css']
})
export class RewardListComponent implements OnInit {
    viewStatus: RewardStatus = RewardStatus.None;

    titleSearch: string = '';
    // sort row data table
    sortName: string | null = null;
    sortValue: string | null = null;
    listOfRewards: Array<RewardResponseModel> = [];
    listOfDisplayRewards: Array<RewardResponseModel> = [];

    constructor(
        private rewardService: RewardService,
        private route: ActivatedRoute,
        private dialogService: DialogService,
        private router: Router,
        private zone: NgZone) {
    }

    ngOnInit() {
        this.route.params.subscribe(params => {
            if (params['status']) {
                this.viewStatus = +params['status'];
            }
            this.getRewardTemplate(this.viewStatus);

        });
    }

    ngAfterViewInit() {
        let that = this;
        (function () {
            $(document).on('click', '.deleteReward', (e) => {
                that.removeReward(e.currentTarget.dataset.itemId);
            });

            $(document).on('click', '.viewReward', (e) => {
              that.zone.run(() => {
                that.router.navigate(['reward-view', e.currentTarget.dataset.itemId]);
              });
            });
        })();
    }

    getRewardTemplate(status: RewardStatus) {
        this.rewardService.getRewardTemplate(status, new Date().getFullYear()).subscribe(data => {
            this.listOfRewards = data;
            this.listOfDisplayRewards = [...this.listOfRewards];
        });
    }

    removeReward(rewardId: number) {
        this.dialogService.confirm("Xóa mẫu", "Bạn có chắc muốn xóa mẫu khen thưởng này?", () => {
            this.rewardService.deleteRewardTemplate(rewardId).subscribe(() => {
                this.dialogService.message("Thông báo", "Bạn đã xóa mẫu khen thưởng thành công!!!", () => {
                    this.getRewardTemplate(this.viewStatus);
                }, "Đóng");
            });
        });
    };


    sort(sort: { key: string; value: string }): void {
      this.sortName = sort.key;
      this.sortValue = sort.value;
      this.search();
    }

    search(): void {
      const filterFunc = (item: RewardResponseModel) => true;
      const data = this.listOfRewards.filter(item => filterFunc(item));
      if (this.sortName && this.sortValue) {
        this.listOfDisplayRewards = data.sort((a, b) =>
          this.sortValue === 'ascend'
            ? a[this.sortName!] > b[this.sortName!]
              ? 1
              : -1
            : b[this.sortName!] > a[this.sortName!]
            ? 1
            : -1
        );
      } else {
        this.listOfDisplayRewards = data;
      }
    }

    // search by title
    searchByTitle() {
      if(this.titleSearch && this.titleSearch.length > 0) {
        this.listOfDisplayRewards = this.listOfRewards.filter(reward => reward.subject.toLowerCase().includes(this.titleSearch.toLowerCase()))
      } else {
        this.listOfDisplayRewards = [...this.listOfRewards];
      }
    }
}
