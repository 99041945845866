import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';
import { BehaviorSubject } from 'rxjs';
import { DialogModel, FormDialogModel } from '../models/dialog.model';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { InputDialogComponent } from '../components/dialog/input-dialog/input-dialog.component';

declare function messageDialogOpening(title, message, okBtntext, okCallback?: () => void): any;
declare function confirmDialogOpening(title, message, okBtntext, cancelBtnText, okCallback: () => void, cancelCallback: () => void): any;
declare function inputDialogOpening(title, content, typeOfInput, okCallback: (result) => void, cancelCallback: () => void, okBtnText, cancelBtnText);

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  bsModalRef: BsModalRef;
  constructor(private modalService: BsModalService) {

  }

  formModal(title: string, initContent: string, hint: string, formSubmitCallback: (string) => void, cancelCallback: () => void = null) {

    let formModel = new FormDialogModel();

    formModel.title = title;
    formModel.initContent = initContent;
    formModel.formSubmitCallback = formSubmitCallback;
    formModel.cancelCallback = cancelCallback;
    formModel.submitBtnText = "Gửi";
    formModel.hint = hint;

    const initialState = {
      model: formModel,
    };
    this.bsModalRef = this.modalService.show(InputDialogComponent, { initialState });
  }

  hideModal() {
    this.bsModalRef.hide();
  }

  confirm(title: string, content: string, confirmBtn?: () => void, cancelBtn?: () => void, confirmBtnText = 'Có', cancelBtnText = 'Không') {
    confirmDialogOpening(title, content, confirmBtnText, cancelBtnText, confirmBtn, cancelBtn);
  }

  message(title: string, content: string, okBtn?: () => void, okBtnText = 'OK') {
    messageDialogOpening(title, content, okBtnText, okBtn);
  }

  input(title: string, content: string, hint: string, typeOfInput: string, okBtn?: (content: string) => void, cancelBtn?: () => void, okBtnText = 'OK', cancelBtnText = 'Hủy') {
    inputDialogOpening(title, content, typeOfInput, okBtn, cancelBtn, okBtnText, cancelBtnText);
  }
}

