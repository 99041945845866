<div class="row">
  <div class="col-xl-8">
    <div class="card-box">
      <h4 class="header-title mb-3">Danh sách tạm ứng trong tháng {{date | date: "MM/yyyy"}}</h4>
      <div class="d-flex justify-content-end mb-3">
        <span class="d-flex align-items-center mr-2">
          Tìm: &nbsp;
          <app-text-box type="text"
                        [placeholder]="'lý do..'"
                        [(ngModel)]="reasonSearch"
                        (ngModelChange)="searchByReason()">
          </app-text-box>
      </span>
        <button type="button" class="btn btn-light" (click)="updateMonth(1)">
          <i class="mdi mdi-arrow-left-bold"></i>
        </button>
        <button type="button" class="btn btn-light mr-1" (click)="updateMonth(2)">
          <i class="mdi mdi-arrow-right-bold"></i>
        </button>
        <button type="button" class="btn btn-custom mr-2" (click)="updateMonth(0)">
          Hôm nay
        </button>
        <button (click)="showModal()" type="button" class="btn btn-custom btn-fw waves-effect float-right"
          title="Tạm ứng lương">
          <i class="mdi mdi-plus"></i>
        </button>
      </div>

      <div class="table-data">
        <nz-table
          #filterTable
          nzShowPagination
          nzShowSizeChanger
          [nzData]="listOfDisplayAdvanceds"
          nzPageSize="50">
          <thead (nzSortChange)="sort($event)" nzSingleSort>
            <tr>
              <th nzShowSort nzSortKey="dateTime">
                Ngày yêu cầu
              </th>
              <th nzShowSort nzSortKey="cash">
                Số tiền ứng
              </th>
              <th nzShowSort nzSortKey="reason">
                Lý do
              </th>
              <th nzShowSort nzSortKey="reason">
                Trạng thái
              </th>
              <th>
                Tác vụ
              </th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let item of filterTable.data">
              <td>
                {{item.dateTime | date: 'dd/MM/yyyy'}}
              </td>
              <td>
                {{item.cash | appNumber}} VNĐ
              </td>
              <td>
                {{item.reason}}
              </td>
              <td *ngIf="item.status == 1">
                <span class="badge badge-success font-13">Đã chấp nhận</span>
              </td>
              <td *ngIf="item.status == 2">
                <span class="badge badge-danger font-13">Đã từ chối</span>
              </td>
              <td *ngIf="item.status == 3">
                <span class="badge badge-warning font-13">Chờ xử lí</span>
              </td>
              <td>
                <button id="cancelCashAdvance" *ngIf="item.status == 3" type="button" title="Hủy yêu cầu này"
                [attr.data-item-id]="item.id" class="btn-sm btn-danger">
                  <i class="mdi mdi-delete-circle"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </nz-table>
      </div>
    </div>
  </div>

  <div class="col-xl-4">
    <div class="card-box">
      <h4 class="m-t-0 header-title">Tổng số tiền lương trong tháng</h4>


      <div id="donut-chart">
        <div id="donut-chart-container" class="flot-chart mt-5" style="height: 340px;">
        </div>
      </div>

    </div>

  </div>
</div>

<ng-template #modalContent let-close="close" class="modal-demo">
  <button type="button" class="close" (click)="closeModal()">
    <span>&times;</span><span class="sr-only">Close</span>
  </button>
  <h4 class="custom-modal-title">Tạm ứng lương</h4>
  <div class="custom-modal-text">
    <form #form="ngForm" (submit)="submitCashAdvance(form)" autocomplete="on">

      <div class="form-group m-b-20 row">
        <div class="col-12">
          <label class="label">Số tiền ứng</label>
            <input type="text" id="selectCash">
        </div>
      </div>

      <div class="form-group row m-b-20">
        <div class="col-12">
          <label >Lý do ứng tiền</label>
          <textarea class="form-group" required name="reason" placeholder="Lý do ứng tiền"
            [(ngModel)]="model.reason" rows="2">
                      </textarea>
          <div invalidmessage="reason">
            <p *invalidType="'required'">Bạn vui lòng nhập lý do nhé!</p>
          </div>
        </div>
      </div>

      <div class="form-group row m-b-20">
        <div class="col-sm-12 text-right">
          <span class="label">Khẩn cấp</span>
          <div class="checkbox checkbox-circle checkbox-custom checkbox-single float-right">
            <input type="checkbox" [(ngModel)]="model.isUrgent" [ngModelOptions]="{standalone: true}">
            <label></label>
          </div>
        </div>
      </div>

      <div class="form-group row text-right float-right m-t-10">
        <div class="col-12">
          <button class="btn btn-custom waves-effect waves-light mr-2">Xác Nhận</button>
          <button class="btn waves-effect waves-light btn-danger" (click)="closeModal()">Hủy</button>
        </div>
      </div>

    </form>
  </div>
</ng-template>
