import { CashAdvanceStatus } from './enums';
import { UserShortInfo } from './userinfo.model';

export enum SalaryType {
  Net = 1,
  Gross,
  Parttime,
  Probation
}

export enum CashAdvanceType {
    CashAdvance = 1,
    SalaryPerformance,
    HolidayBonus,
}

export class SalaryModel {
  userId: number;
  userModel: UserShortInfo;
  month: number;
  basicSalary: number;
  lunchMoney: number;
  telephoneFee: number;
  petrolMoney: number;
  housingSupport: number;
  reduceYourself: number;
  realSalary: number;
  totalRealSalary: number;
  totalOTHours: number;
  otRate: number;
  otSalary: number;
  holidayBonus: number;
  salaryPerformance: number;
  totalAllowance: number;
  totalIncome: number;
  salaryCalculatedForBHXHnBHYT: number;
  salaryCalculatedForBHTN: number;
  totalTaxableIncome: number;
  bhxh: number;
  bhyt: number;
  bhtn: number;
  kpcd: number;
  cdp: number;
  totalCP: number;
  net: number;
  pitExcludingRent: number;
  tnConversionIncludingRent: number;
  pitByEmployee: number;
  taxableIncome: number;
  pit: number;
  cashAdvance: number;
  paidDayOff: number;
  isApprove: boolean;
  bhxhCompulsory: number;
  bhytCompulsory: number;
  bhtnCompulsory: number;
  totalCompulsoryInsurance: number;
  totalTax: number;
  salaryType: SalaryType;
  isEmptySalary :boolean;
  overTimeInfos: OverTimeInfos[] = [];; 
  totalOTHourExemptPIT: number;
  otSalaryExemptPIT: number;
};

export class CurencyExchangeModel {
  curencyExchange: number;
}

export class CashAdvanceModel {
    userIds: number[] = [];
    cashAdvance: number = 0;//for cashadvance
    title: string = "";//for cashadvance
    reason: string = "";
    isUrgent: boolean = false;// for cashadvance
    isSendAll: boolean;
    type: CashAdvanceType;
}

export class MaxCashAdvanceApiModel {
    maxValue: number = 0;
    salaryValue: number;
    cashAdvance: number;
}

export class CashAdvanceApiModel
{
    id : number;
    cash: number;
    reason: string;
    dateTime: string ="";
    isApprove: boolean;
    userId:number;
    status: CashAdvanceStatus;
    userName: string;
    userImage: string;
}

export class UpdateCashAdvanceModel {
  userId: number;
  cashAdvanceId: number;
  isApprove:boolean;
}

export class OverTimeInfos {
  CustomerId: number;
  CustomerName: string;
  RateWithCustomer:number;
  ProjectId: number;
  ProjectName: string;
  TotalWorkTime:number;
  TotalWorkTimeWithRate:number;
  SalaryOTProject: number;
  IsIncludePIT: number;
  UserId:number;
}