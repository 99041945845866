import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ADMIN_CONST } from 'src/app/app.constants';
import { ChangeDateButtonEnum } from 'src/app/models/enums';
import { ReportGetModel, ReportReturnModel } from 'src/app/models/report.model';
import { UserShortInfo } from 'src/app/models/userinfo.model';
import { AuthService } from 'src/app/services/auth.service';
import { LocalService } from 'src/app/services/common/local.service';
import { ProjectService } from 'src/app/services/project.service';
import { ReportService } from 'src/app/services/report.service';
import { UserService } from 'src/app/services/user.service';
import { DatetimeUtils } from 'src/app/utils';
declare var $: any;
@Component({
  selector: 'app-daily-report-view',
  templateUrl: './daily-report-view.component.html',
  styleUrls: ['./daily-report-view.component.css']
})
export class DailyReportViewComponent implements OnInit {

  isAdmin: boolean = false;
  dailyReports : ReportReturnModel[]=[];
  targetDate : Date = new Date();
  userId: number = 0;
  employees: UserShortInfo[] = [];

  constructor(
    private reportService: ReportService,
    private userService: UserService
    ) { }

  ngOnInit() {
    this.isAdmin = AuthService.isAdmin();

    this.userId  = this.isAdmin ? 0 : LocalService.getUserId();

    this.getDailyReports(this.targetDate, this.userId);

    if (this.isAdmin) {
      this.getUsers();
    }
  }

  getDailyReports(date: Date, userId: number){

    if (userId===0) return;

    let model = new ReportGetModel();

    model.month = DatetimeUtils.toShortDateTimeFormat(date.toString());

    model.userId = userId;

    this.reportService.getReports(model).subscribe((res) => {

      this.dailyReports = res;

    })
  }

  isAllowEdit(createdDate: Date){

    let currentDate = new Date().toISOString().split('T').shift();

    let created = createdDate.toString().split('T').shift();

    return currentDate === created;
  }

  updateMonth(cases: any) {

    switch (cases) {

      case ChangeDateButtonEnum.Previous:
        this.targetDate = DatetimeUtils.addMonths(this.targetDate, -1);
        break;
      case ChangeDateButtonEnum.Next:
        this.targetDate = DatetimeUtils.addMonths(this.targetDate, 1);
        break;
      default:
        this.targetDate = new Date();
        break;
    }

    this.getDailyReports(this.targetDate, this.userId);
  }

  getUsers(): void {

    this.userService.getUsers()
        .subscribe(apiUsers => {

            if (apiUsers) {

                this.employees = apiUsers;

                this.employees = this.employees.filter(emp => emp.role != +ADMIN_CONST);
            }

        });

}
  userClicked(event: Event ,userId: number) {

    $(".report-user-list.active").removeClass("active");

    $(event.target).addClass("active");

    this.userId = userId;

    this.getDailyReports(this.targetDate, this.userId);

  }

}
