export class PasscodeForgotRequest {
  constructor(email: string) {
    this.email = email;
  }
  email: string;
};

export class NewPasscodeSubmit {
  passwordNew: string;
  token: string;
}
