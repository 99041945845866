<div class="row">
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
        <form #form="ngForm" (submit)="submit(form)" class="forms-sample row">
          <div class="card-body">
            <div class="row ml-3">
              <div class="col-md-6">
                <div class="row">
                  <div class="form-group col-md-6 col-xs-6 col-lg-6" >
                    <label class="textbox-title" >Thời gian bắt đầu </label>
                    <nz-date-picker name="startDay" nzSize="large" nzFormat="{{formatDateConstant}}" [(ngModel)]="startDay">
                    </nz-date-picker>
                    <div invalidmessage="startDay">
                      <p *invalidType="'required'">Bạn vui lòng chọn ngày bắt đầu</p>
                    </div>

                  </div>
                  <div class="form-group col-md-6 col-xs-6 col-lg-6 datepicker">
                    <nz-time-picker name="startTime" nzSize="large" nzFormat="{{formatTimeConstant}}" [(ngModel)]="startTime"></nz-time-picker>
                  </div>
                </div>
                <app-text-box type="number" title="Số giờ (Không bao gồm thời gian làm việc riêng)" required
                  name="workTime" placeholder="1..." [(ngModel)]="model.workTime" min='0'>
                  <div invalidmessage="workTime">
                    <p *invalidType="'required'">Bạn vui lòng nhập chi tiết công việc</p>
                  </div>
                </app-text-box>

                <label class="textbox-title" >Nội dung</label>
                <textarea class="form-group col-md-12" required name="jobDescription" style="font-size: 12px"
                  [(ngModel)]="model.description" rows="6" placeholder="Chi tiết công việc">
                            </textarea>
                <div invalidmessage="jobDescription">
                  <p *invalidType="'required'">Bạn vui lòng nhập chi tiết công việc</p>
                </div>
              </div>

              <div class="col-md-6">
                <div class="row">
                  <div class="form-group col-md-6 col-xs-6 col-lg-6">
                    <label class="textbox-title" >Thời gian kết thúc </label>
                    <nz-date-picker name="finishDay" nzSize="large" nzFormat="{{formatDateConstant}}" [(ngModel)]="finishDay">
                    </nz-date-picker>
                    <div invalidmessage="finishDay">
                      <p *invalidType="'required'">Bạn vui lòng chọn ngày kết thúc</p>
                      </div>
                  </div>
                  <div class="form-group col-md-6 col-xs-6 col-lg-6 datepicker">
                    <nz-time-picker name="endTime" nzSize="large" nzFormat="{{formatTimeConstant}}" [(ngModel)]="endTime"></nz-time-picker>
                  </div>
                </div>
                <label>Chọn dự án</label>
                <div *ngIf="projects" class="form-group row" style="padding: 10px;">
                  <div style="margin: 10px;" *ngFor="let proj of projects">
                    <button [class.btn-light]="!proj.isChecked"
                      [class.btn-primary]="proj.isChecked" type="button"
                      (click)="projSelectedToggle(proj.id)" class="btn btn-rounded btn-sm">
                      <app-shared-image [srcImg]="proj.projectLogo | image"
                                        [classImg]="'img-xs rounded-circle'"
                                        [altImg]="'Profile image'"
                                        [height]="48"
                                        [width]="48">
                      </app-shared-image>
                      {{proj.projectName}}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="row float-right mr-4">
            <div class="col-xs-12" >

              <div class="btn-group" role="group" aria-label="add overtime button">

                <button class="btn btn-lg btn-icon waves-effect waves-light btn-danger mr-2"
                  style="overflow:inherit!important" routerLink="/overtime-list">
                  Hủy bỏ
                </button>

                <button class="btn btn-lg btn-custom submit-btn btn-block waves-effect waves-light">
                  Hoàn tất
                </button>

              </div>


          </div>
        </div>
        </div>

        </form>
    </div>
  </div>
</div>
