import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SERVER_ERROR_MESSAGES, ToastrAlertConfig } from '../app.constants';
import { NotifyType } from '../../app/models/enums';
import { HtmlParser } from '@angular/compiler';
@Injectable(
  {
    providedIn: 'root'
  }
)
export class NotificationService {

  notifySetup: any;

  constructor(private toastr: ToastrService) {
    this.notifySetup = { timeOut: 15000, tapToDismiss: true, enableHtml: true, progressBar: false, progressAnimation: "decreasing", positionClass: "toast-bottom-right", closeButton: true, extendedTimeOut: 3000 };
  }

  pushServerToast(content: string, title: string = '', toastContentClicked?: () => void, cancelToast?: () => void) {

    if (title == '')
      title = "Thông báo mới.";

    var toast = this.toastr.info(content, title, this.notifySetup);

    let isClicked = false;

    toast.onTap
      .subscribe(() => {
        isClicked = true;
        if (toastContentClicked != null)
          toastContentClicked.call(this);
      });

    toast
      .onHidden
      .subscribe(() => {
        if (!isClicked) {
          if (cancelToast != null)
            cancelToast.call(this);
        }
      });

  }

  error(content: string) {
    this.toastr.error(content, "Lỗi!!", ToastrAlertConfig);
  }

  success(content: string) {
    this.toastr.success(content, "Thành Công!!", ToastrAlertConfig);
  }

  info(content: string) {
    this.toastr.info(content, "Thông Tin", ToastrAlertConfig);
  }

  warning(content: string) {
    this.toastr.warning(content, "Cảnh Báo", ToastrAlertConfig);
  }

  serverError(data: any) {
    this.notify(data.error.error.message);
  }

  notify(message: string, type: NotifyType = NotifyType.Error) {

    let content;

    let errorModel = SERVER_ERROR_MESSAGES.filter(p => p.Key == message)[0];
    if (errorModel)
      content = errorModel.Value;
    else
      content = message;

    switch (type) {
      case NotifyType.Error:
        this.error(content);
        break;
      case NotifyType.Info:
        this.info(content);
        break;
      case NotifyType.Success:
        this.success(content);
        break;
      case NotifyType.Warning:
        this.warning(content);
        break;
    }

  }
}
