import { Component, OnInit } from '@angular/core';
import { CreateRewardModel } from 'src/app/models/reward.model';
import { NgForm } from '@angular/forms';
import { RewardService } from 'src/app/services/reward.service';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { DialogService } from 'src/app/services/dialog.service';
import { UserRole } from 'src/app/models/user';
import { UserShortInfo } from 'src/app/models/userinfo.model';
declare var $: any;
declare function initSummerNoteTemplate(id:string,content: string): any;
declare function saveFormInputValue(): string;
declare function initSelect2(): any;
@Component({
  selector: 'app-reward-compose',
  templateUrl: './reward-compose.component.html',
  styleUrls: ['./reward-compose.component.css']
})
export class RewardComposeComponent implements OnInit {
  model: CreateRewardModel;
  users: UserShortInfo[];
  constructor(
    private rewardService: RewardService,
    private userService: UserService,
    private dialogService: DialogService,
    private router: Router
  ) { }

  ngOnInit() {
    this.model = new CreateRewardModel();
    initSummerNoteTemplate("#summernote", "");
    initSelect2();
    this.getAllUser();
  }

  getAllUser(){
    this.userService.getUsers().subscribe(data=> {
      this.users=data.filter(u=>u.role != UserRole.Admin);
    });
  }

  sendSaveOrSave(form: NgForm, isSend: boolean){
    if (form.invalid) return;

    let eleSelectUsers = document.getElementById("selectUser");

    this.model.userIds.userIds = $(eleSelectUsers).val();
    this.model.userIds.isDeactive = false;
    this.model.isSendReward = isSend;
    this.model.template = saveFormInputValue();

    this.rewardService.createOrSendReward(this.model).subscribe(() => {
      this.dialogService.message("Thành công", `Bạn đã  ${isSend ? "gửi" : "lưu mẫu"} khen thưởng thành công.`, () => {
        this.router.navigate(['/reward-list']);
      }, "Đóng");
    });
  }

  deleteTemplate(){
    this.model = new CreateRewardModel();
    $(".note-editable").first().text("");
    $("#selectUser").val([]).change();
  }
}
