<div class="row">
  <div class="col-sm-12">
    <!-- meta -->
    <div class="profile-user-box card-box bg-custom">
      <div class="row">
        <div class="col-sm-4">
          <span class="float-left mr-3">
            <app-shared-image [srcImg]="user.avatar | image"
                              [classImg]="'thumb-lg rounded-circle'"
                              [altImg]="'image'">
            </app-shared-image>
          </span>

          <div class="media-body text-white">
            <h4 class="mt-1 mb-1 font-18">{{ user.fullName }}</h4>
            <p class="font-13 text-light">{{ user.roleModel.name }}</p>
            <p class="text-light mb-0">{{ user.timeZoneName }}</p>
          </div>
        </div>
        <div class="col-sm-8">
          <div class="text-right">
            <button
              type="button"
              *ngIf="isAdmin"
              (click)="showModalTakeOff()"
              class="btn btn-light waves-effect mr-3"
            >
              <i class="mdi mdi-calendar-today mr-1"></i> Tạm nghỉ
            </button>
            <button
              type="button"
              *ngIf="isSelfProfile || isAdmin"
              [routerLink]="['/user-profile-update', userId]"
              class="btn btn-light waves-effect mr-3"
            >
              <i class="mdi mdi-account-settings-variant mr-1"></i> Chỉnh sửa
            </button>
            <button
              type="button"
              *ngIf="(isSelfProfile && !isAdmin) || (isAdmin && !isSelfProfile)"
              [routerLink]="['/salary-detail', userId]"
              class="btn btn-light waves-effect mr-3"
            >
              <i class="mdi mdi-file-document-box mr-1"></i> Bảng lương
            </button>
            <button
              type="button"
              *ngIf="(isSelfProfile && !isAdmin) || (isAdmin && !isSelfProfile)"
              (click)="getLabourContract()"
              class="btn btn-light waves-effect"
            >
              <i class="mdi mdi mdi-content-copy mr-1"></i> Hợp đồng
            </button>
            <button
              type="button"
              *ngIf="!isSelfProfile"
              routerLink="/users"
              class="btn btn-light waves-effect ml-3"
            >
              <div *ngIf="!isAdmin">
                <i class="mdi mdi-arrow-left"></i> Đồng nghiệp
              </div>
              <div *ngIf="isAdmin">
                <i class="mdi mdi-arrow-left"></i> Nhân viên
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--/ meta -->
  </div>
</div>
<!-- end row -->
<!-- modal -->
<ng-template #modalContent let-close="close">
  <div class="modal-header">
    <h5 class="modal-title">Tạm nghỉ</h5>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModalTakeOff()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form #form="ngForm" (ngSubmit)="submitTakeOff()" autocomplete="off">
    <div class="modal-body">
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label class="textbox-title" >Thời gian bắt đầu </label>
            <nz-date-picker [disabled]="isDisabledStartDay" class="date-take-off" name="startDateTakeOff" nzSize="large" nzFormat="{{formatDateConstant}}" [(ngModel)]="user.startDateTakeOff" (ngModelChange)="checkDateTakeOff()">
            </nz-date-picker>
            <div *ngIf="!!errorStartDay" class="error-take-off">
              <p>{{errorStartDay}}</p>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label class="textbox-title" >Thời gian kết thúc </label>
            <nz-date-picker [disabled]="isDisabledEndDay" class="date-take-off" name="endDateTakeOff" nzSize="large" nzFormat="{{formatDateConstant}}" [(ngModel)]="user.endDateTakeOff" (ngModelChange)="checkDateTakeOff()">
            </nz-date-picker>
            <div *ngIf="!!errorEndDay" class="error-take-off">
              <p>{{errorEndDay}}</p>
            </div>
          </div>
        </div>
        <div class="col-sm-12 text-right">
          <span class="label">Nghỉ vô thời hạn</span>
          <div class="checkbox checkbox-custom checkbox-single float-right">
            <input type="checkbox" [(ngModel)]="user.isTakeOffPermanent" [ngModelOptions]="{standalone: true}" (ngModelChange)="changedTakeOffPermanent($event)">
            <label></label>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="modal-footer">
    <button *ngIf="isHasTakeOff" class="btn btn-danger save-event waves-effect waves-light" (click)="cancelTakeOff()" type="button">Hủy tạm nghỉ</button>
    <button class="btn btn-custom save-event waves-effect waves-light" (click)="submitTakeOff()" type="button"
      [disabled]="!formTakeOff">Xác nhận</button>
    <button type="button" class="btn btn-light waves-effect" data-dismiss="modal" (click)="closeModalTakeOff()">Đóng</button>
  </div>
</ng-template>
<!-- end modal -->
<div class="row">
  <div class="col-xl-4">
    <!-- Personal-Information -->
    <div class="card-box">
      <h4 class="header-title mt-0 m-b-20">Thông tin cá nhân</h4>
      <div class="panel-body">
        <p class="text-muted font-13">
          Thông tin liên hệ
        </p>

        <hr />

        <div class="text-left">
          <p class="text-muted font-13">
            <strong>Ngày sinh: </strong>
            <span class="m-l-15">{{ user.birthday }}</span>
          </p>

          <p class="text-muted font-13">
            <strong>SĐT: </strong><span class="m-l-15">{{ user.phone }}</span>
          </p>

          <p class="text-muted font-13">
            <strong>Email: </strong>
            <span class="m-l-15">{{ user.email }}</span>
          </p>

          <p class="text-muted font-13">
            <strong>Thời gian đã làm ở công ty: </strong>
            <span class="m-l-15">{{ user.workingTimeCounting }}</span>
          </p>

          <p class="text-muted font-13">
            <strong>Thời gian vào công ty: </strong>
            <span class="m-l-15">{{ user.dateJoinCompany }}</span>
          </p>
          <p class="text-muted font-13">
            <strong>Lần truy cập gần nhất: </strong>
            <span class="m-l-15">{{ user.lastLogin }}</span>
          </p>
        </div>

        <ul class="social-links list-inline m-t-20 m-b-0">
          <li class="list-inline-item">
            <a
              title="{{ user.facebookId }}"
              data-placement="top"
              data-toggle="tooltip"
              class="tooltips"
              href="https://www.facebook.com/{{ user.facebookId }}"
              target="_blank"
              ><i class="fa fa-facebook"></i
            ></a>
          </li>
          <li class="list-inline-item">
            <a
              title="{{ user.skypeId }}"
              data-placement="top"
              data-toggle="tooltip"
              class="tooltips"
              ><i class="fa fa-skype"></i
            ></a>
          </li>
          <li
            *ngIf="(!isAdmin && isSelfProfile) || (isAdmin && !isSelfProfile)"
            class="list-inline-item"
          >
            <a id="tooltip-salary"><i class="fa fa-usd"></i></a>
          </li>
        </ul>
      </div>
    </div>
    <!-- Personal-Information -->
    <div
      *ngIf="(isSelfProfile && !isAdmin) || (!isSelfProfile && isAdmin)"
      class="card-box ribbon-box"
    >
      <div class="ribbon ribbon-info">Danh sách tạm ứng lương</div>
      <div class="clearfix"></div>
      <div class="inbox-widget slimscroll" *ngIf="listCashAdvance.length > 0">
        <div
          class="inbox-item"
          *ngFor="let cash of listCashAdvance"
          title="Lí do ứng lương: {{ cash.reason }}"
        >
          <p class="inbox-item-author">Tạm ứng lương</p>
          <p class="inbox-item-text">
            Bạn đã yêu cầu tạm ứng số tiền
            <span class="font-500 text-dark"
              >{{ cash.cash | appNumber }} VNĐ
            </span>
            vào ngày
            <span class="font-500 text-dark">{{
              cash.dateTime | date: "dd/MM/yyyy"
            }}</span>
          </p>
          <p class="inbox-item-date m-t-10">
            <span *ngIf="cash.status == 1" class="badge badge-success font-13"
              >Đã chấp nhận</span
            >
            <span *ngIf="cash.status == 2" class="badge badge-danger font-13"
              >Đã từ chối</span
            >
            <span *ngIf="cash.status == 3" class="badge badge-warning font-13"
              >Chờ xác nhận</span
            >
          </p>
        </div>
      </div>
      <div class="inbox-widget" *ngIf="listCashAdvance.length == 0">
        <div class="inbox-item">
          <p class="inbox-item-text">Không có tạm ứng lương nào.</p>
        </div>
      </div>
    </div>

    <div
      *ngIf="(isSelfProfile && !isAdmin) || (!isSelfProfile && isAdmin)"
      class="card-box ribbon-box"
    >
      <div class="ribbon ribbon-warning">Danh sách đi trễ</div>
      <div class="clearfix"></div>
      <div class="inbox-widget">
        <div class="inbox-item">
          <p class="inbox-item-text">Không có ngày nào đi trễ.</p>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xl-8">
    <div
      *ngIf="(isSelfProfile && !isAdmin) || (!isSelfProfile && isAdmin)"
      class="text-center"
    >
      <div class="row">
        <div class="col-md-6 col-xl-4">
          <div
            class="card-box card-normal bg-primary widget-flat border-primary text-white"
          >
            <i class="fi-stack-2"></i>
            <h3 class="m-b-10">{{ userIndex.totalProjects }}</h3>
            <p class="text-uppercase m-b-5 font-13 font-600">
              Dự án đã và đang làm
            </p>
          </div>
        </div>
        <div class="col-md-6 col-xl-4">
          <div
            class="card-box card-normal widget-flat border-success bg-success text-white"
          >
            <i class="fi-sun"></i>
            <h3 class="m-b-10">{{ userIndex.dayOffInYear }}</h3>
            <p class="text-uppercase m-b-5 font-13 font-600">Số ngày đã nghỉ</p>
          </div>
        </div>
        <div class="col-md-6 col-xl-4">
          <div
            class="card-box card-normal bg-danger widget-flat border-danger text-white"
          >
            <i class="fi-sun"></i>
            <h3 class="m-b-10">{{ userIndex.totalDayOffInYear }}</h3>
            <p class="text-uppercase font-13 font-600">Tổng số ngày nghỉ</p>
          </div>
        </div>
        <!-- end col -->
      </div>
    </div>
    <!-- end row -->

    <!-- Project-working -->
    <div *ngIf="!isAdmin || !isSelfProfile" class="card-box project-box">
      <h4 class="header-title mb-3">Dự án đang làm</h4>

      <nz-table
      nzVirtualScroll
      [nzData]="projects"
      [nzFrontPagination]="false"
      [nzShowPagination]="false"
      [nzScroll]="{ y: '240px' }">
      <thead class="project-header">
        <tr class="project-header-row">
          <th>Tên dự án</th>
          <th>Ảnh dự án</th>
          <th>Khách hàng</th>
          <th>Trạng thái</th>
          <th>Thành viên</th>
        </tr>
      </thead>
      <tbody *ngIf="projects">
        <ng-template nz-virtual-scroll let-data let-index="index">
          <tr class="project-data-row">
            <td>{{ data.projectName}}</td>
            <td>
              <app-shared-image [srcImg]="data.projectLogo | image"
                                [classImg]="'rounded-circle'"
                                [titleImg]="data.projectName"
                                [altImg]="'friend'"
                                [width]="60"
                                [height]="60">
              </app-shared-image>
            </td>
            <td>{{ data.customerName }}</td>
            <td>
              <div
                *ngIf="data.status == 1"
                class="ribbon-two ribbon-two-custom">
                <span>{{ data.statusModel.name }}</span>
              </div>
              <div
                *ngIf="data.status == 2"
                class="ribbon-two ribbon-two-warning">
                <span>{{ data.statusModel.name }}</span>
              </div>
              <div
                *ngIf="data.status == 3"
                class="ribbon-two ribbon-two-danger">
                <span>{{ data.statusModel.name }}</span>
              </div>
              <div
                *ngIf="data.status == 4"
                class="ribbon-two ribbon-two-success">
                <span>{{ data.statusModel.name }}</span>
              </div>
            </td>
            <td class="project-members memnber-hide-overflow">
              <div *ngFor="let user of data.userInfos">
                <a
                  [routerLink]="['/user-profile', user.id]"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="{{ user.fullName }}">
                  <div class="thumb-md member-thumb">
                    <app-shared-image [srcImg]="user.avatar | image"
                                      [classImg]="'rounded-circle img-thumbnail ' + (user.isLeader ? 'project-leader-mark' : '')"
                                      [altImg]="'friend'">
                    </app-shared-image>
                  </div>
                </a>
              </div>
            </td>
          </tr>
        </ng-template>
      </tbody>
      </nz-table>
    </div>
    <!-- end -->

    <div
      *ngIf="(isSelfProfile && !isAdmin) || (!isSelfProfile && isAdmin)"
      class="row"
    >
      <div class="col-xs-6 col-md-6">
        <div class="card-box ribbon-box">
          <div class="ribbon ribbon-custom">Danh sách tăng ca</div>
          <div class="clearfix"></div>
          <div class="inbox-widget slimscroll" *ngIf="listOvertime.length > 0">
            <div
              class="inbox-item"
              *ngFor="let request of listOvertime"
              title="Chi tiết công việc: {{ request.description }}"
            >
              <p class="inbox-item-author">
                Ngày
                <span class="font-500 text-dark">{{
                  request.submitTime | date: "dd/MM/yyyy"
                }}</span>
              </p>
              <p class="inbox-item-text">
                Bạn đã tăng ca từ
                <span class="text-dark font-500">
                  {{ request.timeStart | date: "h:mm a" }}</span
                >
                đến
                <span class="text-dark font-500">
                  {{ request.timeFinish | date: "h:mm a" }}</span
                >
              </p>
              <p class="inbox-item-date m-t-10">
                <span *ngIf="request.isLocked" class="badge badge-secondary font-13">
                  {{ request.workTime }} giờ</span>
                <span *ngIf="!request.isLocked" class="badge badge-success font-13">
                  {{ request.workTime }} giờ</span>
              </p>
            </div>
          </div>
          <div class="inbox-widget" *ngIf="listOvertime.length == 0">
            <div class="inbox-item">
              <p class="inbox-item-text">Bạn chưa tăng ca trong tháng này.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-6 col-md-6">
        <div class="card-box ribbon-box">
          <div class="ribbon ribbon-danger">Danh sách xin nghỉ phép</div>
          <div class="clearfix"></div>
          <div
            class="inbox-widget slimscroll"
            *ngIf="listRequestOff.length > 0"
          >
            <div
              class="inbox-item"
              *ngFor="let request of listRequestOff"
              title="Lí do xin nghỉ: {{ request.reason }}"
            >
              <p class="inbox-item-author">
                Ngày
                <span class="text-dark font-500">
                  {{ request.dateTime | date: "dd/MM/yyyy" }}</span
                >
              </p>
              <p class="inbox-item-text">
                Thời gian nghỉ:
                <span class="text-dark font-500">
                  {{
                    request.option == 1
                      ? "cả ngày"
                      : request.option == 2
                      ? "buổi sáng"
                      : "buổi chiều"
                  }}</span
                >
              </p>
              <p class="inbox-item-date m-t-10">
                <span
                  *ngIf="request.dayOffStatus == 1"
                  class="badge badge-success font-13"
                  >Đã chấp nhận</span
                >
                <span
                  *ngIf="request.dayOffStatus == 3 || request.dayOffStatus == 0"
                  class="badge badge-danger font-13"
                  >Đã từ chối</span
                >
                <span
                  *ngIf="request.dayOffStatus == 2"
                  class="badge badge-warning font-13"
                  >Chờ xác nhận</span
                >
              </p>
            </div>
          </div>
          <div class="inbox-widget" *ngIf="listRequestOff.length == 0">
            <div class="inbox-item">
              <p class="inbox-item-text">
                Không có nhân viên nào xin nghỉ phép.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- end row -->
