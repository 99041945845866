import { UserRole, Gender, EmployeeTypes, WorkingStates } from '../models/enums';

export class User {
  Id: number;
  DateOfBirth: number;
  FullName: string;
  ProfileImage: string;
  UserRole: UserRole;
  Gender: Gender;
  EmployeeType: EmployeeTypes;
  WorkingState: WorkingStates;
  BackToWorkDate: number;

  Address: string;
  Email: string;
  PhoneNumber: string;
};

export class UserIdsModel {
    userIds: number[] = [];
    isDeactive: boolean;
};
export class UserIdModel {
    userId: number;
    isDeactive: boolean;
};

export class UserIndexApiModel {
  totalProjects: number;
  dayOffInYear: number;
  totalDayOffInYear: number;
}

export class TemporarilyLockedModel {
  temporarilyLockedStartDate: string;
  temporarilyLockedEndDate: string;
  isTemporarilyLockUnlimited: boolean;
  userId: number;
}

export { UserRole, Gender, EmployeeTypes }
