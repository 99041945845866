<div class="row">
  <div class="col-12 grid-margin">
    <div class="card">
      <div class="card-body">
        <full-calendar
          *ngIf="options && !isListView"
          #fullcalendar
          (dateClick)="dateClick($event)"
          (eventClick)="eventClick($event)"
          (eventRender)="eventRender($event)"
          [events]="eventsModel"
          [eventLimitText]="options.eventLimitText"
          [editable]="options.editable"
          [eventLimit]="options.eventLimit"
          [listDayAltFormat]="options.listDayAltFormat"
          [noEventsMessage]="options.noEventsMessage"
          [header]="options.header"
          [aspectRatio]="options.aspectRatio"
          [plugins]="options.plugins"
          [footer]="options.footer"
          [customButtons]="options.customButtons"
          [locale]="options.locale"
          [buttonText]="options.buttonText">
        </full-calendar>
        <div *ngIf="isAdmin && isListView">
          <button type="button" class="fc-viewListLeaving-button fc-button fc-button-primary float-right" (click)="viewListRatesOvertime()"><i class="mdi mdi-calendar-today"></i></button>
          <h4 class="card-title mb-4">Danh sách tỷ giá tăng ca</h4>

          <nz-table #filterTable
                    nzShowPagination
                    nzShowSizeChanger
                    [nzData]="listOfDisplayRates"
                    nzPageSize="50">
            <thead (nzSortChange)="sort($event)" nzSingleSort>
              <tr>
                <th nzShowSort nzSortKey="dateTime">
                  Ngày
                </th>
                <th nzShowSort nzSortKey="rate">
                  Tỷ giá
                </th>
                <th>
                  Tác vụ
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let rate of filterTable.data">
                <td>
                  {{rate.dateTime | date: 'dd/MM/yyyy'}}
                </td>
                <td>
                  <span *ngIf="rate.rate == 2" class="badge badge-custom font-13">x2</span>
                  <span *ngIf="rate.rate == 3" class="badge badge-danger font-13">x3</span>
                </td>
                <td>
                  <button type="button" class="btn btn-icon btn-sm waves-effect waves-light btn-custom mr-2 editRate"
                              title="Chỉnh sửa" [attr.data-item-id]="rate.id" [attr.data-item-date]="rate.dateTime" [attr.data-item-rate]="rate.rate">
                              <i class="fa fa-edit"></i>
                  </button>
                  <button type="button" class="btn btn-icon btn-sm waves-effect waves-light btn-danger deleteRate"
                      title="Xóa" [attr.data-item-id]="rate.id"> <i class="fa fa-times"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </nz-table>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #modalContent let-close="close">
  <div class="modal-header">
    <h5 class="modal-title">Tỷ Giá Tăng Ca</h5>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form #form="ngForm" (ngSubmit)="submit()" autocomplete="off">
    <div class="modal-body">
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <app-text-box class="form-group" title="Ngày" type="text" name="date"  disabled
              [ngModel]="modalData.date| date:'dd-MM-yyyy'">
            </app-text-box>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="form-group">
              <app-dropdown-list required class="app-text" name="rate" [data]="rate" [(ngModel)]="modalData.rateOption" title="Tỷ giá tăng ca">
              </app-dropdown-list>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="modal-footer">
    <button *ngIf ="modalData.isHasEvent" class="btn btn-danger waves-effect waves-light" (click)="deleteRateOverTime()" type="button"
    [disabled]="!form.valid">Xóa</button>
    <button class="btn btn-success save-event waves-effect waves-light" (click)="submit()" type="button"
      [disabled]="!form.valid">Xác
      nhận</button>
    <button type="button" class="btn btn-light waves-effect" data-dismiss="modal" (click)="closeModal()">Đóng</button>
  </div>
</ng-template>

<ng-template #dialogModal let-close="close">
  <app-confirm-dialog [model]="dialogData"></app-confirm-dialog>
</ng-template>
