import { Component, OnInit } from '@angular/core';
import { ProjectService } from 'src/app/services/project.service';
import { AuthService } from 'src/app/services/auth.service';
import { UntypedFormControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DialogService } from 'src/app/services/dialog.service';
import { debounceTime, tap } from 'rxjs/operators';
import { ProjectDetailModel, GetProjectModel, ProjectRoleModel } from '../../../models/project.models';
declare var $: any;
@Component({
  selector: 'list-project',
  templateUrl: './list-project.component.html',
  styleUrls: ['./list-project.component.css']
})
export class ListProjectComponent implements OnInit {

  projectName = new UntypedFormControl();
  projects: ProjectDetailModel[];
  originList: ProjectDetailModel[];
  userProject = new ProjectRoleModel();
  isAdmin = false;
  projForm: UntypedFormGroup = this.formBuilder.group({
    projectName: this.projectName
  });
  pageIndex: number;
  totalProject: number;
  statusCode: number;
  limit: number;
  sortName: string;

  constructor(private formBuilder: UntypedFormBuilder, private projectService: ProjectService, private dialogService: DialogService) { }

  ngOnInit() {

    this.isAdmin = AuthService.isAdmin();

    this.projects = [];

    this.projectName.valueChanges.pipe(debounceTime(1000), tap())
      .subscribe(res => this.projectService.getProjects(res)
        .subscribe(projects => {          
          this.originList = projects;
          this.projects = this.originList && this.originList.length >= 6 ? this.originList.slice(0, 6) : this.originList;
        }));

    this.getProjects();

    this.sortListProject("byProjectName");

    this.getUserProjectManagement();
    this.pageIndex = 1;
    this.totalProject = 0;
    this.statusCode = 0;
    this.limit = 20;
    this.sortName = "byProjectName";
  }

  getUserProjectManagement() {
    this.projectService.getUserProjectManagement().subscribe( res => {
        this.userProject = res;
    })
  }

  isAllowModify(projId: number):boolean {
    let isLeaderProj = false;
    if (this.userProject.leaderProjectIds.length === 0) {
      isLeaderProj = false;
    } else {
      isLeaderProj = this.userProject.leaderProjectIds.indexOf(projId) > -1;
    }

    let isManagementRole = this.isAdmin || AuthService.isManager();

    return isLeaderProj || isManagementRole;
  }

  getProjects() {
    this.projectService.getProjects(new GetProjectModel())
      .subscribe(projects => {
        this.totalProject = projects.length;
        this.originList = projects.sort((a, b) => (a.projectName.toLowerCase() > b.projectName.toLowerCase()) ?  1 : -1);
        this.projects = !this.isAdmin ? this.originList : (this.originList && this.originList.length >= this.limit ? this.originList.slice(0, this.limit) : this.originList);
        
      });
  }

  onPageIndexChange($event) {
    let start = ($event - 1) * this.limit;
    let end = $event * this.limit;
    this.pageIndex = $event;
    this.projects = this.statusCode == 0 ? this.originList.slice(start, end) : this.originList.filter(p => p.status == this.statusCode).slice(start, end);
    this.sortListProject(this.sortName);
  }

  onPageSizeChange(size: number): void {
    let start = (this.pageIndex - 1) * size;
    let end = this.pageIndex * size;
    this.limit = size;
    this.projects = this.statusCode == 0 ? this.originList.slice(start, end) : this.originList.filter(p => p.status == this.statusCode).slice(start, end);
    this.sortListProject(this.sortName);
  }

  getProjectByStatus(statusCode: number) {
    this.statusCode = statusCode;
    this.pageIndex = 1;
    if (!this.originList) return;
    switch (statusCode.toString()) {
      case "0":
        if (this.originList.length >= this.limit)
          this.projects =  this.originList.slice(0, this.limit);
        else
          this.projects = this.originList;
        this.totalProject = this.originList.length;
        this.sortListProject(this.sortName);
        break;
      default:
        if (this.originList.length >= this.limit)
          this.projects = this.originList.filter(p => p.status == statusCode).slice(0, this.limit);
        else
          this.projects = this.originList.filter(p => p.status == statusCode);
        this.totalProject = this.originList.filter(p => p.status == statusCode).length;
        this.sortListProject(this.sortName);
        break;
    }
  }

  sortListProject(sortMethod: string) {
    this.sortName = sortMethod;
    switch (sortMethod) {
      case "byStatus":
        this.projects = this.projects.sort((a, b) => {return a.statusModel.value - b.statusModel.value;});
        break;
      case "byCustomerName":
        this.projects = this.projects.sort((a, b) => (a.customerName.toLowerCase() > b.customerName.toLowerCase()) ?  1 : -1);
        break;
      case "byProjectName":
        this.projects = this.projects.sort((a, b) => (a.projectName.toLowerCase() > b.projectName.toLowerCase()) ?  1 : -1);
        break;
      default:
        this.projects = this.projects.sort((a, b) => (a.projectName.toLowerCase() > b.projectName.toLowerCase()) ?  1 : -1);
        break;
    }
  }

  deleteProject(id: number) {
    this.dialogService.confirm("Xóa dự án", "Bạn có chắc muốn xóa dự án này?", () => {
        this.projectService.deleteProject(id)
          .subscribe(data => {
            if (data)
              this.getProjects();
          });
    });
  }
}
