<div class="row">
  <div class="col-12 grid-margin">
    <div class="card">
      <form #form="ngForm" (submit)="addProject(form)" autocomplete="on">
        <div class="card-body">
          <div class="row">
            <div class="col-md-6 col-xs-6 col-lg-6 col-sm-6">
              <div class="form-group row d-flex justify-content-center align-items-center">

                <div class="thumb-xxl member-thumb m-b-10">
                  <app-shared-image *ngIf="!projectLogo" [srcImg]="model.projectLogo | image" [classImg]="'img-cover rounded-circle img-thumbnail no-border'" [altImg]="'image'">
                  </app-shared-image>
                  <app-shared-image *ngIf="projectLogo" [srcImg]="projectLogo | image" [classImg]="'img-cover rounded-circle img-thumbnail no-border'" [altImg]="'image'">
                  </app-shared-image>
                </div>
              </div>
              <div class="form-group row d-flex justify-content-center align-items-center">
                <div class="vertical-center">
                  <input type="file" class="img-cover file-upload-default" (change)="onChangeLogo($event)" name="uploadedFile" #logoImageUpload hidden>
                  <div class="input-group">
                    <input type="text" name="logoImageName" value="{{logoImageName}}" class="form-control file-upload-info img-cover" disabled placeholder="Tải ảnh lên">
                    <span class="input-group-append">
                      <button class="btn btn-info" [disabled]="!isAdmin" type="button" (click)="logoImageUpload.click()">
                        Tải Lên
                      </button>
                    </span>
                  </div>
                </div>
              </div>


            </div>
            <div class="col-md-6 col-xs-6 col-lg-6 col-sm-6">
              <div class="form-group row">
                <div class="col-sm-12">
                  <app-text-box class="app-text" title="Tên dự án" type="text" name="projectname" [(ngModel)]="model.projectName" [disabled]="!isAdmin" required="true">
                    <div invalidmessage="projectname">
                      <p *invalidType="'required'">Bạn vui lòng nhập tên dự án!!!</p>
                    </div>
                  </app-text-box>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-12">
                  <app-dropdown-list class="app-text" name="customer" title="Khách hàng" [data]="customers" [(ngModel)]="model.customerTitle" required="true">
                    <div invalidmessage="customer">
                      <p *invalidType="'required'">Bạn vui lòng nhập tên khách hàng!!!</p>
                    </div>
                  </app-dropdown-list>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-12">
                  <app-dropdown-list class="app-text" [defaultValue]="defaultValue" name="status" title="Trạng thái" [data]="status"
                                     [(ngModel)]="model.statusTitle" required="true">
                  </app-dropdown-list>
                </div>
              </div>

            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <label>Mô tả</label>
              <textarea class="form-group col-md-12 textArea" [disabled]="!isAdmin" name="jobDescription" [(ngModel)]="model.description" rows="3">
                  </textarea>
            </div>
          </div>

          <div *ngIf="users">
            <div class="row">
              <div class="col-md-12">
                <label>Chọn thành viên tham gia</label>
                <div class="template-searchbox-right">
                  <app-search-box placeholder="Tìm kiếm theo tên" [delayTimeSpan]=500 (searchTextInvoke)='searchUser($event)'>
                  </app-search-box>
                </div>
              </div>
            </div>
            <div class="row">
              <div style="margin: 10px;" *ngFor="let user of users">
                <button [class.btn-light]="!user.isChecked" [class.btn-primary]="user.isChecked" type="button" (click)="userSelectedToggle(user.id)" class="btn btn-rounded btn-fw btn-select-user project-hide-overflow text-left">
                  <app-shared-image [srcImg]="user.avatar | image"
                                    [classImg]="'img-xs img-cover rounded-circle img-avt-thumb'"
                                    [altImg]="'image'"
                                    [titleImg]="'Profile image'"
                                    [height]="32"
                                    [width]="32">
                  </app-shared-image>
                  {{user.fullName}}
                </button>
              </div>
            </div>
          </div>

          <div *ngIf="chosenUsers && isAdmin" class="row mt-3">
            <div class="col-md-12">
              <label>{{chooseLeaderTitle}}</label>
              <div class="form-group row">
                <div style="margin: 10px;" *ngFor="let chosenUser of chosenUsers">
                  <button [class.btn-light]="!chosenUser.isChecked" [class.btn-primary]="chosenUser.isChecked" type="button" (click)="leaderUseroggle(chosenUser.id)" class="btn btn-rounded btn-fw btn-select-user project-hide-overflow text-left">
                    <app-shared-image [srcImg]="chosenUser.avatar | image"
                                      [classImg]="'img-xs img-cover rounded-circle img-avt-thumb'"
                                      [altImg]="'Profile image'"
                                      [titleImg]="chosenUser.fullName"
                                      [height]="32"
                                      [width]="32">
                    </app-shared-image>
                    {{chosenUser.fullName}}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="row" style="float:right; padding-right: 14px;">

            <div class="col-xs-12">

              <div class="btn-group" role="group" aria-label="add project button">

                <button class="btn btn-lg btn-icon waves-effect waves-light btn-danger mr-2" style="overflow:inherit!important" routerLink="/list-project">
                  Hủy bỏ
                </button>

                <button class="btn btn-lg btn-custom submit-btn btn-block waves-effect waves-light">
                  {{action}}
                </button>

              </div>

            </div>

          </div>

        </div>
      </form>
    </div>
  </div>
</div>
