import { Injectable } from '@angular/core';
import { BaseService } from './common/base.service';
import { HttpClient } from '@angular/common/http';
import { ErrorService } from './common/error.service';
import { GlobalService } from './global.service';
import { Observable } from 'rxjs';
import { API_ENDPOINT } from '../app.constants';
import { GetCustomerModel } from '../models/customer.models';

@Injectable({
  providedIn: 'root'
})
export class CustomerService extends BaseService {

  constructor(protected http: HttpClient,
    protected errorHandler: ErrorService,
    protected globalService: GlobalService) {
    super(http, errorHandler, globalService);

  }

  addCustomer(project: any): Observable<any> {
    return this.postFormData(API_ENDPOINT.add_edit_customer, project, true);
  }

  editCustomer(project: any): Observable<any> {
    return this.postFormData(API_ENDPOINT.add_edit_customer, project, true);
  }


  getById(id: number): Observable<any> {
    return this.get(`${API_ENDPOINT.list_project}?id=${id}`);
  }

  deleteCustomer(id: number) {
    return this.post(`${API_ENDPOINT.remove_customer}?customerId=${id}`);
  }

  getCustomers(model: GetCustomerModel): Observable<any> {
    return this.post(API_ENDPOINT.list_customer, model, true);
  }
}
