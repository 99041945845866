<div class="row">
  <div class="col-12 grid-margin stretch-card">
    <div class="card">
      <form #form="ngForm" (submit)="addUser(form)" autocomplete="on">
        <div class="card-body">
          <h4 class="card-title">THÔNG TIN CƠ BẢN</h4>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <app-text-box class="app-text" type="text" name="email" email="true" [(ngModel)]="model.email"
                  required="true" title="Email">
                  <div invalidmessage="email">
                    <p *invalidType="'required'">Bạn vui lòng nhập email của nhân viên</p>
                    <p *invalidType="'email'">Vui lòng nhập email hợp lệ</p>
                  </div>
                </app-text-box>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">                                
                <app-text-box class="app-text" type="text" name="phone" title="Số điện thoại"
                [(ngModel)]="model.phone">
            </app-text-box>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <app-text-box class="app-text" type="text" name="userCode" [(ngModel)]="model.userCode"
                  title="Mã nhân viên">
                </app-text-box>
              </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                  <app-dropdown-list class="app-text" [defaultValue]="roleTypeDefault" name="role" [data]="role" [(ngModel)]="model.roleOption"
                    title="Chức vụ">
                  </app-dropdown-list>
                </div>
            </div>
          </div>
          <h4 class="card-title">THÔNG TIN CÁ NHÂN</h4>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <app-text-box class="app-text" type="text" name="fullName" [(ngModel)]="model.fullName" required="true"
                  title="Họ và tên">
                  <div invalidmessage="fullName">
                    <p *invalidType="'required'">
                      Bạn vui lòng nhập họ và tên của nhân viên
                    </p>
                  </div>
                </app-text-box>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <app-dropdown-list  [defaultValue]="genderTypeDefault" class="app-text" name="gender" [data]="gender"
                  [(ngModel)]="model.genderOption" title="Giới tính">
                </app-dropdown-list>
              </div>
            </div>
          </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <app-date-picker name="dateJoinCompany" placeholder="Ngày vào công ty"
                               [(ngModel)]="dateJoinCompany" title="Ngày vào công ty" readonly >
              </app-date-picker>
            </div>
          </div>
        </div>
        <h4 class="card-title">THÔNG TIN LIÊN QUAN</h4>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <app-text-box class="app-text" type="number" name="hourSalary" [(ngModel)]="model.hourSalary" min="0" title="Lương theo giờ">
                <div invalidmessage="hourSalary">
                  <p *invalidType="'min'">Vui lòng nhập mức hợp lệ</p>
                </div>
              </app-text-box>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <app-dropdown-list class="app-text" name="timeZone" [defaultValue]="timeZoneDefault" [data]="timeZones" [(ngModel)]="model.timeZoneOption" title="Múi giờ">
              </app-dropdown-list>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <app-text-box class="app-text" type="number" name="lunchMoney" [(ngModel)]="model.lunchMoney" min="0" title="Tiền ăn trưa">
                <div invalidmessage="lunchMoney">
                  <p *invalidType="'min'">Vui lòng nhập mức hợp lệ</p>
                </div>
              </app-text-box>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <app-text-box class="app-text" type="number" name="telephoneFee" [(ngModel)]="model.telephoneFee"
                            min="0" title="Phí điện thoại">
                <div invalidmessage="telephoneFee">
                  <p *invalidType="'min'">Vui lòng nhập mức hợp lệ</p>
                </div>
              </app-text-box>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <app-text-box class="app-text" type="number" name="petrolMoney" [(ngModel)]="model.petrolMoney" min="0" title="Phí xăng xe">
                <div invalidmessage="petrolMoney">
                  <p *invalidType="'min'">Vui lòng nhập mức hợp lệ</p>
                </div>
              </app-text-box>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <app-text-box class="app-text" type="number" name="housingSupport" [(ngModel)]="model.housingSupport"
                            min="0" title="Hỗ trợ tiền nhà">
                <div invalidmessage="housingSupport">
                  <p *invalidType="'min'">Vui lòng nhập mức hợp lệ</p>
                </div>
              </app-text-box>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">

              <app-text-box class="app-text" type="number" name="reduceYourself" [(ngModel)]="model.reduceYourself"
                            min="0" title="Khấu trừ bản thân">
                <div invalidmessage="reduceYourself">
                  <p *invalidType="'min'">Vui lòng nhập mức hợp lệ</p>
                </div>
              </app-text-box>

            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <app-dropdown-list class="app-text" [defaultValue]="salaryTypeDefault" name="salaryType" [data]="salaryType"
                                 [(ngModel)]="model.salaryTypeOption" title="Loại lương">
              </app-dropdown-list>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <app-text-box class="app-text" type="number" name="basicSalary" [(ngModel)]="model.basicSalary" min="0" title="Lương cơ bản">
                <div invalidmessage="basicSalary">
                  <p *invalidType="'min'">Vui lòng nhập mức hợp lệ</p>
                </div>
              </app-text-box>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center align-items-center container">
          <button class="btn btn-success submit-btn mr-2">Xác Nhận</button>
          <button class="btn btn-light" routerLink="/">Hủy</button>
        </div>
      </div>
    </form>
  </div>
</div>
