<div class="account-pages">

  <!-- Begin page -->
  <div class="accountbg"
    [ngStyle]="{'background-image': 'url(' + background.backgroundImage + ')', 'background-repeat': 'no-repeat', 'background-size': 'cover', 'background-position': 'center'}">
  </div>

  <div class="wrapper-page account-page-full">

    <div class="card">
      <div class="card-block">

        <div class="account-box">

          <div class="card-box p-5">
            <h2 class="text-uppercase text-center pb-4">
              <a  class="text-success">
                <span>
                  <app-shared-image [srcImg]="'assets/images/logo.png'" alt="" [height]="72"></app-shared-image>
                </span>
              </a>
            </h2>

            <form #form="ngForm" (submit)="changePassword(form)" autocomplete="on">

              <div class="form-group m-b-20 row">
                <div class="col-12">
                  <app-text-box type="password" title="Mật khẩu cũ" name="oldPassCode" placeholder="*********" required="true"
                                [(ngModel)]="model.oldPasscode" password="true">
                    <div invalidmessage="oldPassCode">
                      <p *invalidType="'required'">Bạn vui lòng nhập mật khẩu cũ</p>
                      <p *invalidType="'password'">Mật khẩu cũ không hợp lệ</p>
                    </div>
                  </app-text-box>
                </div>
              </div>

              <div class="form-group row m-b-20">
                <div class="col-12">
                  <app-text-box type="password" title="Mật khẩu mới" name="newPassCode" placeholder="*********" required="true"
                                [(ngModel)]="model.newPassCode" password="true" validateEqual="confPassCode" reverse="true">
                    <div invalidmessage="newPassCode">
                      <p *invalidType="'required'">Bạn vui lòng nhập mật khẩu mới</p>
                      <p *invalidType="'password'">Mật khẩu mới không hợp lệ</p>
                    </div>
                  </app-text-box>
                </div>
              </div>

              <div class="form-group row m-b-20">
                <div class="col-12">
                  <app-text-box type="password" title="Xác nhận lại mật khẩu" name="confPassCode" placeholder="*********" required="true"
                                password="true" validateEqual="newPassCode" reverse="false" [(ngModel)]="model.confPassCode">
                    <div invalidmessage="confPassCode">
                      <p *invalidType="'required'">Bạn vui lòng nhập lại mật khẩu mới</p>
                      <p *invalidType="'password'">Vui lòng xác nhận lại mật khẩu mới hợp lệ</p>
                      <p *invalidType="'validateEqual'">Xác nhận lại mật khẩu không phù hợp</p>
                    </div>
                  </app-text-box>
                </div>
              </div>

              <div class="form-group row text-center m-t-10">
                <div class="col-12">
                  <button class="btn btn-block btn-custom waves-effect waves-light">Đổi mật khẩu</button>
                </div>
              </div>
              <p class="text-dark font-14 mt-2">
                - Mật khẩu phải có độ dài từ 6-12 kí tự.<br />
                - Mật khẩu phải có ít nhất một kí tự viết hoa.<br />
                - Mật khẩu phải có ít nhất một kí tự viết thường.<br />
                - Mật khẩu phải có ít nhất một kí tự đặc biệt (@, !, #, $, ...)<br />
                - Mật khẩu phải có ít nhất một kí tự số.
              </p>
            </form>
          </div>
        </div>

      </div>
    </div>

    <div class="m-t-40 text-center">
      <p class="account-copyright">{{background.footerText}}</p>
    </div>

  </div>

</div>

<ng-template #customLoadingTemplate>
  <div class="custom-class">
  </div>
</ng-template>
<ngx-loading [show]="elementLoading" [template]="customLoadingTemplate">
</ngx-loading>
