import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { HomepageService } from 'src/app/services/homepage.service';
import { AdminIndexApiModel } from 'src/app/models/homepage.model';
import { CashAdvanceApiModel, UpdateCashAdvanceModel } from 'src/app/models/salary.model';
import { UserShortInfo } from 'src/app/models/userinfo.model';
import { UserService } from 'src/app/services/user.service';
import { SalaryService } from 'src/app/services/salary.service';
import { DatetimeUtils } from 'src/app/utils/dateutil';
import { LeavingService } from 'src/app/services/leaving.service';
import { DayOffModel, UpdateDayOffModel } from 'src/app/models/leaving';
import { DayOffOption } from 'src/app/models/enums';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { DialogService } from 'src/app/services/dialog.service';
import { ChangeDateButtonEnum } from 'src/app/models/enums';

@Component({
  selector: 'app-homepage-admin',
  templateUrl: './homepage-admin.component.html',
  styleUrls: ['./homepage-admin.component.css']
})
export class HomepageAdminComponent implements OnInit {
  date = new Date();
  model = new AdminIndexApiModel();
  dayoffOption: DayOffOption;
  listRequestOff: DayOffModel[] = [];
  updateDayOffModel = new UpdateDayOffModel();

  listUserBirthday: UserShortInfo[] = [];

  listCashadvance: CashAdvanceApiModel[] = [];
  updateCashAdvanceModel = new UpdateCashAdvanceModel();

  constructor(
    private homepageService: HomepageService,
    private userService: UserService,
    private salaryService: SalaryService,
    private leavingService: LeavingService,
    private cd: ChangeDetectorRef,
    public route: ActivatedRoute,
    private dialogService: DialogService,
    public globalService: GlobalService,
  ) {
  }

  ngOnInit() {
    this.getAdminIndex();
    this.getUserHaveBirthdayCurrentMonth();
    this.getListCashadvance();
    this.getListOffNotApproved();
  }

  getAdminIndex() {
    let dateTimeLocal = DatetimeUtils.convertServerDateString(this.date);
    this.homepageService.getAdminHomepage(dateTimeLocal).subscribe(data => {
      this.model = data;
    })
  }

  getUserHaveBirthdayCurrentMonth() {
    this.userService.getUserByBirthdayMonth().subscribe(users => {
      this.listUserBirthday = users;
    })
  }

  getListCashadvance() {
    this.salaryService.getCashAdvanceByStatus(DatetimeUtils.toShortDateTimeFormat(new Date().toString()), 3).subscribe(cash => {
      this.listCashadvance = cash;
    });
  }

  getListOffNotApproved() {
    this.leavingService.getDayoffNotApproved().subscribe(days => {
      this.listRequestOff = days;
    });
  }

  updateDayoff(dayoffId: number, isApprove: boolean) {
    this.updateDayOffModel.dayoffIds.push(dayoffId);
    this.updateDayOffModel.isApprove = isApprove;
    this.dialogService.confirm("Xác nhận", `Bạn có chắc bạn muốn ${isApprove ? 'chấp nhận' : 'từ chối'} yêu cầu nghỉ phép này?`, () => {
    this.leavingService.updateDayoff(this.updateDayOffModel).subscribe(() => {
      this.listRequestOff = [];
      this.updateDayOffModel = new UpdateDayOffModel();
      this.getListOffNotApproved();
      this.cd.detectChanges();
    });
  });
  }

  updateCashAdvance(userId: number, cashAdvanceId: number, isApprove: boolean) {
    this.updateCashAdvanceModel.userId = userId;
    this.updateCashAdvanceModel.cashAdvanceId = cashAdvanceId;
    this.updateCashAdvanceModel.isApprove = isApprove;
    this.dialogService.confirm("Xác nhận", `Bạn có chắc bạn muốn ${isApprove ? 'chấp nhận' : 'từ chối'} yêu cầu ứng lương này?`, () => {
    this.salaryService.updateCashAdvance(this.updateCashAdvanceModel).subscribe(() => {
      this.listCashadvance = [];
      this.updateCashAdvanceModel = new UpdateCashAdvanceModel();
      this.getListCashadvance();
      this.cd.detectChanges();
    });
  });
  }

  updateMonth(code:string) {
    switch (code) {
      case ChangeDateButtonEnum.Previous:
        this.date = DatetimeUtils.addMonths(this.date, -1);
        break;
      case ChangeDateButtonEnum.Next:
        if(DatetimeUtils.addMonths(this.date, 1) > new Date())
          return;
        this.date = DatetimeUtils.addMonths(this.date, 1);
        break;
      default:
        this.date = new Date();
        break;
    }
  this.getAdminIndex();
  }
}
