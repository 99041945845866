<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">{{model.title}}</h5>
  <button type="button" class="close" data-dismiss="modal" (click)="bsModalRef.hide()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <div class="form-group">
    <div id="summernote">
      <p>{{model.initContent}}</p>
    </div>
  </div>

</div>
<div class="modal-footer">
  <button class="btn btn-success btn-fw" type="button" (click)="submit()">
    <i class="mdi mdi-checkbox-marked-circle mr-2"></i>{{model.submitBtnText}}</button>
  <button class="btn btn-danger btn-fw" type="button" (click)="noBtnClicked()">
    <i class="mdi mdi-close-circle mr-2"></i>Hủy</button>
</div>