<div class="container-scroller">
  <div class="container-fluid page-body-wrapper full-page-wrapper auth-page">
    <div class="content-wrapper d-flex align-items-center auth register-bg-1 theme-one">
      <div class="row w-100">
        <div class="col-lg-4 mx-auto">
          <h2 class="text-center mb-4">Kích hoạt tài khoản CCT</h2>
          <div class="auto-form-wrapper">
            <div class="form-group">
              <button class="btn btn-primary submit-btn btn-block" (click)="activateAccount()">Xác Nhận</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- content-wrapper ends -->
  </div>
  <!-- page-body-wrapper ends -->
</div>