import { AuthService } from '../../services/auth.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Component, OnInit, Inject, forwardRef, ChangeDetectorRef, OnDestroy, AfterViewInit } from '@angular/core';
import { AppComponent } from '../../app.component';
import { NavigationService } from '../../services/navigation.service';
import { LocalService } from '../../services/common/local.service';
import { GlobalService } from '../../services/global.service';
import { Subscription, BehaviorSubject } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { SalaryService } from 'src/app/services/salary.service';
import { CurencyExchangeModel } from 'src/app/models/salary.model';
import { UserInfo } from 'src/app/models/userinfo.model';
import { SettingModel } from 'src/app/models/setting.model';
import { SettingService } from 'src/app/services/setting.service';
import { DatetimeUtils } from 'src/app/utils/dateutil';
import { RealtimeClientService } from 'src/app/services/realtime.client.service';
import { NotificationService } from 'src/app/services/notify.service';
import { NotifyModel, NotificationsIdsModel, NotificationsModel } from 'src/app/models/notify.model';
import { AlertGroups, UserPosition } from 'src/app/models/enums';
import { DialogService } from 'src/app/services/dialog.service';
import { MenuItems } from 'src/app/models/enums';
import { LOCAL_STORAGE_VARIABLE, LEFT_MENU_USER, LEFT_MENU_ADMIN } from 'src/app/app.constants';
import { filter, tap } from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.css']
})
export class MainLayoutComponent extends NavigationService implements OnInit, OnDestroy, AfterViewInit {

  currencyModel = new CurencyExchangeModel();
  elementLoading: boolean;
  isAdmin: boolean;
  loaderSubscribe: Subscription;
  user = new UserInfo();
  settingConfig = new SettingModel();
  userName: any;
  active = false;
  currDate: string;
  unreadMessageCount: number;
  notifications: NotifyModel[];
  notifyList: BehaviorSubject<NotifyModel[]>;
  alertGroup = AlertGroups;
  isNotifyBadgeOpening: boolean;
  notifyPushed: Subscription;
  notifiesUpdated: Subscription;
  notifyCountUpdated: Subscription;
  forceLogOut: Subscription;
  title: string;
  currentMenuItem: MenuItems;
  tempMenuItem: MenuItems;
  menuUserData = LEFT_MENU_USER;
  menuAdminData = LEFT_MENU_ADMIN;

  constructor(router: Router,
    private route: ActivatedRoute,
    private realtimeService: RealtimeClientService,
    @Inject(forwardRef(() => AppComponent)) private appComm: AppComponent,
    private globalService: GlobalService,
    private cdref: ChangeDetectorRef,
    private salaryService: SalaryService,
    private settingService: SettingService,
    private userService: UserService,
    private authService: AuthService,
    private dialogService: DialogService,
    private notifyService: NotificationService) {

    super(router);

    this.currentMenuItem = MenuItems.None;
    this.tempMenuItem = MenuItems.None;

    this.subscribeNavigationEnd();
  }

  ngOnInit() {

    let isFirstLogin = LocalService.getItem(LOCAL_STORAGE_VARIABLE.is_first_login);
    if (isFirstLogin === "true") this.router.navigate(['change-password']);
    this.currentMenuItem = MenuItems.None;
    this.notifications = [];

    this.notifiesUpdated = this.realtimeService.notifyPushedList.subscribe(() => {
    })

    this.notifyPushed = this.realtimeService.notifyPushed.subscribe(notify => {
      this.globalService.notifyCommingInvoke(notify);
      this.notifications.unshift(notify);
      this.unreadMessageCount += 1;
      this.notifyService.pushServerToast(notify.message, notify.title, () => {
        this.notifyOnClickURL(notify.id);
      });

    });

    this.notifyCountUpdated = this.realtimeService.notifyCountUpdated.subscribe(count => {
      this.unreadMessageCount = count;
    });

    this.forceLogOut = this.realtimeService.forceLogOut.subscribe(() => {
      this.realtimeService.disConnecting(() => {
        this.authService.logOut()
          .subscribe(() => {
            LocalService.logout();
            this.dialogService.message("Thông Báo", "Bạn đã mất quyền truy cập hệ thống!!", () => {
              this.router.navigate(['login']);
            }, "Đăng Xuất");

          });
      });
    });

    this.realtimeService.clientInit(LocalService.getAccessToken(), LocalService.getUserId(), () => {
      this.globalService.signalRClientInitDone();
      this.getNotifications(1,10, AlertGroups.All);
    });

    this.isAdmin = AuthService.isAdmin();
    this.userName = LocalService.getUserName();
    this.currDate = DatetimeUtils.toShortDateFormat((new Date()).toDateString());

    this.loaderSubscribe = this.globalService.loader
      .subscribe(status => {
        this.elementLoading = status;
        this.cdref.detectChanges();
      });

    this.userService.getUserProfile()
      .subscribe((res) => {
        if (res) {
          this.user = res;
        }
      });

    this.settingService.getSetting()
      .subscribe(data => {
        if (data) {
          this.settingConfig = data;
        }
      })
  }

  ngAfterViewInit() {

    super.ngAfterViewInit();
    jsFile.forEach((item) => {
      if (document.getElementById(item.name)) {
        document.getElementById(item.name).remove();
      }
      let script = document.createElement("script");
      script.setAttribute("id", item.name);
      script.setAttribute("src", item.src);
      document.body.appendChild(script);
    });

    setTimeout(() => {
      this.currentMenuItem = this.tempMenuItem;
    })
  }

  subscribeNavigationEnd() {
    this.router
      .events
      .pipe(filter(e => e instanceof NavigationEnd))
      .pipe(map(() => this.route))
      .pipe(map(route => {
        while(route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }))
      .pipe(filter(route => route.outlet === 'primary'))
      .subscribe(route => {
        let data = route.data.subscribe(data => {

          this.title = data.title;
          if (data.menuGroup) {
            this.tempMenuItem = data.menuGroup;
            this.currentMenuItem = data.menuGroup;
          }
          else {
            this.tempMenuItem = MenuItems.None;
            this.currentMenuItem = MenuItems.None;
          }
          // initModule();
        });

      });
  }

  getNotifications(page: number = 0, numOfPage: number = 0,notifyType : AlertGroups) {
    this.getNotificationList(page, numOfPage , notifyType)
      .then(notifies => {
        this.notifications = notifies.notifyMessageApis;
        this.realtimeService.revokeUnreadNotifyCount(LocalService.getUserId());
        this.globalService.notifyListComing(notifies);
      });

  }

  getNotificationList(page: number = 0, numOfPage: number = 0,notifyType : AlertGroups ): Promise<NotificationsModel> {
    if (this.realtimeService.isServerConnected()) {
      return this.realtimeService.getNotifications(LocalService.getUserId(), page , numOfPage , notifyType);
    }
    else {
      return new Promise<NotificationsModel>(null);
    }
  }

  markAsreadAddNotify() {
    this.realtimeService.markAsreadAllNotifications(LocalService.getUserId()).then(() => {
      this.notifications.forEach((notifications) => {
        notifications.isRead = true;
      });
      this.unreadMessageCount = 0;
    })
  }

  markAsUnReadNotify(id: number) {
    let notify = this.notifications.filter(p => p.id == id)[0];
    if (notify.isRead) {
      this.realtimeService.asReadNotify(notify.id, false).then((res) => {
        if (!res)
          return;
        this.unreadMessageCount += 1;
        notify.isRead = false;
      });
    }
  }

  markAsUnReadOrReadNotifies(model: NotificationsIdsModel, counter: number, page: number = 1, numOfPage: number = 10, notifyType : number) {
      this.realtimeService.asReadNotifies(model).then((res) => {
        if (!res)
          return;
        this.unreadMessageCount -= counter;
        setTimeout(() => {
        this.getNotifications(page, numOfPage,notifyType);
        });
    });
  }

  //remove a message
  removeNotify(id: number) {
    this.realtimeService.removeNotify(id, LocalService.getUserId());
  }

    //remove lots of notifies
  removeNotifies(model: NotificationsIdsModel) {
    this.realtimeService.removeNotifies(model);
  }

  //Click and stay in the same page
  notifyOnClick(id: number) {
    let notify = this.notifications.filter(p => p.id == id)[0];
    if (!notify.isRead) {
      this.realtimeService.asReadNotify(notify.id, true).then((res) => {
        if (!res)
          return;
        this.unreadMessageCount -= 1;
        notify.isRead = true;
      });
    }
  }

  //Click to open a new link
  notifyOnClickURL(id: number) {
    let notify = this.notifications.filter(p => p.id == id)[0];
    if (!notify.isRead)
      this.realtimeService.asReadNotify(notify.id, true).then((res) => {
        if (!res)
          return;
        this.unreadMessageCount -= 1;
        notify.isRead = true;
      });

    if (notify.link) {
      var current = this.router.url;

      if (current != notify.link)
        this.router.navigate([notify.link]);
      else {
        location.reload();
      }
    }
  }

  getLabourContract() {
    this.userService.exportContract();
  }

  logOut() {
    try {
      this.realtimeService.disConnecting(() => {
        this.authService.logOut()
          .subscribe(() => {
            LocalService.logout();
            this.router.navigate(['login']);
          });
      });
    }
    catch {
      this.router.navigate(['login']);
    }

  }

  screenReload() {

    this.userService.getUserProfile()
      .subscribe((res) => {
        if (res) {
          this.user = res;
        }
      });

    this.settingService.getSetting()
      .subscribe(data => {
        if (data) {
          this.settingConfig = data;
          this.appComm.updateFavIconAndTitle(this.settingConfig.favIcon, this.settingConfig.documentTitle);
        }
      })
  }

  ngOnDestroy() {
    this.loaderSubscribe.unsubscribe();
    this.forceLogOut.unsubscribe();
    this.notifyPushed.unsubscribe();
    this.notifyCountUpdated.unsubscribe();
    this.notifiesUpdated.unsubscribe();
  }

  navigationStarted() {
    this.elementLoading = false;
    this.appComm.navigationStarted();

  }
  navigationEnded() {
    this.appComm.navigationEnded();
  }
  navigationCanceled() {
  }
  navigationRecognized() {
  }

  nvaigateToComponent() {
    this.router.navigate(['']);
  }

  viewProfile() {
    this.router.navigate(['/user-profile']);
  }

}

export const jsFile = [
  {
    name: "metisMenu",
    src: "../assets/js/metisMenu.min.js"
  },
  {
    name: "jqueryApp",
    src: "../assets/js/jquery.app.js"
  },
  {
    name: "jqueryCore",
    src: "../assets/js/jquery.core.js"
  }
]
