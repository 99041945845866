<div class="row">
  <div class="col-xl-4">
    <!-- Personal-Information -->
    <div class="card-box">
      <h4 class="header-title mt-0 m-b-20">Thông tin cá nhân</h4>
      <div class="panel-body">
        <p class="text-muted font-13">
          Thông tin cơ bản nhân viên
        </p>

        <hr />

        <div class="text-left">
          <p class="text-muted font-13"><strong>Ngày sinh: </strong> <span class="m-l-15">{{user.birthday}}</span></p>

          <p class="text-muted font-13"><strong>SĐT: </strong><span class="m-l-15">{{user.phone}}</span></p>

          <p class="text-muted font-13"><strong>Email: </strong> <span class="m-l-15">{{user.email}}</span>
          </p>

          <p class="text-muted font-13"><strong>Thời gian đã làm ở công ty: </strong> <span
              class="m-l-15">{{user.workingTimeCounting}}</span>
          </p>

          <p class="text-muted font-13"><strong>Thời gian vào công ty: </strong> <span
              class="m-l-15">{{user.dateJoinCompany}}</span>
          </p>

          <p class="text-muted font-13">
            <strong>Lần truy cập gần nhất: </strong>
            <span class="m-l-15">{{ user.lastLogin }}</span>
          </p>

          <p class="text-muted font-13"><strong>Múi giờ: </strong> <span class="m-l-15">{{user.timeZoneName}}</span>
          </p>

        </div>

        <ul class="social-links list-inline m-t-20 m-b-0">
          <li class="list-inline-item">
            <a title="" data-placement="top" data-toggle="tooltip" class="tooltips"
              href="https://www.facebook.com/{{user.facebookId}}" data-original-title="Facebook"><i
                class="fa fa-facebook"></i></a>
          </li>
          <li class="list-inline-item">
            <a title="{{user.skypeId}}" data-placement="top" data-toggle="tooltip" class="tooltips"
              data-original-title="Skype"><i class="fa fa-skype"></i></a>
          </li>
          <li class="list-inline-item">
            <a id="tooltip-salary"><i class="fa fa-usd"></i></a>
          </li>
        </ul>
      </div>
    </div>
    <!-- Personal-Information -->
    <div class="card-box ribbon-box">
      <div class="ribbon ribbon-info">Danh sách tạm ứng lương</div>
      <div class="clearfix"></div>
      <div class="inbox-widget  slimscroll" *ngIf="listCashAdvance.length > 0">
          <div class="inbox-item" *ngFor="let cash of listCashAdvance" title="Lí do ứng lương: {{cash.reason}}">
              <p class="inbox-item-author">Tạm ứng lương</p>
              <p class="inbox-item-text">
                Bạn đã yêu cầu tạm ứng số tiền
                <span class="font-500 text-dark">{{cash.cash | appNumber}} VNĐ </span>
                vào ngày
                <span class="font-500 text-dark">{{cash.dateTime | date:"dd/MM/yyyy"}}</span>
              </p>
              <p class="inbox-item-date m-t-10">
                <span *ngIf="cash.status == 1" class="badge badge-success font-13">Đã chấp nhận</span>
                <span *ngIf="cash.status == 2" class="badge badge-danger font-13">Đã từ chối</span>
                <span *ngIf="cash.status == 3" class="badge badge-warning font-13">Chờ xác nhận</span>
            </p>
          </div>

      </div>
      <div class="inbox-widget" *ngIf="listCashAdvance.length == 0">
        <div class="inbox-item">

          <p class="inbox-item-text">Không có tạm ứng lương nào.</p>

        </div>
      </div>
    </div>
  </div>


  <div class="col-xl-8">
    <div class="text-center">
      <div class="row">

        <div class="col-md-6 col-xl-4">
          <div class="card-box card-normal bg-primary widget-flat border-primary text-white">
            <i class="fi-stack-2"></i>
            <h3 class="m-b-10">{{userIndex.totalProjects}}</h3>
            <p class="text-uppercase m-b-5 font-13 font-600">Dự án đã và đang làm</p>
          </div>
        </div>
        <div class="col-md-6 col-xl-4">
          <div class="card-box card-normal widget-flat border-success bg-success text-white">
            <i class="fi-sun"></i>
            <h3 class="m-b-10">{{userIndex.dayOffInYear}}</h3>
            <p class="text-uppercase m-b-5 font-13 font-600">Số ngày đã nghỉ</p>
          </div>
        </div>
        <div class="col-md-6 col-xl-4">
          <div class="card-box card-normal bg-danger widget-flat border-danger text-white">
            <i class="fi-sun"></i>
            <h3 class="m-b-10">{{userIndex.totalDayOffInYear}}</h3>
            <p class="text-uppercase font-13 font-600">Số ngày nghỉ còn lại</p>
          </div>
        </div><!-- end col -->
      </div>
    </div>
    <!-- end row -->

    <!-- Project-working -->
    <div class="card-box">
      <div class="card-title font-500 font-18"> Dự án đang tham gia </div>
      <nz-table
      nzVirtualScroll
      [nzData]="projects"
      [nzFrontPagination]="false"
      [nzShowPagination]="false"
      [nzScroll]="{ y: '240px' }">
      <thead class="project-header">
        <tr class="project-header-row">
          <th>Tên dự án</th>
          <th>Ảnh dự án</th>
          <th>Khách hàng</th>
          <th>Trạng thái</th>
          <th>Thành viên</th>
        </tr>
      </thead>
      <tbody *ngIf="projects">
        <ng-template nz-virtual-scroll let-data let-index="index">
          <tr class="project-data-row">
            <td>{{data.projectName}}</td>
            <td>
              <app-shared-image [srcImg]="data.projectLogo | image"
                                [classImg]="'rounded-circle'"
                                [altImg]="'friend'"
                                [titleImg]="data.projectName"
                                [width]="60"
                                [height]="60">
              </app-shared-image>
            </td>
            <td>{{data.customerName}}</td>
            <td>
              <div *ngIf="data.status == 1" class="badge badge-custom font-13">
                <span>{{data.statusModel.name}}</span></div>
              <div *ngIf="data.status == 2" class="badge badge-warning font-13">
                <span>{{data.statusModel.name}}</span></div>
              <div *ngIf="data.status == 3" class="badge badge-danger font-13">
                <span>{{data.statusModel.name}}</span></div>
              <div *ngIf="data.status == 4" class="badge badge-success font-13">
                <span>{{data.statusModel.name}}</span></div>
            </td>
            <td *ngIf="data.userInfos">
              <div *ngIf="data.userInfos.length > 4">
                <span *ngFor="let user of data.userInfos.slice(0, 4)">
                    <app-shared-image [srcImg]="user.avatar | image"
                                      [classImg]="'rounded-circle img-cover'"
                                      [altImg]="'image'"
                                      [titleImg]="user.fullName"
                                      [height]="40"
                                      [width]="40">
                    </app-shared-image>
                </span>
                <i class="mdi mdi-dots-horizontal align-bottom bottom-dots"></i>
              </div>
              <div *ngIf="data.userInfos.length <= 4">
                <span *ngFor="let user of data.userInfos">
                  <app-shared-image [srcImg]="user.avatar | image"
                              [classImg]="'rounded-circle img-cover'"
                              [altImg]="'image'"
                              [titleImg]="user.fullName"
                              [height]="40"
                              [width]="40">
                  </app-shared-image>
                </span>
              </div>
            </td>
          </tr>
        </ng-template>
      </tbody>
      </nz-table>
    </div>
    <!-- end -->

    <div class="row">
      <div class="col-xs-6 col-md-6">
        <div class="card-box ribbon-box">
          <div class="ribbon ribbon-custom">Danh sách tăng ca</div>
          <div class="clearfix"></div>
          <div class="inbox-widget  slimscroll" *ngIf="listOvertime.length > 0">
              <div class="inbox-item " *ngFor="let request of listOvertime" title="Chi tiết công việc: {{request.description}}">
                  <p class="inbox-item-author">Ngày <span class="font-500 text-dark">{{request.submitTime | date:"dd/MM/yyyy"}}</span> </p>
                  <p class="inbox-item-text">
                    Bạn đã tăng ca từ
                    <span class="text-dark font-500">
                      {{request.timeStart | date: 'h:mm a'}}</span>
                     đến
                    <span class="text-dark font-500">
                      {{request.timeFinish | date: 'h:mm a'}}</span></p>
                <p class="inbox-item-date m-t-10">
                <span *ngIf="request.isLocked" class="badge badge-secondary font-13">
                  {{ request.workTime }} giờ</span>
                  <span *ngIf="!request.isLocked" class="badge badge-success font-13">
                  {{ request.workTime }} giờ</span>
                </p>
              </div>

          </div>
          <div class="inbox-widget" *ngIf="listOvertime.length == 0">
            <div class="inbox-item">

              <p class="inbox-item-text">Bạn chưa tăng ca trong tháng này.</p>

            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-6 col-md-6">
        <div class="card-box ribbon-box">
          <div class="ribbon ribbon-danger">Danh sách xin nghỉ phép</div>
          <div class="clearfix"></div>
          <div class="inbox-widget  slimscroll" *ngIf="listRequestOff.length > 0">
              <div class="inbox-item " *ngFor="let request of listRequestOff" title="Lí do xin nghỉ: {{request.reason}}">
                  <p class="inbox-item-author">Ngày
                    <span class="text-dark font-500">
                    {{request.dateTime | date: 'dd/MM/yyyy'}}</span>
                  </p>
                  <p class="inbox-item-text">Thời gian nghỉ:  <span class="text-dark font-500">
                      {{request.option == 1 ? "cả ngày" : (request.option==2 ? "buổi sáng" : "buổi chiều")}}</span></p>
                      <p class="inbox-item-date m-t-10">
                        <span *ngIf="request.dayOffStatus == 1" class="badge badge-success font-13">Đã chấp nhận</span>
                        <span *ngIf="request.dayOffStatus == 3 || request.dayOffStatus == 0" class="badge badge-danger font-13">Đã từ chối</span>
                        <span *ngIf="request.dayOffStatus == 2" class="badge badge-warning font-13">Chờ xác nhận</span>
                    </p>
              </div>

          </div>
          <div class="inbox-widget" *ngIf="listRequestOff.length == 0">
            <div class="inbox-item">

              <p class="inbox-item-text">Không có nhân viên nào xin nghỉ phép.</p>

            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
