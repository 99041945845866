<div class="container-fluid">
  <div class="row">
        <div class="card-box col-md-12">
          <!-- Left sidebar for template  -->
          <div class="inbox-leftbar">

            <a routerLink="/reward-compose" class="btn btn-danger btn-block waves-effect waves-light">Soạn mẫu</a>

            <div class="mail-list mt-4">
              <a routerLink="/reward-list"
                class="{{viewStatus == 0 ? 'text-danger' : 'text-muted' }}  list-group-item border-0 "><i
                  class="mdi mdi-inbox font-18 align-middle mr-2"></i>Biểu mẫu<span
                  class="badge badge-danger float-right ml-2 mt-1"></span></a>

              <a routerLink="/reward-list/2"
                class="{{viewStatus == 2 ? 'text-danger' : 'text-muted' }} list-group-item border-0"><i
                  class="mdi mdi-file-document-box font-18 align-middle mr-2"></i>Bản nháp<span
                  class="badge badge-info float-right ml-2 mt-1"></span></a>
              <a [routerLink]="['/reward-list', 1, true ]" 
                class="{{viewStatus == 1 ? 'text-danger' : 'text-muted' }} list-group-item border-0">
                <i class="mdi mdi-send font-18 align-middle mr-2"></i>Đã gửi</a>

            </div>

          </div>
          <!-- End Left sidebar -->

          <!-- right sidebar for list of rewards -->
          <div class="inbox-rightbar">
            <span class="justify-content-end d-flex align-items-center mb-3 mr-0">
              Tìm: &nbsp;
              <app-text-box type="text"
                            [placeholder]="'tiêu đề..'"
                            [(ngModel)]="titleSearch"
                            (ngModelChange)="searchByTitle()">
              </app-text-box>
            </span>
            <nz-table
              #filterTable
              nzShowPagination
              nzShowSizeChanger
              [nzData]="listOfDisplayRewards"
              nzPageSize="50">
              <thead (nzSortChange)="sort($event)" nzSingleSort>
                <tr>
                  <th nzShowSort nzSortKey="created">
                    Ngày thưởng
                  </th>
                  <th nzShowSort nzSortKey="subject">
                    Tiêu đề
                  </th>
                  <th nzShowSort nzSortKey="value">
                    Tiền thưởng
                  </th>
                  <th>
                    Nhân viên
                  </th>
                  <th>
                    Tác vụ
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let rew of filterTable.data" class="{{ rew.isSendReward ? 'reward-not-send' : ''}}">
                  <td>
                    {{rew.created | date: 'dd/MM/yyyy'}}
                  </td>
                  <td>
                    {{rew.subject}}
                  </td>
                  <td>
                    {{rew.value | appNumber}} VNĐ
                  </td>
                  <td *ngIf="rew.users">
                    <div *ngIf="rew.users.length > 4">
                      <span *ngFor="let user of rew.users.slice(0, 4)">
                        <app-shared-image [srcImg]="user.userImage | image"
                                          [classImg]="'rounded-circle img-cover'"
                                          [altImg]="'image'"
                                          [titleImg]="user.userName"
                                          [height]="48"
                                          [width]="48">
                        </app-shared-image>
                      </span>
                      <i class="mdi mdi-dots-horizontal align-bottom bottom-dots"></i>
                    </div>
                    <div *ngIf="rew.users.length <= 4">
                      <span *ngFor="let user of rew.users">
                        <app-shared-image [srcImg]="user.userImage | image"
                                          [classImg]="'rounded-circle img-cover'"
                                          [altImg]="'image'"
                                          [titleImg]="user.userName"
                                          [height]="48"
                                          [width]="48">
                        </app-shared-image>
                      </span>
                    </div>
                  </td>
                  <td>
                    <button [attr.data-item-id]="rew.id" type="button" title="Xem nội dung"
                      class="btn btn-sm btn-custom mr-2 viewReward">
                      <i class="mdi mdi-file-document-box font-18 align-middle"></i>
                    </button>
                    <button *ngIf="!rew.isSendReward" [attr.data-item-id]="rew.id" type="button"
                      title="Xóa khen thưởng" class="btn btn-sm btn-danger deleteReward">
                      <i class="mdi mdi-delete font-18 align-middle"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </nz-table>
          </div>
          <!-- End Right sidebar -->
        </div>
      </div>
</div>
