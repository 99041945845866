import { AlertGroups } from './enums';
import { DropdownModel } from './dropdown.model';

export class NotifyModel {
    id: number;
    message: string;
    title: string;
    link: string;
    isRead: boolean;
    userId: number;
    alertGroups: AlertGroups;
    pushedTime: Date;
    alertGroupModel: DropdownModel
    isChecked: boolean;
}

export class NotificationsIdsModel
{
    constructor(listId, userId, isRead) {
        this.listIds = listId;
        this.userId = userId;
        this.isRead = isRead;
    }
    listIds: number[];
    userId: number;
    isRead: boolean;
}
export class NotificationsModel
{
    notifyMessageApis : NotifyModel[];
    total:number
    constructor() {
        this.notifyMessageApis = [];
    }
}
export class NotifyRequestModel
{
    userid : number;
    page : number;
    numofpage : number;
    notifyType : AlertGroups;

    constructor(userid : number,page : number, numofpage : number,notifyType : AlertGroups ) {
        this.userid = userid;
        this.page = page;
        this.numofpage = numofpage;
        this.notifyType = notifyType;
    }
}