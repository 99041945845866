import { Injectable } from '@angular/core';
import { BaseService } from './common/base.service';
import { HttpClient } from '@angular/common/http';
import { ErrorService } from './common/error.service';
import { GlobalService } from './global.service';
import { Observable } from 'rxjs';
import { API_ENDPOINT } from '../app.constants';
import { GetDepartmentModel } from '../models/department.models';

@Injectable({
  providedIn: 'root'
})
export class DepartmentService extends BaseService {

  constructor(protected http: HttpClient,
    protected errorHandler: ErrorService,
    protected globalService: GlobalService) {
    super(http, errorHandler, globalService);

  }

  updateDepartmentLogo(file: File, id: number) {
    return this.postFormData(API_ENDPOINT.update_department_logo, { id: id, uploadedFile: file }, true);
  }

  addOrEditDepartment(department: any): Observable<any> {
    const url = department.id ? API_ENDPOINT.edit_department : API_ENDPOINT.add_department;
    return this.post(url, department);
  }

  deleteDepartment(id: number) {
    return this.post(`${API_ENDPOINT.delete_department}?departmentId=${id}`);
  }

  getDepartments(model: GetDepartmentModel): Observable<any> {
    return this.post(API_ENDPOINT.list_department, model, true);
  }
}
