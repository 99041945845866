import { Component, OnInit } from '@angular/core';
import {DropdownModel} from 'src/app/models/dropdown.model';
import { UserService } from 'src/app/services/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UserShortInfo } from 'src/app/models/userinfo.model';
import { NgForm } from '@angular/forms';
import { CustomerService } from 'src/app/services/customer.service';
import { CustomerModel, GetCustomerModel } from '../../../models/customer.models';
import { GlobalService } from 'src/app/services/global.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'customer',
  templateUrl: './add-customer.component.html',
  styleUrls: ['./add-customer.component.css']
})
export class AddCustomerComponent implements OnInit {
  model = new CustomerModel();
  status:  DropdownModel[];
  hardCodeUserList: DropdownModel[];
  customerUser: UserShortInfo[] = [];
  avatar: any;
  logoImageName: string;
  action: string;
  isAdmin: boolean;
  defaultValue: DropdownModel;
  constructor(
    private router: Router,
    private customerService: CustomerService,
    public route: ActivatedRoute,
    public globalService: GlobalService,
  ) {
  }

  ngOnInit() {
    this.isAdmin = AuthService.isAdmin();

    this.route.params.subscribe(params => {
      this.action = "Hoàn tất";

      if (!params['id']) {
        this.model.id = 0;
        this.hardCodeUserList = [];
      }
      else {
        var id = +params['id'];
        let getModel = new GetCustomerModel(0,id);
        this.customerService.getCustomers(getModel)
          .subscribe(customer => {
            this.model = customer[0];
          });
      }
      console.log(params);
    });
  }

  onChangeLogo(event) {
    const filesUpload1: File = event.target.files[0];
    const reader1 = new FileReader();
    reader1.onload = () => this.avatar = reader1.result;
    reader1.readAsDataURL(filesUpload1);
    this.model.uploadedFile = filesUpload1;
    this.logoImageName = filesUpload1.name.toString();
  }

  addCustomer(form: NgForm) {
    if (form.invalid)
      return;

    if (this.model.id != 0) {
      this.customerService.editCustomer(this.model)
        .subscribe(() => {
          this.router.navigate(['/list-customer']);
        });
    } else {
      this.customerService.addCustomer(this.model)
        .subscribe(() => {
          this.router.navigate(['/list-customer']);
        });
    }

  }
}
