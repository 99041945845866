export class HomepageModel {
    dayOffInMonth: number;
    totalDayOffRemaining: number;
    totalOverTime: number;
};

export class AdminIndexApiModel {
    totalEmployee: number;
    totalProject: number;
    totalOverTime: number;
    totalInsurrance: number;
    totalTax: number;
}