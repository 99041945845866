import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'activate-user',
  templateUrl: './activate-user.component.html',
  styleUrls: ['./activate-user.component.css']
})
export class ActivateUserComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router, private userServie: UserService) { }
  token: string;
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params['token']) {
        this.token = params['token'];
      }
    });
  }
  activateAccount() {
    this.userServie.activate(this.token)
      .subscribe(res => {
        this.router.navigate(['/login']);
      })
  }
}
