<div class="row">
    <div class="col-12 grid-margin stretch-card">
        <div class="card-box">
            <form #formInfo="ngForm" (submit)="editUser(formInfo)" autocomplete="on">
                <div class="card-body">
                    <h4 class="card-title">CẬP NHẬT THÔNG TIN</h4>
                    <div class="row d-flex justify-content-center align-items-center">
                        <div class="thumb-xl member-thumb m-b-10">
                            <app-shared-image [classImg]="'rounded-circle img-thumbnail no-border'"
                                [srcImg]="avatar ? avatar : userInfo.avatar | image" [altImg]="'image'">
                            </app-shared-image>
                        </div>
                        <div *ngIf="isSelfEditting">
                            <div class="col-sm-12">
                                <input type="file" class="file-upload-default" (change)="onChange($event)" #imageUpload
                                    hidden>
                                <div class="input-group">
                                    <input type="text" value="{{imageInfo}}" class="form-control file-upload-info"
                                        disabled placeholder="Tải ảnh lên">
                                    <span class="input-group-append">
                                        <button class="btn btn-info" type="button" (click)="imageUpload.click()">Tải
                                            Lên</button>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <nz-divider nzText="Thông tin các nhân" nzOrientation="left"></nz-divider>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label class="col-sm-4 col-form-label">Mã nhân viên</label>
                                <label *ngIf="isSelfEditting"
                                    class="col-sm-8 col-form-label font-weight-bold">{{userInfo.userCode}}</label>
                                <div *ngIf="!isSelfEditting" class="col-sm-8">
                                    <app-text-box class="app-text" type="text" name="userCode"
                                        [(ngModel)]="userInfo.userCode">
                                    </app-text-box>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6" *ngIf="isAdmin">
                            <div class="form-group row">
                                <label class="col-sm-4 col-form-label">Làm mới hợp đồng</label>                               
                                <div class="checkbox checkbox-custom checkbox-single col-sm-5 col-form-label">
                                    <input type="checkbox" name="isResetContract" [(ngModel)]="userInfo.isResetContract" [disabled]="!isAdmin">
                                    <label class=""></label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label class="col-sm-4 col-form-label">Họ và tên</label>
                                <label *ngIf="!isSelfEditting"
                                    class="col-sm-8 col-form-label font-weight-bold">{{userInfo.fullName}}</label>
                                <div *ngIf="isSelfEditting" class="col-sm-8">
                                    <app-text-box class="app-text" type="text" name="fullName"
                                        [(ngModel)]="userInfo.fullName" required="true">
                                    </app-text-box>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group row ">
                                <label class="col-sm-4 col-form-label">Email</label>
                                <label *ngIf="!isSelfEditting"
                                    class="col-sm-8 col-form-label font-weight-bold">{{userInfo.email}}</label>
                                <div *ngIf="isSelfEditting" class="col-sm-8">
                                    <app-text-box class="app-text" type="email" email="true" name="email"
                                        [(ngModel)]="userInfo.email" required>
                                        <div invalidmessage="email">
                                            <p *invalidType="'required'">Bạn vui lòng nhập email</p>
                                            <p *invalidType="'email'">Vui lòng nhập email hợp lệ</p>
                                        </div>
                                    </app-text-box>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="isAdmin" class="row">
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label class="col-sm-4 col-form-label">Loại lương</label>
                                <div class="col-sm-8">
                                    <app-dropdown-list class="app-text" [defaultValue]="defaultSalaryType"
                                        name="salaryType" [data]="salaryTypeModel"
                                        [(ngModel)]="userInfo.salaryTypeOption">
                                    </app-dropdown-list>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label class="col-sm-4 col-form-label">Số người phụ thuộc</label>
                                <label *ngIf="isSelfEditting"
                                    class="col-sm-8 col-form-label font-weight-bold">{{userInfo.numberOfDenpendents}}</label>
                                <div *ngIf="!isSelfEditting" class="col-sm-8">
                                    <app-text-box class="app-text" type="text" name="numberOfDenpendents"
                                        [(ngModel)]="userInfo.numberOfDenpendents">
                                    </app-text-box>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label class="col-sm-4 col-form-label">SĐT</label>
                                <label *ngIf="!isSelfEditting"
                                    class="col-sm-8 col-form-label font-weight-bold">{{userInfo.phone}}</label>
                                <div *ngIf="isSelfEditting" class="col-sm-8">
                                    <app-text-box class="app-text" type="text" phone=true name="phone"
                                        [(ngModel)]="userInfo.phone" required>
                                        <div invalidmessage="phone">
                                            <p *invalidType="'required'">Bạn vui lòng nhập sdt</p>
                                            <p *invalidType="'phone'">Vui lòng nhập sdt hợp lệ</p>
                                        </div>
                                    </app-text-box>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label class="col-sm-4 col-form-label">Giới tính</label>
                                <div *ngIf="!isSelfEditting" [ngSwitch]="userInfo.genderModel?.value">
                                    <label class="badge badge-info ml-3"
                                        *ngSwitchCase="1">{{userInfo.genderModel.name}}</label>
                                    <label class="badge-success badge ml-3"
                                        *ngSwitchCase="2">{{userInfo.genderModel.name}}</label>
                                    <label class="badge-danger badge ml-3"
                                        *ngSwitchCase="3">{{userInfo.genderModel.name}}</label>
                                </div>
                                <div *ngIf="isSelfEditting" class="col-sm-8">
                                    <app-dropdown-list class="app-text" [defaultValue]="defaultGender" name="gender"
                                        title="Giới tính" [data]="genderModel" [(ngModel)]="userInfo.genderOption"
                                        required="true">
                                    </app-dropdown-list>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isAdmin" class="col-md-6">
                            <div class="form-group row">
                                <label class="col-sm-4 col-form-label">Ngày vào công ty</label>
                                <div class="col-sm-8">
                                    <div class="input-group">
                                        <input type="text" name="dateJoinCompany" placeholder="Ngày vào công ty"
                                            [(ngModel)]="userInfo.dateJoinCompany" class="form-control"
                                            #dpr="bsDatepicker" [bsConfig]="bsConfig" required bsDatepicker>
                                        <div class="input-group-prepend bg-primary border-primary"
                                            (click)="dpr.toggle()" [attr.aria-expanded]="dpr.isOpen">
                                            <span class="input-group-text bg-primary border-primary">
                                                <i class="mdi mdi-calendar-text text-white"></i>
                                            </span>
                                        </div>
                                        <div invalidmessage="dateJoinCompany">
                                            <p *invalidType="'required'">Bạn vui lòng chọn ngày vào công ty</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label class="col-sm-4 col-form-label">Địa chỉ</label>
                                <label *ngIf="!isSelfEditting"
                                    class="col-sm-8 col-form-label font-weight-bold">{{userInfo.address}}</label>
                                <div *ngIf="isSelfEditting" class="col-sm-8">
                                    <input type="text" class="form-control" name="address"
                                        [(ngModel)]="userInfo.address" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label class="col-sm-4 col-form-label">Tài khoản Skype</label>
                                <label *ngIf="!isSelfEditting"
                                    class="col-sm-8 col-form-label font-weight-bold">{{userInfo.skypeId}}</label>
                                <div *ngIf="isSelfEditting" class="col-sm-8">
                                    <input type="text" class="form-control" name="skypeId"
                                        [(ngModel)]="userInfo.skypeId" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label class="col-sm-4 col-form-label">Tài khoản LinkedIn</label>
                                <label *ngIf="!isSelfEditting"
                                    class="col-sm-8 col-form-label font-weight-bold">{{userInfo.linkedId}}</label>
                                <div *ngIf="isSelfEditting" class="col-sm-8">
                                    <input type="text" class="form-control" name="linkedId"
                                        [(ngModel)]="userInfo.linkedId" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label class="col-sm-4 col-form-label">Tài khoản Facebook</label>
                                <label *ngIf="!isSelfEditting"
                                    class="col-sm-8 col-form-label font-weight-bold">{{userInfo.facebookId}}</label>
                                <div *ngIf="isSelfEditting" class="col-sm-8">
                                    <input type="text" class="form-control" name="facebookId"
                                        [(ngModel)]="userInfo.facebookId" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div *ngIf="!isAdmin" class="col-md-6">
                            <div class="form-group row">
                                <label class="col-sm-4 col-form-label">Múi giờ</label>
                                <div class="col-sm-8">
                                    <app-dropdown-list class="app-text" name="timeZone" [data]="timeZones"
                                        [(ngModel)]="userInfo.timeZoneOption" required="true">
                                    </app-dropdown-list>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="!isAdmin" class="col-md-6">
                            <div class="form-group row">
                                <label class="col-sm-4 col-form-label">Ngày sinh</label>
                                <label *ngIf="!isSelfEditting"
                                    class="col-sm-8 col-form-label font-weight-bold">{{userInfo.birthday}}</label>
                                <div *ngIf="isSelfEditting" class="col-sm-8">
                                    <div class="input-group">
                                        <input type="text" name="birthday" placeholder="Ngày sinh"
                                            [(ngModel)]="userInfo.birthday" class="form-control" #dpr="bsDatepicker"
                                            [bsConfig]="bsConfig" required bsDatepicker>
                                        <div class="input-group-prepend bg-primary border-primary"
                                            (click)="dpr.toggle()" [attr.aria-expanded]="dpr.isOpen">
                                            <span class="input-group-text bg-primary border-primary">
                                                <i class="mdi mdi-calendar-text text-white"></i>
                                            </span>
                                        </div>
                                        <div invalidmessage="birthday">
                                            <p *invalidType="'required'">Bạn vui lòng chọn ngày sinh</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <nz-divider nzText="Thông tin tài khoản ngân hàng" nzOrientation="left"></nz-divider>
                    <div class="row bankName">
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label class="col-sm-4 col-form-label">Tên ngân hàng</label>
                                <label *ngIf="!isSelfEditting"
                                    class="col-sm-8 col-form-label font-weight-bold">{{userInfo.bankName}}</label>
                                <div *ngIf="isSelfEditting" class="col-sm-8">
                                    <app-text-box class="app-text" type="text" name="bankName"
                                        [(ngModel)]="userInfo.bankName">
                                    </app-text-box>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label class="col-sm-4 col-form-label">Tên chủ tài khoản</label>
                                <label *ngIf="!isSelfEditting"
                                    class="col-sm-8 col-form-label font-weight-bold">{{userInfo.bankBeneficiary}}</label>
                                <div *ngIf="isSelfEditting" class="col-sm-8">
                                    <app-text-box class="app-text" type="text" name="bankBeneficiary"
                                        [(ngModel)]="userInfo.bankBeneficiary">
                                    </app-text-box>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label class="col-sm-4 col-form-label">Tên chi nhánh</label>
                                <label *ngIf="!isSelfEditting"
                                    class="col-sm-8 col-form-label font-weight-bold">{{userInfo.bankBranch}}</label>
                                <div *ngIf="isSelfEditting" class="col-sm-8">
                                    <app-text-box class="app-text" type="text" name="bankBranch"
                                        [(ngModel)]="userInfo.bankBranch">
                                    </app-text-box>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label class="col-sm-4 col-form-label">Số TK ngân hàng</label>
                                <label *ngIf="!isSelfEditting"
                                    class="col-sm-8 col-form-label font-weight-bold">{{userInfo.bankAccount}}</label>
                                <div *ngIf="isSelfEditting" class="col-sm-8">
                                    <app-text-box class="app-text" type="text" name="bankAccount"
                                        [(ngModel)]="userInfo.bankAccount">
                                    </app-text-box>
                                </div>
                            </div>
                        </div>
                    </div>

                    <nz-divider nzText="Lương cơ bản & Hỗ trợ" nzOrientation="left"></nz-divider>
                    <div>
                        <div class="row" *ngIf="isAdmin">
                            <div class="col-md-6">
                                <div class="form-group row">
                                    <label class="col-sm-4 col-form-label">Chức vụ</label>
                                    <div class="col-sm-8">
                                        <app-dropdown-list class="app-text" [defaultValue]="defaultRole" name="status"
                                            required="true" [data]="roleModel" [(ngModel)]="userInfo.roleOption">
                                        </app-dropdown-list>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6" *ngIf="userInfo.roleOption?.value == roleFreelance && isAdmin">
                                <div class="form-group row">
                                    <label class="col-sm-4 col-form-label">Đơn vị tiền tệ lương cơ bản</label>
                                    <div class="col-sm-8">
                                        <app-dropdown-list class="app-text" [defaultValue]="defaultCurrencyBasicType" name="currencyBasicTypeOption"
                                            required="true" [data]="currencyBasicTypeModel" [(ngModel)]="userInfo.currencyBasicTypeOption">
                                            <div invalidmessage="reduceYourself">
                                                <p *invalidType="'required'">Vui lòng chọn đơn vị tiền tệ</p>
                                            </div>
                                        </app-dropdown-list>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group row">
                                    <label class="col-sm-4 col-form-label">Lương cơ bản {{userInfo.currencyBasicTypeOption?.value == this.currencyUSD ? "(USD)" : "(VND)"}}</label>
                                    <label *ngIf="!isAdmin" class="col-sm-8 col-form-label font-weight-bold">{{this.basicSalary | appNumber}}</label>
                                    <div class="col-sm-8" *ngIf="isAdmin">
                                        <input type="text" name="basicSalary" placeholder="0"
                                            [(ngModel)]="this.basicSalary" (blur)="formatPrice('basicSalary')"
                                            class="form-control">
                                        <div invalidmessage="basicSalary">
                                            <p *invalidType="'min'">Vui lòng nhập mức hợp lệ</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group row">
                                    <label class="col-sm-4 col-form-label">Tiền ăn trưa (VND)</label>
                                    <label *ngIf="!isAdmin" class="col-sm-8 col-form-label font-weight-bold">{{this.lunchMoney | appNumber}}</label>
                                    <div class="col-sm-8" *ngIf="isAdmin">
                                        <input type="text" name="lunchMoney" placeholder="0"
                                            [(ngModel)]="this.lunchMoney" (blur)="formatPrice('lunchMoney')"
                                            class="form-control">
                                        <div invalidmessage="lunchMoney">
                                            <p *invalidType="'min'">Vui lòng nhập mức hợp lệ</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group row">
                                    <label class="col-sm-4 col-form-label">Phí điện thoại (VND)</label>
                                    <label *ngIf="!isAdmin"
                                        class="col-sm-8 col-form-label font-weight-bold">{{this.telephoneFee |
                                        appNumber}}</label>
                                    <div class="col-sm-8" *ngIf="isAdmin">
                                        <input type="text" name="telephoneFee" placeholder="0"
                                            [(ngModel)]="this.telephoneFee" (blur)="formatPrice('telephoneFee')"
                                            class="form-control">
                                        <div invalidmessage="telephoneFee">
                                            <p *invalidType="'min'">Vui lòng nhập mức hợp lệ</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group row">
                                    <label class="col-sm-4 col-form-label">Phí xăng xe (VND)</label>
                                    <label *ngIf="!isAdmin"class="col-sm-8 col-form-label font-weight-bold">{{this.petrolMoney | appNumber}}</label>
                                    <div *ngIf="isAdmin" class="col-sm-8">
                                        <input type="text" name="petrolMoney" placeholder="0"
                                            [(ngModel)]="this.petrolMoney" (blur)="formatPrice('petrolMoney')"
                                            class="form-control">
                                        <div invalidmessage="petrolMoney">
                                            <p *invalidType="'min'">Vui lòng nhập mức hợp lệ</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group row">
                                    <label class="col-sm-4 col-form-label">Hỗ trợ tiền nhà (VND)</label>
                                    <label *ngIf="!isAdmin" class="col-sm-8 col-form-label font-weight-bold">{{this.housingSupport | appNumber}}</label>
                                    <div *ngIf="isAdmin" class="col-sm-8">
                                        <input type="text" name="housingSupport" placeholder="0"
                                            [(ngModel)]="this.housingSupport" (blur)="formatPrice('housingSupport')"
                                            class="form-control">
                                        <div invalidmessage="housingSupport">
                                            <p *invalidType="'min'">Vui lòng nhập mức hợp lệ</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group row">
                                    <label class="col-sm-4 col-form-label">Khấu trừ bản thân (VND)</label>
                                    <label *ngIf="!isAdmin" class="col-sm-8 col-form-label font-weight-bold">{{this.reduceYourself | appNumber}}</label>
                                    <div class="col-sm-8" *ngIf="isAdmin">
                                        <div class="input-group">
                                            <input type="text" name="reduceYourself" placeholder="0"
                                                [(ngModel)]="this.reduceYourself" (blur)="formatPrice('reduceYourself')"
                                                class="form-control">
                                            <div invalidmessage="reduceYourself">
                                                <p *invalidType="'min'">Vui lòng nhập mức hợp lệ</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <nz-divider nzText="Lương theo giờ" nzOrientation="left"></nz-divider>

                    <div class="row">
                        <div class="col-md-6" *ngIf="isAdmin" >
                            <div class="form-group row">
                                <label class="col-sm-4 col-form-label">Đơn vị tiền tệ</label>
                                <div class="col-sm-8">
                                    <app-dropdown-list class="app-text" [defaultValue]="defaultCurrencyType" name="currencyTypeOption"
                                        required="true" [data]="currencyTypeModel" [(ngModel)]="userInfo.currencyTypeOption">
                                        <div invalidmessage="reduceYourself">
                                            <p *invalidType="'required'">Vui lòng chọn đơn vị tiền tệ</p>
                                        </div>
                                    </app-dropdown-list>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label class="col-sm-4 col-form-label">Lương theo giờ {{userInfo.currencyTypeOption?.value == this.currencyUSD ? "(USD)" : "(VND)"}}</label>
                                <label *ngIf="!isAdmin" class="col-sm-8 col-form-label font-weight-bold">{{userInfo.hourSalary | appNumber}}</label>                                
                                <div class="col-sm-8" *ngIf="isAdmin">
                                    <input type="text" name="hourSalary" placeholder="0"
                                        [(ngModel)]="userInfo.hourSalary" class="form-control">
                                    <div invalidmessage="hourSalary">
                                        <p *invalidType="'min'">Vui lòng nhập mức hợp lệ</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <nz-divider nzText="Lương làm thêm theo khách hàng {{userInfo.currencyTypeOption?.value == this.currencyUSD ? '(USD)' : '(VND)'}}"
                        nzOrientation="left"></nz-divider>

                    <button *ngIf="isAdmin"  type="button" class="btn btn-success submit-btn waves-effect waves-light mr-2"
                        (click)="addRow()">
                        Thêm
                    </button>

                    <div class="listHourSalaryCustomer">
                        <nz-table #editRowTable nzBordered [nzData]="listOfData" nzTemplateMode [nzPageSize]="5">
                            <tbody>
                                <tr *ngFor="let data of listOfData" class="editable-row">
                                    <td class="col-sm-5">
                                        <div class="form-group row">
                                            <label class="col-sm-3 col-form-label">Khách hàng:</label>
                                            <div class="col-sm-8">
                                                <app-dropdown-list class="app-text"
                                                    name="customer{{data.id}}" required="true" [data]="customers" [disabled]="!isAdmin"
                                                    [(ngModel)]="data.customerModel">
                                                </app-dropdown-list>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="col-sm-4">
                                        <div class="form-group row">
                                            <div class="col-sm-8">
                                             <input type="text" name="hourSalaryCustomer{{data.id}}" placeholder="0" [disabled]="!isAdmin"
                                                    [(ngModel)]="data.hourSalaryCustomer"
                                                    class="form-control">
                                                <div invalidmessage="hourSalaryCustomer">
                                                    <p *invalidType="'min'">Vui lòng nhập mức hợp lệ</p>
                                                </div>
                                            </div>
                                            <label class="col-sm-2 col-form-label">{{userInfo.currencyTypeOption?.value == this.currencyUSD ? '(USD)' : '(VND)'}}</label>
                                        </div>
                                    </td>
                                    <td class="col-sm-2">
                                        <div class="form-group row">
                                            <label class="col-sm-7 col-form-label">Khấu trừ thuế</label>
                                            <div class="checkbox checkbox-custom checkbox-single col-sm-5 col-form-label">
                                                <input type="checkbox" [(ngModel)]="data.isIncludePIT" [disabled]="!isAdmin" name="isIncludePIT{{data.id}}" [ngModelOptions]="{isIncludePIT: true}">
                                                <label class=""></label>
                                            </div>
                                        </div>
                                    </td>
                                    <td *ngIf="isAdmin" class="col-sm-1">
                                        <div class="form-group row">
                                            <div *ngIf="isAdmin" class="col-sm-6">
                                                <a class="btn btn-icon waves-effect waves-light btn-danger"
                                                (click)="deleteRow(data.id)">Xóa</a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </nz-table>
                    </div>

                    <div class="form-group text-right m-b-0">
                        <button class="btn btn-custom submit-btn waves-effect waves-light mr-2">
                            Xác Nhận
                        </button>
                        <button *ngIf="isSelfEditting" class="btn btn-icon waves-effect waves-light btn-danger"
                            routerLink="/">
                            Hủy
                        </button>
                        <button *ngIf="!isSelfEditting" class="btn btn-icon waves-effect waves-light btn-danger"
                            routerLink="/users">
                            Hủy
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-12 grid-margin stretch-card">
        <div class="card-box">
            <form #form="ngForm" (submit)="editUserIdentity(form)" autocomplete="on">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title" *ngIf="isSelfEditting">CẬP NHẬT THÔNG TIN CCCD</h4>
                        <h4 class="card-title" *ngIf="!isSelfEditting">THÔNG TIN CCCD</h4>
                        <div class="row">
                            <div class="col-md-6">
                                <div
                                    class="form-group row d-flex justify-content-center align-items-center flex-column">
                                    <app-shared-image
                                        [classImg]="'d-flex justify-content-center align-items-end col-form-label-lg img-cover img-fluid'"
                                        [srcImg]="idFrontImage ? idFrontImage : userInfo.idFrontImage | image"
                                        [altImg]="'image'" height="250">
                                    </app-shared-image>
                                    <div class="col-sm-8">
                                        <div class="vertical-center">
                                            <input type="file" class="file-upload-default"
                                                (change)="onChangeIdFrontImage($event)" name="idFrontImageUpload" hidden
                                                #idFrontImageUpload>
                                            <div class="input-group" *ngIf="isSelfEditting">
                                                <input type="text" name="idFrontImageInfo" value="{{idFrontImageInfo}}"
                                                    class="form-control file-upload-info" disabled
                                                    placeholder="Tải lên ảnh mặt trước CCCD">
                                                <span class="input-group-append">
                                                    <button class="btn btn-info" type="button"
                                                        (click)="idFrontImageUpload.click()">
                                                        Tải lên
                                                    </button>
                                                    <button class="btn btn-danger" type="button"
                                                        (click)="deleteIdFrontImage()">
                                                        Xóa
                                                    </button>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div
                                    class="form-group row d-flex justify-content-center align-items-center flex-column">
                                    <app-shared-image
                                        [classImg]="'d-flex justify-content-center align-items-end col-form-label-lg img-cover img-fluid'"
                                        [srcImg]="idBackImage ? idBackImage : userInfo.idBackImage | image"
                                        [altImg]="'image'" height="250">
                                    </app-shared-image>
                                    <div class="col-sm-8">
                                        <div class="vertical-center">
                                            <input type="file" class="img-cover file-upload-default"
                                                (change)="onChangeIdBackImage($event)" hidden name="idBackImageUpload"
                                                #idBackImageUpload>
                                            <div class="input-group" *ngIf="isSelfEditting">
                                                <input type="text" name="idBackImageInfo" value="{{idBackImageInfo}}"
                                                    class="form-control file-upload-info img-cover" disabled
                                                    placeholder="Tải lên ảnh mặt sau CCCD">
                                                <span class="input-group-append">
                                                    <button class="btn btn-info" type="button"
                                                        (click)="idBackImageUpload.click()">
                                                        Tải Lên
                                                    </button>
                                                    <button class="btn btn-danger" type="button"
                                                        (click)="deleteIdBackImage()">
                                                        Xóa
                                                    </button>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group row">
                                    <label class="col-sm-4 col-form-label">Số CCCD</label>
                                    <div  class="col-sm-8">
                                        <input type="text" class="form-control" name="userIdentity"
                                            [(ngModel)]="userIdentity.userIdentity"  [disabled]="!isSelfEditting"/>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group row">
                                    <label class="col-sm-4 col-form-label">Ngày cấp CCCD</label>
                                    <div class="col-sm-8">
                                        <div class="input-group">
                                            <input type="text" name="idIssueDate" [disabled]="!isSelfEditting"
                                                placeholder="Ngày cấp CCCD" [(ngModel)]="userIdentity.idIssueDate"
                                                class="form-control" #dpr="bsDatepicker" [bsConfig]="bsConfig" required
                                                bsDatepicker>
                                            <div class="input-group-prepend bg-primary border-primary"
                                                (click)="isSelfEditting && dpr.toggle()"
                                                [attr.aria-expanded]="dpr.isOpen">
                                                <span class="input-group-text bg-primary border-primary">
                                                    <i class="mdi mdi-calendar-text text-white"></i>
                                                </span>
                                            </div>
                                            <div invalidmessage="idIssueDate">
                                                <p *invalidType="'required'">Bạn vui lòng chọn ngày cấp CCCD</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group row">
                                    <label class="col-sm-4 col-form-label">Nơi cấp CCCD</label>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control" name="address"
                                            [disabled]="!isSelfEditting" [(ngModel)]="userIdentity.idIssuePlace" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group text-right m-b-0" *ngIf="isSelfEditting">
                            <button class="btn btn-custom submit-btn waves-effect waves-light mr-2">
                                Xác Nhận
                            </button>
                            <button class="btn btn-icon waves-effect waves-light btn-danger" routerLink="/">
                                Hủy
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>