import { Component, OnInit, Input } from '@angular/core';
//
import { DEFAULT_IMAGE } from 'src/app/utils/constants';
//

@Component({
  selector: 'app-shared-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.css']
})
export class ImageComponent implements OnInit {
  @Input() srcImg: string = '';
  @Input() altImg: string = '';
  @Input() classImg: string = '';
  @Input() titleImg: string = '';
  @Input() height: number;
  @Input() width: number;
  //

  constructor() { }

  ngOnInit() {
  }

  setDefaultPic() {
    this.srcImg = DEFAULT_IMAGE;
  }

}
