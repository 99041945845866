import { Component, OnInit } from '@angular/core';
import { BsDatepickerConfig } from 'ngx-bootstrap';
import { BsDatepickerViewMode } from 'ngx-bootstrap/datepicker/public_api';
import { UserService } from 'src/app/services/user.service';
import { OverTimeModel } from 'src/app/models/overtime.model';
import { DatetimeUtils } from 'src/app/utils/dateutil';
import { DialogService } from 'src/app/services/dialog.service';
import { AuthService } from 'src/app/services/auth.service';
import { ChangeDateButtonEnum } from 'src/app/models/enums';
declare var $: any;
@Component({
  selector: 'overtime-list',
  templateUrl: './overtime-list.component.html',
  styleUrls: ['./overtime-list.component.css']
})
export class OvertimeListComponent implements OnInit {
  minMode: BsDatepickerViewMode = 'month';
  bsConfig: Partial<BsDatepickerConfig>;
  targetDate = new Date();
  stringDateTime: string;
  totalTime: number = 0;
  isAdmin: boolean;

  descriptionSearch: string = '';
  // sort row data table
  sortName: string | null = null;
  sortValue: string | null = null;
  listOTs: Array<OverTimeModel> = [];
  listOfDisplayOTs: Array<OverTimeModel> = [];

  constructor(private userService: UserService, private dialogService: DialogService) { }

  ngOnInit() {

    this.isAdmin = AuthService.isAdmin();
    this.bsConfig = Object.assign({}, {
      minMode: this.minMode,
      containerClass: 'theme-blue',
      dateInputFormat: 'MM/YYYY'
    });
    this.stringDateTime = DatetimeUtils.toShortDateTimeFormat((new Date().toDateString()));
    this.updateOTList(this.stringDateTime);
  }

  ngAfterViewInit() {
    let that = this;
    (function () {
      $(document).on('click', '#deleteOT', (e) => {
        that.deleteOT(e.currentTarget.dataset.itemId);
      });
    })();
  }

  updateOTList(dateTime: string) {
    this.userService.getOTList(0, dateTime)
      .subscribe(rs => {
        this.totalTime = 0;
        this.listOTs = rs;
        this.listOfDisplayOTs = [...this.listOTs];

        rs.forEach((value: OverTimeModel) => {
          this.totalTime += value.workTime;
        })
      });
  }

  updateMonth(action: string) {
    switch (action) {
      case ChangeDateButtonEnum.Previous:
        this.targetDate = DatetimeUtils.addMonths(this.targetDate, -1);
        break;
      case ChangeDateButtonEnum.Next:
        this.targetDate = DatetimeUtils.addMonths(this.targetDate, 1);
        break;
      default:
        this.targetDate = new Date();
        break;
    }
    this.updateOTList(DatetimeUtils.toShortDateTimeFormat((this.targetDate.toDateString())));
  }

  onValueChange(value: Date): void {
    this.targetDate = value;
    this.stringDateTime = DatetimeUtils.toShortDateTimeFormat(value.toDateString());
    this.updateOTList(this.stringDateTime);
  }

  deleteOT(id: number) {
    this.dialogService.confirm("Xóa", "Bạn có chắc muốn xóa giờ làm thêm này?", () => {
      this.userService.deleteOT(id)
        .subscribe(() => {
          this.updateOTList(this.stringDateTime);
        })
    });
  }

  sort(sort: { key: string; value: string }): void {
    this.sortName = sort.key;
    this.sortValue = sort.value;
    this.search();
  }

  search(): void {
    const filterFunc = (item: OverTimeModel) => true;
    const data = this.listOTs.filter(item => filterFunc(item));
    if (this.sortName && this.sortValue) {
      this.listOfDisplayOTs = data.sort((a, b) =>
        this.sortValue === 'ascend'
          ? a[this.sortName!] > b[this.sortName!]
            ? 1
            : -1
          : b[this.sortName!] > a[this.sortName!]
          ? 1
          : -1
      );
    } else {
      this.listOfDisplayOTs = data;
    }
  }

  // search by description
  searchByDescription() {
    if(this.descriptionSearch && this.descriptionSearch.length > 0) {
      this.listOfDisplayOTs = this.listOTs.filter(ot => ot.description.toLowerCase().includes(this.descriptionSearch.toLowerCase()))
    } else {
      this.listOfDisplayOTs = [...this.listOTs];
    }
  }
}
