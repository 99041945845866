<div class="account-pages">
  <!-- Begin page -->
  <div class="accountbg"
       [ngStyle]="{'background-image': 'url(' + background.backgroundImage + ')', 'background-repeat': 'no-repeat', 'background-size': 'cover', 'background-position': 'center'}">
  </div>

  <div class="wrapper-page account-page-full">

    <div class="card">
      <div class="card-block">

        <div class="account-box">

          <div class="card-box p-5">
            <h2 class="text-uppercase text-center pb-4">
              <a routerLink="/" class="text-success">
                <span>
                  <app-shared-image [srcImg]="'assets/images/logo.png'"
                              [altImg]="'image'"
                              [height]="72"
                              [width]="72">
                  </app-shared-image>
                </span>
              </a>
            </h2>

            <div class="text-center">

              <svg id="Layer_1" class="svg-computer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 424.2 424.2">
                <style>
                  .st0 {
                    fill: none;
                    stroke: #02c0ce;
                    stroke-width: 5;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                    stroke-miterlimit: 10;
                  }
                </style>
                <g id="Layer_2">
                  <path class="st0" d="M339.7 289h-323c-2.8 0-5-2.2-5-5V55.5c0-2.8 2.2-5 5-5h323c2.8 0 5 2.2 5 5V284c0 2.7-2.2 5-5 5z" />
                  <path class="st0" d="M26.1 64.9h304.6v189.6H26.1zM137.9 288.5l-3.2 33.5h92.6l-4.4-33M56.1 332.6h244.5l24.3 41.1H34.5zM340.7 373.7s-.6-29.8 35.9-30.2c36.5-.4 35.9 30.2 35.9 30.2h-71.8z" />
                  <path class="st0" d="M114.2 82.8v153.3h147V82.8zM261.2 91.1h-147" />
                  <path class="st0" d="M124.5 105.7h61.8v38.7h-61.8zM196.6 170.2H249v51.7h-52.4zM196.6 105.7H249M196.6 118.6H249M196.6 131.5H249M196.6 144.4H249M124.5 157.3H249M124.5 170.2h62.2M124.5 183.2h62.2M124.5 196.1h62.2M124.5 209h62.2M124.5 221.9h62.2" />
                </g>
              </svg>

              <h4 class="text-danger">Trang Web Hiện Tại Đang Bảo Trì</h4>
              <p class="text-muted">Chúng tôi sẽ hoạt động lại nhanh nhất có thể.</p>
              <p class="text-muted">Vui lòng liên hệ CCT Care để biết thêm thông tin chi tiết.</p>
              <p class="text-muted">Đường dây nóng:</p>
            </div>

          </div>
        </div>

      </div>
    </div>

    <div class="m-t-40 text-center">
      <p class="account-copyright">{{background.footerText}}</p>
    </div>

  </div>

</div>
