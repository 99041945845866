import { BaseService } from './common/base.service';
import { Injectable } from '@angular/core';
import { ErrorService } from './common/error.service';
import { GlobalService } from './global.service';
import { HttpClient } from '@angular/common/http';
import { ChangePasscode } from '../models/changepasscode.model';

@Injectable({
    providedIn: 'root'
})
export class ChangePasscodeService extends BaseService {
    constructor(protected http: HttpClient,
        protected errorHandler: ErrorService,
        protected globalService: GlobalService) {
        super(http, errorHandler, globalService);

    }
    changePasscode(data:ChangePasscode) {
        return this.post('/Home/ChangePasscode', data);
    }
}
