import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  ChangeDetectorRef
} from "@angular/core";
import { BsModalService } from "ngx-bootstrap";

// fullcalendar
import { OptionsInput, EventInput } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";

import { AuthService } from "src/app/services/auth.service";
import { DatetimeUtils } from "../../utils/dateutil";
import viLocale from "@fullcalendar/core/locales/vi";
import { DialogModel } from "src/app/models/dialog.model";
import { NgForm } from '@angular/forms';
import { MeetingApiModel, MeetingData, CreateMeetingModel, DeleteMeetingModel } from 'src/app/models/meeting.model';
import { MeetingService } from 'src/app/services/meeting.service';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { DialogService } from 'src/app/services/dialog.service';
import { UserNameModel } from 'src/app/models/overtime.model';
import { UserService } from 'src/app/services/user.service';
import { FORMAT_DATE_CONSTANT } from 'src/app/app.constants';

declare function initSelect2(): any;
declare var $: any;

@Component({
  selector: "app-meeting",
  templateUrl: "./meeting.component.html",
  styleUrls: ["./meeting.component.css"]
})
export class MeetingComponent implements OnInit {
  @ViewChild("modalCreateMeeting") modalCreateMeeting: TemplateRef<any>;
  @ViewChild('fullcalendar') fullcalendar: FullCalendarComponent;
  bodyHTML = document.getElementById("body");
  options: OptionsInput;
  eventsModel: EventInput[];
  modalShowData: MeetingData;
  config = {
    ignoreBackdropClick: true
  };
  currentEventId: string;
  dialogModel: DialogModel;
  currentEvent: any;
  isAdmin: boolean;
  isEventClick: boolean;
  meetings: MeetingApiModel[] = [];
  model = new CreateMeetingModel();
  isEdit = false;
  users: UserNameModel;
  allUsers: any;
  formatDateConstant = FORMAT_DATE_CONSTANT.short_date_format;
  constructor(
    private modal: BsModalService,
    private dialogService: DialogService,
    private userService: UserService,
    private meetingService: MeetingService,
    private cd: ChangeDetectorRef) { }

  ngOnInit() {
    this.isAdmin = AuthService.isAdmin();
    this.getMeetings()
    this.options = {
      locale: viLocale,
      eventLimit: 1,
      editable: false,
      aspectRatio: this.aspectRatio,
      header: {
        left: "prev,next today",
        center: "title",
        right: "addMeeting"
      },
      customButtons: {
        addMeeting: {
          text: 'Thêm lịch họp',
          icon: 'mdi mdi mdi-plus',
          click: () => this.addMeetingByButton()
        }
      },
      plugins: [dayGridPlugin, interactionPlugin, listPlugin]
    };
    this.getAllUsers();
  }
  
  addMeetingByButton(){
    console.log("thêm lịch họp");
    this.isEdit = false;
    this.modalShowData = {
      id: 0,
      content: "",
      timeStart: new Date().toString(),
      userids: null
    };
    this.users = this.allUsers;
    this.modal.show(this.modalCreateMeeting, this.config);

    setTimeout(() => {
      initSelect2();
      this.setDataForMultiSelect(this.modalShowData.userids);
    });
  }

  getAllUsers() {
    this.userService.getUsersMeeing().subscribe(data => {
      this.allUsers = data;
    })
  }

  eventRender(info: any) {
    const pros = info.event.extendedProps;
    let leader = pros.users.filter(p => p.userId == pros.leaderId.toString()) ? pros.users.filter(p => p.userId == pros.leaderId.toString())[0] as UserNameModel : new UserNameModel();
    info.el.innerHTML = `
    <image src="${leader.userImage ? leader.userImage : "./assets/images/no-image.jpg"}" title="${leader.userName} đã tạo cuộc họp này!!!"
     height="40" width="40" alt="image" class="rounded-circle"></image>
    `;
  }

  get aspectRatio(): number {
    const currentWindowSize = window.innerWidth;
    if (currentWindowSize > 768) return 2.4;
    if (currentWindowSize > 413) return 0.8;
    if (currentWindowSize > 374) return 0.69;
    else return 0.575;
  }

  closeModal(): void {
    this.modal._hideModal(this.modal.getModalsCount());
    this.bodyHTML.classList.remove("modal-open");
  }

  eventClick(model) {
    this.isEventClick = true;
    this.currentEventId = model.event.id;
    this.isEdit = true;
    this.modalShowData = {
      id: model.event.id,
      content: model.event.title,
      timeStart: model.event.start,
      userids: this.getSelectedUsersId(model.event.extendedProps.users)
    };
    this.users = this.allUsers;
    this.modal.show(this.modalCreateMeeting, this.config);

    setTimeout(() => {
      initSelect2();
      this.setDataForMultiSelect(this.modalShowData.userids);
    });

  }

  setDataForMultiSelect(data: any) {
    (setTimeout(() => {
      $("#selectUser").val(data).change();
    }));
  }

  getSelectedUsersId(users) {
    let selectedUsers = [];
    users.forEach((user) => {
      selectedUsers.push(user.userId.toString());
    });
    return selectedUsers.filter((n, i) => selectedUsers.indexOf(n) === i);
  }

  dateClick(model) {
    this.isEventClick = false;
    if (!this.isDayHasEvent(model.date)) {
      this.isEdit = false;
      this.modalShowData = {
        id: 0,
        content: "",
        timeStart: DatetimeUtils.toDateFormatMM_DD_YYYY_HH_MM_SS(model.date.toString()),
        userids: []
      };
      this.users = this.allUsers;

      this.modal.show(this.modalCreateMeeting, this.config);
      setTimeout(() => {
        initSelect2();

      });

    }
  }

  isDayHasEvent(date) {
    return this.meetings.find(
      meeting => DatetimeUtils.toShortDateStandard(meeting.timeStart + "") === date
    );
  }

  getMeetings(): void {
    this.meetingService.getMeetings().subscribe(meetings => {
      this.meetings = meetings;
      this.eventsModel = meetings.map<EventInput>((meeting) => {
        const dateTimeStart = DatetimeUtils.toShortDateTimeStandard(
          meeting.timeStart.toString()
        );
        const dayEvent: EventInput = {
          id: meeting.id,
          title: meeting.content,
          start: dateTimeStart,
          end: dateTimeStart,
          extendedProps: {
            leaderId: meeting.leaderId,
            users: meeting.users
          }
        };
        return dayEvent;
      });
    });
  }

  updateView() {
    this.fullcalendar.getApi().destroy();
    this.fullcalendar.getApi().render();
    setTimeout(() => {
      this.getMeetings();
      this.cd.detectChanges();
    });
  }

  generateModelData(id = 0){
    let selectedUser = $("#selectUser").val();

    this.model.id = id;
    this.model.content = this.modalShowData.content;
    this.model.timeStart = DatetimeUtils.toShortDateTimeFormat(this.modalShowData.timeStart);

    this.model.userids = selectedUser;
  }

  createMeeting(form: NgForm) {
    if (form.invalid) return;

    this.generateModelData();

    this.meetingService.createMeeting(this.model).subscribe(() => {
      this.dialogService.message("Thông báo", "Bạn đã tạo cuộc họp thành công!!!", () => {
        this.model = new CreateMeetingModel();
        this.closeModal();
      }, "Đóng");
      this.updateView();
    });
  }

  editMeeting(form: NgForm, id: number) {
    if (form.invalid) return;

    this.generateModelData(id);

    this.meetingService.editMeeting(this.model).subscribe(() => {
      this.dialogService.message("Thông báo", "Bạn đã chỉnh sửa cuộc họp thành công!!!", () => {
        this.model = new CreateMeetingModel();
        this.closeModal();
      }, "Đóng");
      this.updateView();
    });
  }

  deleteMeeting(id: number) {
    if (id <= 0) return;
    let deleteModel = new DeleteMeetingModel(id);
    this.meetingService.deleteMeeting(deleteModel).subscribe(() => {
      this.dialogService.message("Thông báo", "Bạn đã xóa cuộc họp thành công!!!", () => {
        this.closeModal();
      }, "Đóng");
    });
    this.updateView();
  }
}

