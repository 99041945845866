import { UserNameModel } from './overtime.model';

export class CreateMeetingModel {
    content: string;
    userids: number[];
    timeStart: string;
    id: number;
}

export class DeleteMeetingModel {
    constructor(meetingId) {
        this.meetingid = meetingId;
    }
    meetingid: number;
}

export class MeetingApiModel {
    id: number;
    timeStart: Date;
    content: string;
    leaderId: number;
    users : UserNameModel;
}

export interface MeetingData {
    id: number;
    content: string;
    timeStart: string;
    userids: number[];
}