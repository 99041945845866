import { DropdownModel } from './dropdown.model';
import { UserShortInfo } from './userinfo.model';

export class ProjectModel {
    constructor() {
    }
    id: number;
    projectName: string;
    statusTitle: DropdownModel;
    customerTitle: DropdownModel;
    status: number;
    customerId: number;
    description: string;
    member: string;
    uploadedFile: File;
    projectLogo: string;
    customerName: string;
}

export class ProjectDetailModel {
    constructor() {
        this.statusModel = new DropdownModel();
        this.owner = new UserShortInfo();
        this.userInfos = [];
    }
    id: number;
    projectName: string;
    status: number;
    statusModel: DropdownModel;
    customerModel: DropdownModel;
    description: string;
    projectLogo: string;
    owner: UserShortInfo;
    userInfos: UserShortInfo[];
    leaderId: number;
    isChecked: boolean;
    customerName: string;
    overtimeProjectRecords: OvertimeProjectRecord[]
    totalTimeWorkMonth: number;
    totalTimeWorkProject: number;
    totalSalaryMonth: number;
    totalSalaryProject: number;

}


export class GetProjectModel {
    constructor(userId = 0, projectId = 0, projectName = "") {
        this.userId = userId;
        this.projectId = projectId;
        this.projectName = projectName;
    }
    userId: number;
    projectId: number;
    projectName: string;
}

export class ProjectRoleModel {
    constructor(){
        this.leaderProjectIds = [];
    }
    leaderProjectIds: number[];
}


export class OvertimeProjectRecord {
    constructor(){
        this.overtimeItems = [];
    }

    userId: string;
    userName: string;
    userImage: string;
    totalTime: number;
    totalSalaryOT: number;
    overtimeItems: OvertimeProjectRecordItem[]
}


export class OvertimeProjectRecordItem {
    submitTime: string;
    timeStart: string;
    timeFinish: string;
    workTime: number;
    description: string;
    rateType: string;
}
