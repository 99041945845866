import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service'
import { HomepageModel } from 'src/app/models/homepage.model';
import { Router } from '@angular/router';
import { UserInfo } from 'src/app/models/userinfo.model';
import { CashAdvanceApiModel, SalaryModel } from 'src/app/models/salary.model';
import { DropdownModel } from 'src/app/models/dropdown.model';
import { UserIndexApiModel, UserIdModel } from 'src/app/models/user';
import { UserService } from 'src/app/services/user.service';
import { ProjectService } from 'src/app/services/project.service';
import { SalaryService } from 'src/app/services/salary.service';
import { HardcodeService } from 'src/app/services/hardcode.service';
import { DatetimeUtils } from 'src/app/utils/dateutil';
import { HARDCODE_CONST, DEFAULT_SETTINGS, FORMAT_DATE_CONSTANT } from 'src/app/app.constants';
import { GetSalaryModel } from 'src/app/models/getsalary.model';
import { CashAdvanceStatus } from 'src/app/models/enums';
import { LocalService } from 'src/app/services/common/local.service';
import { OverTimeModel } from 'src/app/models/overtime.model';
import { LeavingService } from 'src/app/services/leaving.service';
import { DayOffForView } from 'src/app/models/leaving';
import { GetProjectModel, ProjectDetailModel } from 'src/app/models/project.models';
declare var $: any;
@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {
  homepageModel = new HomepageModel();
  user = new UserInfo();
  projects: ProjectDetailModel[] = [];
  userIndex: UserIndexApiModel = new UserIndexApiModel();
  listCashAdvance: CashAdvanceApiModel[] = [];
  salary = new SalaryModel();
  hardCodeSalaryType: DropdownModel[] = [];
  isAdmin: boolean;
  userId: number;
  listRequestOff: DayOffForView[] = [];
  listOvertime: OverTimeModel[] = [];

  constructor(
    private router: Router,
    private userService: UserService,
    private projectService: ProjectService,
    private salaryService: SalaryService,
    private hardCodeService: HardcodeService,
    private leavingService: LeavingService) { }

  ngOnInit() {
    this.isAdmin = AuthService.isAdmin();
    if (this.isAdmin) this.router.navigate(['/admin-dashboard']);

    this.userId = LocalService.getUserId();

    this.getUsers();

    this.getUserIndex();

    this.getProjects();

    this.getCashAdvanceList(DatetimeUtils.toShortDateTimeFormat(new Date().toString()));

    this.getSalary();

    this.getOvertimeList();

    this.getListRequestOff();
  }

  getSalaryTypeHardCode() {
    this.hardCodeService.getHardcode(HARDCODE_CONST.salaryType).subscribe(data => {
      this.hardCodeSalaryType = data;
      if (!this.isAdmin) {
        this.initSalaryToolTip();
      }
    });
  }

  getSalary() {
    let getSalaryModel = new GetSalaryModel();
    getSalaryModel.time = DatetimeUtils.toShortDateTimeFormat(new Date().toString());
    getSalaryModel.userId = 0;
    this.salaryService.getSalary(getSalaryModel)
      .subscribe(data => {
        this.salary = data;
        this.getSalaryTypeHardCode();
      });
  };

  initSalaryToolTip() {
    if (this.hardCodeSalaryType.length !== 0) {
      $('#tooltip-salary').tooltipster({
        content: $(`
        <div class="row">
        <div class="col-xs-6 col-md-6">
          <p>Lương cơ bản : ${this.salary.basicSalary.toLocaleString()} VNĐ</p>
          <p>Lương theo giờ : ${this.salary.otSalary.toLocaleString()} VNĐ</p>
          <p>Khấu trừ bản thân : ${this.salary.reduceYourself.toLocaleString()} VNĐ</p>
          <p>Loại lương : <span class="badge badge-success font-13">${this.hardCodeSalaryType[this.salary.salaryType - 1].name}</span></p>
        </div>
        <div class="col-xs-6 col-md-6">
          <p>Phí điện thoại : ${this.salary.telephoneFee.toLocaleString()} VNĐ</p>
          <p>Phí xăng xe : ${this.salary.petrolMoney.toLocaleString()} VNĐ</p>
          <p>Tiền ăn trưa : ${this.salary.lunchMoney.toLocaleString()} VNĐ</p>
          <p>Hỗ trợ tiền nhà : ${this.salary.housingSupport.toLocaleString()} VNĐ</p>
        </div>
      </div>`),
        minWidth: 600,
        maxWidth: 600,
        position: 'right'
      });
    }
  }

  getUserIndex() {
    let userIdModel = new UserIdModel();
    userIdModel.isDeactive = false;
    userIdModel.userId = this.userId;
    this.userService.getUserIndex(userIdModel).subscribe(data => {
      this.userIndex = data;
    });
  }

  getProjects() {
    let prModel = new GetProjectModel(LocalService.getUserId());

    this.projectService.getProjects(prModel)
      .subscribe(projects => {
        this.projects = projects;
      });
  }

  getCashAdvanceList(dateTime: string) {
    this.salaryService.getCashAdvanceList(dateTime, CashAdvanceStatus.None).subscribe(data => {
      this.listCashAdvance = data;
    })
  }

  getUsers(): void {

    this.userService.getUserProfile(this.userId)
      .subscribe(apiUsers => {
        if (apiUsers) {
          this.user = apiUsers;
        }

        if (this.user.birthday) {
          this.user.birthday = DatetimeUtils.toShortDateFormat(this.user.birthday);
        }

        if (this.user.dateJoinCompany) {
          this.user.dateJoinCompany = DatetimeUtils.toShortDateFormat(this.user.dateJoinCompany);

        }

        if (this.user.idIssueDate) {
          this.user.idIssueDate = DatetimeUtils.toShortDateFormat(this.user.idIssueDate);
        }

        if (this.user.lastLogin) {
          this.user.lastLogin = DatetimeUtils.toDateFormatTwelveHours(this.user.lastLogin);
        }

        this.userService.GetTimeZones().subscribe((data: any[]) => {
          this.user.timeZoneName = data.find(p => p.id == (this.user.timeZone || DEFAULT_SETTINGS.time_zone)).name;
        })
      });
  }

  getListRequestOff() {
    this.leavingService.getLeavings().subscribe(leaving => {
      this.listRequestOff = leaving;
    });
  }

  getOvertimeList() {
    this.userService.getOTList(0, DatetimeUtils.toShortDateTimeFormat(new Date().toString()))
      .subscribe(apiOTList => {
        this.listOvertime = apiOTList;
      });
  }
}
