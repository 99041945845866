<div class="row">
  <div class="col-12 grid-margin">
    <div class="card">
      <div class="card-body">
        <div class="font-600 font-18 mb-2">
          Chi tiết theo tháng {{ targetDate | date: "MM/yyyy" }}:
          {{ totalTime }} giờ
        </div>

        <div class="d-flex justify-content-end mb-3">
          <span class="d-flex align-items-center mr-2">
              Tìm: &nbsp;
              <app-text-box type="text"
                            [placeholder]="'nội dung..'"
                            [(ngModel)]="descriptionSearch"
                            (ngModelChange)="searchByDescription()">
              </app-text-box>
          </span>

          <button
            type="button"
            class="btn btn-light"
            (click)="updateMonth('previous')"
          >
            <i class="mdi mdi-arrow-left-bold"></i>
          </button>
          <button
            type="button"
            class="btn btn-light mr-1"
            (click)="updateMonth('next')"
          >
            <i class="mdi mdi-arrow-right-bold"></i>
          </button>
          <button
            type="button"
            class="btn btn-custom mr-2"
            (click)="updateMonth('today')"
          >
            Hôm nay
          </button>
          <button
            routerLink="/submit-overtime"
            type="button"
            class="btn btn-custom btn-fw waves-effect float-right"
            title="Thêm giờ"
          >
            <i class="mdi mdi-plus"></i>
          </button>
        </div>

        <div class="table-data">
          <nz-table
            #filterTable
            nzShowPagination
            nzShowSizeChanger
            [nzData]="listOfDisplayOTs"
            nzPageSize="50"
          >
            <thead (nzSortChange)="sort($event)" nzSingleSort>
              <tr>
                <th nzShowSort nzSortKey="submitTime">
                  Ngày tạo
                </th>
                <th nzShowSort nzSortKey="timeStart">
                  Bắt đầu
                </th>
                <th nzShowSort nzSortKey="timeFinish">
                  Kết thúc
                </th>
                <th nzShowSort nzSortKey="workTime">
                  Tổng thời gian
                </th>
                <th nzShowSort nzSortKey="description">
                  Nội dung
                </th>
                <th nzShowSort nzSortKey="projectName">
                  Dự án
                </th>
                <th>
                  Xóa
                </th>
              </tr>
            </thead>

            <tbody>
              <tr *ngFor="let ot of filterTable.data">
                <td>
                  {{ ot.submitTime | date: "dd/MM/yyyy, h:mm a" }}
                </td>
                <td>
                  {{ ot.timeStart | date: "dd/MM/yyyy, h:mm a" }}
                </td>
                <td>
                  {{ ot.timeFinish | date: "dd/MM/yyyy, h:mm a" }}
                </td>
                <td>
                  {{ ot.workTime }}
                </td>
                <td style="white-space: pre-wrap;">
                  {{ ot.description }}
                </td>
                <td>
                  <div class="row">
                    <div *ngFor="let proj of ot.projects" style="padding: 5px;">
                      <div>
                        <app-shared-image [srcImg]="proj.projectAvatar | image"
                                    [classImg]="'img-cover rounded-circle img-thumbnail'"
                                    [altImg]="'image'"
                                    [titleImg]="proj.projectName"
                                    [height]="40"
                                    [width]="40">
                        </app-shared-image>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <button
                    id="deleteOT"
                    type="button"
                    title="Xóa Giờ Làm"
                    [attr.data-item-id]="ot.id"
                    class="btn btn-danger"
                  >
                    <i class="mdi mdi-delete-circle"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </nz-table>
        </div>
      </div>
    </div>
  </div>
</div>
