import { Injectable } from '@angular/core';
import { BaseService } from './common/base.service';
import { HttpClient } from '@angular/common/http';
import { ErrorService } from './common/error.service';
import { GlobalService } from './global.service';
import { Observable } from 'rxjs';
import { API_ENDPOINT } from '../app.constants';
import { GetRoomModel, EstimateModel, RoomModel } from '../models/pocker.models';

@Injectable({
  providedIn: 'root'
})
export class PockerService extends BaseService {

  constructor(protected http: HttpClient,
    protected errorHandler: ErrorService,
    protected globalService: GlobalService) {
    super(http, errorHandler, globalService);

  }

  getRooms() {
    return this.get(API_ENDPOINT.get_rooms);
  }

  getRoomsByProjectId(projectId: number) {
    return this.post(API_ENDPOINT.get_rooms_by_project_id, projectId);
  }

  createRoom(projectId: number): Observable<RoomModel> {
    return this.post(API_ENDPOINT.create_room, projectId);
  }

  estimate(roomId: number, hour: number) {
    return this.post(API_ENDPOINT.estimate, { roomId: roomId, hour: hour }, false);
  }

  deleteRoom(roomId) {
    return this.remove(API_ENDPOINT.delete_room, roomId);
  }

  getEstimates(roomId, loading) {
    return this.get(API_ENDPOINT.get_estimates, { roomId: roomId }, loading);
  }

  getRoomByRoomId(roomId: string): Observable<RoomModel> {
    return this.get(`${API_ENDPOINT.get_room_by_room_id}?roomId=${roomId}`);
  }

  getRoom(id) {
    return this.get(`${API_ENDPOINT.get_room_by_id}?id=${id}`);
  }

  deleteEstimates(roomId) {
    return this.remove(API_ENDPOINT.delete_estimates, roomId);
  }

  updateDescription(id, description) {
    return this.put(API_ENDPOINT.update_description, { id: id, description: description }, false);
  }

  exitRoom(roomId) {
    return this.post(API_ENDPOINT.exit_room, roomId);
  }

  getJoinedRoomsByProjectId(projectId: number) {
    return this.get(`${API_ENDPOINT.get_joined_rooms_by_projectId}?projectId=${projectId}`);
  }
}
