<div class="row mb-4">
    <div class="col-12 grid-margin">
        <div class="card">
            <div class="card-body">
                <div class="fc-toolbar fc-header-toolbar">
                    <div class="fc-left">
                        <div class="fc-button-group">
                            <button type="button" class="fc-prev-button fc-button fc-button-primary" aria-label="prev" (click)="updateMonth('previous')">
                                <span class="fc-icon fc-icon-chevron-left">
            
                                </span>
                            </button>
                            <button type="button" class="fc-next-button fc-button fc-button-primary" aria-label="next" (click)="updateMonth('next')">
                                <span class="fc-icon fc-icon-chevron-right"></span>
                            </button>
                        </div>
                        <button type="button" class="fc-today-button fc-button fc-button-primary" (click)="updateMonth('today')">Hôm nay</button>
                    </div>
                    <div class="fc-center">
                        <h2>Tháng {{ targetDate | date: "MM" }} năm {{ targetDate | date: "yyyy" }}</h2>
                    </div>
                    <div class="fc-right">
                        <button *ngIf="!isAdmin" routerLink="/add-daily-report" type="button" class="btn btn-custom btn-fw waves-effect" title="Tạo mới">
                            <i class="mdi mdi-plus"></i>
                            Tạo mới
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!isAdmin">
    <div class="row mb-4" *ngFor="let report of dailyReports">
        <div class="col-12 grid-margin">
            <div class="card">
                <div class="card-body">
                    <div class="report-header">
                        <p class="font-18">
                            {{report.created | date: "dd/MM/yyyy, hh:mm:ss a"}}
                            <span class="font-18 float-right">
                            Chỉnh sửa lúc: {{report.modified | date: "dd/MM/yyyy, hh:mm:ss a"}}
                            <button *ngIf="report.successfully < 31" type="button" class="btn btn-danger ml-2" disabled>{{report.successfully}}%</button>
                            <button *ngIf="report.successfully > 79"type="button" class="btn btn-success ml-2" disabled>{{report.successfully}}%</button>
                            <button *ngIf="report.successfully > 30 && report.successfully < 80" type="button" class="btn btn-warning ml-2" disabled>{{report.successfully}}%</button>
                        </span>
                        </p>
                        <p class="font-18">Dự án: {{report.projectName}}</p>
                    </div>
                    <div class="report-body straight-line">
                        <p class="font-18" [innerHTML]="report.content"></p>
                    </div>
                    <div *ngIf="isAllowEdit(report.created)" class="report-footer">
                        <button class="btn btn-custom waves-effect waves-light float-right mt-1" [routerLink]="['/edit-daily-report', report.id]">Cập nhật</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row" *ngIf="isAdmin">
    <div class="col-3 grid-margin">
        <div class="card">
            <div class="card-body">
                <div *ngFor="let employee of employees">
                    <button type="button" class="fc-prev-button fc-button fc-button-primary report-user-list mb-2" (click)="userClicked($event, employee.id)">
                        {{employee.fullName}}
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="col-9 ">
        <div class="row mb-4" *ngFor="let report of dailyReports">
            <div class="col-12 grid-margin">
                <div class="card">
                    <div class="card-body">
                        <div class="report-header">
                            <p class="font-18">
                                {{report.created | date: "dd/MM/yyyy, hh:mm:ss a"}}
                                <span class="font-18 float-right">
                            Chỉnh sửa lúc: {{report.modified | date: "dd/MM/yyyy, hh:mm:ss a"}}
                            <button *ngIf="report.successfully < 31" type="button" class="btn btn-danger ml-2" disabled>{{report.successfully}}%</button>
                            <button *ngIf="report.successfully > 79"type="button" class="btn btn-success ml-2" disabled>{{report.successfully}}%</button>
                            <button *ngIf="report.successfully > 30 && report.successfully < 80" type="button" class="btn btn-warning ml-2" disabled>{{report.successfully}}%</button>
                        </span>
                            </p>
                            <p class="font-18">Dự án: {{report.projectName}}</p>
                        </div>
                        <div class="report-body straight-line">
                            <p class="font-18" [innerHTML]="report.content"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>