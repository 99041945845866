import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
//
import { ImageComponent } from './components';
//

const BASE_MODULES = [
  CommonModule,
]

const COMPONENTS = [
  ImageComponent
]

@NgModule({
  declarations: [
    ...COMPONENTS,
  ],
  imports: [
    ...BASE_MODULES,
  ],
  exports: [
    ...BASE_MODULES,
    ...COMPONENTS
  ],
})
export class SharedModule { }
