<div class="row">
  <div class="col-12 grid-margin">
    <div class="card">
      <form #form="ngForm" autocomplete="on">
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group row  d-flex justify-content-center align-items-center">
                <div class="thumb-xxl member-thumb m-b-10">
                  <app-shared-image [srcImg]="model.projectLogo | image"
                                    [classImg]="'img-cover rounded-circle img-thumbnail no-border'"
                                    [altImg]="'image'">
                  </app-shared-image>
                </div>
              </div>


            </div>

            <div class="col-md-6">

              <div class="form-group row">
                <div class="col-sm-12">
                  <app-text-box class="app-text" title="Tên dự án" type="text" name="projectname"
                                [(ngModel)]="model.projectName" disabled >

                  </app-text-box>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-12">
                  <app-text-box class="app-text" title="Trạng thái dự án" type="text" name="projectStatus"
                                [(ngModel)]="model.statusModel.name" disabled>

                  </app-text-box>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-12">
                  <app-text-box class="app-text" title="Tên khách hàng" type="text" name="customerName"
                                [(ngModel)]="model.customerName" disabled>

                  </app-text-box>
                </div>
              </div>

            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="form-group row">
                <div class="col-sm-12">
                  <label for="descripton">Mô tả</label>
                  <textarea class="form-group col-md-12" name="jobDescription"
                            [(ngModel)]="model.description" rows="3" disabled>
                </textarea>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="model.userInfos" class="row">
            <div class="col-md-12">
              <label>Thành viên đang tham gia</label>
              <div class="form-group row ml-1">
                <div *ngFor="let user of model.userInfos" class="ml-2">
                  <a [routerLink]="['/user-profile', user.id]" data-toggle="tooltip" data-placement="top" title="{{user.fullName}}">
                    <app-shared-image *ngIf="user.isLeader"
                                      [srcImg]="user.avatar | image"
                                      [classImg]="'project-leader-mark rounded-circle thumb-md'"
                                      [altImg]="'image'">
                    </app-shared-image>
                    <app-shared-image *ngIf="!user.isLeader"
                                      [srcImg]="user.avatar | image"
                                      [classImg]="'rounded-circle thumb-md'"
                                      [altImg]="'image'">
                    </app-shared-image>
                  </a>
                </div>
              </div>
            </div>
          </div>

        </div>
      </form>
    </div>
  </div>
</div>
