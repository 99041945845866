import { BaseService } from './common/base.service';
import { Injectable } from '@angular/core';
import { ErrorService } from './common/error.service';
import { GlobalService } from './global.service';
import { HttpClient } from '@angular/common/http';
import { PasscodeForgotRequest, NewPasscodeSubmit } from '../models/forgotpasscode.model';
import { API_ENDPOINT } from '../app.constants';

@Injectable({
    providedIn: 'root'
})
export class ForgotPasscodeService extends BaseService {
    constructor(protected http: HttpClient,
        protected errorHandler: ErrorService,
        protected globalService: GlobalService) {
        super(http, errorHandler, globalService);

    }
    forgotPasscodeRequest(email: string) {
        let model = new PasscodeForgotRequest(email);
        return this.post(API_ENDPOINT.forgot_passcode_request, model, true);
    }

    newPasscodeSubmit(newPasscode: string, token: string) {
        let model = new NewPasscodeSubmit();
        model.passwordNew = newPasscode;
        model.token = token;
        return this.post(API_ENDPOINT.new_passcode_submit, model, true);
    }
}
