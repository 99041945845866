import { Component, OnInit } from '@angular/core';
import { DatetimeUtils } from '../../../utils/dateutil';
import { BsDatepickerViewMode } from 'ngx-bootstrap/datepicker/models';
import { ChangeDateButtonEnum } from 'src/app/models/enums';
import { ProjectService } from 'src/app/services/project.service';
import { ActivatedRoute } from '@angular/router';
import { OvertimeProjectRecord, ProjectDetailModel } from 'src/app/models/project.models';

declare var $: any;
@Component({
  selector: 'detail-project',
  templateUrl: './detail-project.component.html',
  styleUrls: ['./detail-project.component.css'],
})
export class DetailProjectComponent implements OnInit {
  expandSet = new Set<number>();
  minMode: BsDatepickerViewMode = 'month';
  dateTime: string;
  date = new Date();

  descriptionSearch: string = '';
  // sort row data table
  sortName: string | null = null;
  sortValue: string | null = null;
  projectDetail: ProjectDetailModel;
  listOTs: Array<OvertimeProjectRecord> = [];
  listOfDisplayOTs: Array<OvertimeProjectRecord> = [];
  projectId: number;

  constructor(
    private activatedRouter: ActivatedRoute,
    private projectService: ProjectService) {

  }

  ngOnInit() {
    const id = Number.parseInt(this.activatedRouter.snapshot.paramMap.get('id'));
    if (id) {
      this.projectId = id;
    }

    this.dateTime = DatetimeUtils.toShortDateTimeFormat((this.date.toDateString()));
    this.getProjectDetail(this.dateTime, this.projectId);
  }

  getProjectDetail(dateTime: string, projectId: number) {
    this.projectService.getProjectDetail(dateTime, projectId).subscribe(data => {
      this.projectDetail = data;
      this.listOTs = data.overtimeProjectRecords;
      this.listOfDisplayOTs = [...this.listOTs];
    },
      error => {
        this.listOTs = [];
        this.listOfDisplayOTs = [];
      });

  };

  updateMonth(action: string) {
    switch (action) {
      case ChangeDateButtonEnum.Previous:
        this.date = DatetimeUtils.addMonths(this.date, -1);
        break;
      case ChangeDateButtonEnum.Next:
        this.date = DatetimeUtils.addMonths(this.date, 1);
        break;
      default:
        this.date = new Date();
        break;
    }
    this.dateTime = DatetimeUtils.toShortDateTimeFormat(this.date.toDateString());
    this.getProjectDetail(this.dateTime, this.projectId);
  }

  sort(sort: { key: string; value: string }): void {
    this.sortName = sort.key;
    this.sortValue = sort.value;
  }

  // // search by description
  searchByDescription() {
    if (this.descriptionSearch && this.descriptionSearch.length > 0) {
      this.listOfDisplayOTs = this.listOTs.filter(ot => ot.userName.toLowerCase().includes(this.descriptionSearch.toLowerCase()))
    } else {
      this.listOfDisplayOTs = [...this.listOTs];
    }
  }

  onExpandChange(id: number, checked: boolean): void {
    if (checked) {
      this.expandSet.add(id);
    } else {
      this.expandSet.delete(id);
    }
  }
}
