<div class="row">
  <div class="col-12 grid-margin">
    <div class="card">
      <form #form="ngForm" (submit)="addCustomer(form)" autocomplete="on">
        <div class="card-body">
          <div class="row">
            <div class="col-md-6 col-xs-6 col-lg-6 col-sm-6">
              <div class="form-group row d-flex justify-content-center align-items-center">

                <div class="thumb-xxl member-thumb m-b-10">
                  <app-shared-image *ngIf="!avatar" [srcImg]="model.avatar | image" [classImg]="'img-cover rounded-circle img-thumbnail no-border'" [altImg]="'image'">
                  </app-shared-image>
                  <app-shared-image *ngIf="avatar" [srcImg]="avatar | image" [classImg]="'img-cover rounded-circle img-thumbnail no-border'" [altImg]="'image'">
                  </app-shared-image>
                </div>
              </div>
              <div class="form-group row d-flex justify-content-center align-items-center">
                <div class="vertical-center">
                  <input type="file" class="img-cover file-upload-default" (change)="onChangeLogo($event)" name="uploadedFile" #logoImageUpload hidden>
                  <div class="input-group">
                    <input type="text" name="logoImageName" value="{{logoImageName}}" class="form-control file-upload-info img-cover" disabled placeholder="Tải ảnh lên">
                    <span class="input-group-append">
                      <button class="btn btn-info" [disabled]="!isAdmin" type="button" (click)="logoImageUpload.click()">
                        Tải Lên
                      </button>
                    </span>
                  </div>
                </div>
              </div>

            </div>
            <div class="col-md-6 col-xs-6 col-lg-6 col-sm-6">
              <div class="form-group row">
                <div class="col-sm-12">
                  <app-text-box class="app-text" title="Tên khách hàng " type="text" [disabled]="!isAdmin" name="customerName" [(ngModel)]="model.customerName" required="true">
                    <div invalidmessage="customerName">
                      <p *invalidType="'required'">Bạn vui lòng nhập tên khách hàng!!!</p>
                    </div>
                  </app-text-box>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-12">
                  <label>Mô tả</label>
                  <textarea class="form-group col-md-12 textArea" [disabled]="!isAdmin" name="jobDescription" [(ngModel)]="model.description" rows="3">
                      </textarea>
                </div>
              </div>

            </div>
          </div>

          <div class="row" style="float:right; padding-right: 14px;">

            <div class="col-xs-12">

              <div class="btn-group" role="group" aria-label="add project button">

                <button class="btn btn-lg btn-icon waves-effect waves-light btn-danger mr-2" style="overflow:inherit!important" routerLink="/list-customer">
                  Hủy bỏ
                </button>

                <button class="btn btn-lg btn-custom submit-btn btn-block waves-effect waves-light">
                  {{action}}
                </button>

              </div>

            </div>

          </div>

        </div>
      </form>
    </div>
  </div>
</div>