<!-- ========== Left Sidebar Start ========== -->
<div class="left side-menu">

  <div class="slimscroll-menu" id="remove-scroll">

    <!-- LOGO -->
    <div class="topbar-left">
      <a class="logo">
        <span>
          <app-shared-image [srcImg]="settingConfig.logo | image" [altImg]="'image'" [height]="48">
          </app-shared-image>
        </span>
        <i>
          <app-shared-image [srcImg]="settingConfig.logo | image" [altImg]="'image'" [height]="48">
          </app-shared-image>
        </i>
      </a>
    </div>

    <!-- User box -->
    <div class="user-box" (click)="viewProfile()">
      <div class="user-img">
        <app-shared-image [srcImg]="user.avatar | image" [classImg]="'rounded-circle'" [altImg]="'user-img'"
          [titleImg]="'Mat Helme'" [height]="48" [width]="48">
        </app-shared-image>

      </div>
      <h5><a>{{user.fullName}}</a> </h5>
    </div>

    <!--- Sidemenu -->
    <div id="sidebar-menu">

      <ul class="metismenu" *ngIf="!isAdmin">

        <li class="menu-item" *ngFor="let item of menuUserData">
          <a routerLink={{item.routerLink}} [ngClass]="(currentMenuItem==item.menuItem)?'active':'deactive'">
            <i class={{item.icon}}></i>
            <span>
              {{item.title}}
            </span>
          </a>
        </li>

        <li class="menu-item">
          <a (click)="getLabourContract()">
            <i class="menu-icon mdi mdi-content-copy"></i>
            <span>
              Hồ sơ lao động
            </span>
          </a>
        </li>

      </ul>

      <ul class="metismenu" id="side-menu" *ngIf="isAdmin">

        <li class="menu-item" *ngFor="let item of menuAdminData">
          <a routerLink={{item.routerLink}} [ngClass]="(currentMenuItem==item.menuItem)?'active':'deactive'">
            <i class={{item.icon}}></i>
            <span>
              {{item.title}}
            </span>
          </a>
        </li>

      </ul>
    </div>
    <!-- Sidebar -->

    <div class="clearfix"></div>

  </div>
  <!-- Sidebar -left -->

</div>
<!-- Left Sidebar End -->
<!-- ============================================================== -->
<!-- Start right Content here -->
<!-- ============================================================== -->

<div class="content-page">

  <!-- Top Bar Start -->
  <div class="topbar">
    <nav class="navbar-custom">

      <ul class="list-unstyled topbar-right-menu float-right mb-0">

        <li class="dropdown notification-list">
          <a class="nav-link dropdown-toggle arrow-none" data-toggle="dropdown" href="#" role="button"
            aria-haspopup="false" aria-expanded="false">
            <i class="fi-bell noti-icon"></i>
            <span *ngIf="unreadMessageCount > 0"
              class="badge badge-danger badge-pill noti-icon-badge">{{unreadMessageCount}}</span>
          </a>
          <div class="dropdown-menu dropdown-menu-right dropdown-menu-animated dropdown-lg">

            <!-- item-->
            <div class="dropdown-item noti-title">
              <h5 class="m-0">
                <span class="float-right">
                  <a (click)="markAsreadAddNotify()" class="text-dark pointer"><small>Đọc tất cả</small></a>
                </span>Thông báo
              </h5>
            </div>

            <div class="slimscroll">
              <!-- item-->

              <div *ngFor="let notify of notifications">
                <div [ngStyle]="{ background: notify.isRead ? 'white' : '#EDEDF5' }">
                  <a class="dropdown-item notify-item" (click)="notifyOnClickURL(notify.id)">
                    <div [ngSwitch]="notify.alertGroups">
                      <div *ngSwitchCase="alertGroup.Message" class="notify-icon bg-info">
                        <i class="mdi mdi-message"></i>
                      </div>
                      <div *ngSwitchCase="alertGroup.Account" class="notify-icon bg-success">
                        <i class="mdi mdi-account-circle"></i>
                      </div>
                      <div *ngSwitchCase="alertGroup.Project" class="notify-icon bg-danger">
                        <i class="mdi mdi-professional-hexagon"></i>
                      </div>
                      <div *ngSwitchCase="alertGroup.DayOff" class="notify-icon bg-purple">
                        <i class="mdi mdi-calendar-remove"></i>
                      </div>
                      <div *ngSwitchCase="alertGroup.OverTime" class="notify-icon bg-warning">
                        <i class="mdi mdi-timetable"></i>
                      </div>
                      <div *ngSwitchCase="alertGroup.BirthDay" class="notify-icon bg-primary">
                        <i class="mdi mdi-cake"></i>
                      </div>
                      <div *ngSwitchCase="alertGroup.CashAdvance" class="notify-icon bg-secondary">
                        <i class="mdi mdi-cash-100"></i>
                      </div>
                      <div *ngSwitchDeFault><i class="mdi mdi-battery-unknown"></i></div>
                    </div>
                    <p class="notify-details" [innerHTML]="notify.message">
                      <small class="text-muted">{{notify.pushedTime | date: 'dd/MM/yyyy, hh:mm a'}}</small>
                    </p>
                  </a>
                </div>
              </div>
            </div>

            <!-- All-->
            <a href="javascript:void(0);" class="dropdown-item text-center text-primary notify-item notify-all"
              routerLink="/notify-list">
              Xem tất cả <i class="fi-arrow-right"></i>
            </a>

          </div>
        </li>

        <li class="dropdown notification-list">
          <a class="nav-link dropdown-toggle nav-user" data-toggle="dropdown" href="#" role="button"
            aria-haspopup="false" aria-expanded="false">
            <app-shared-image [srcImg]="user.avatar | image" [classImg]="'rounded-circle'" [altImg]="'user'">
            </app-shared-image>
            <span class="ml-1">{{user.fullName}}<i class="mdi mdi-chevron-down"></i> </span>
          </a>
          <div class="dropdown-menu dropdown-menu-right dropdown-menu-animated profile-dropdown">
            <!-- item-->
            <div class="dropdown-item noti-title">
              <h6 class="text-overflow m-0">Xin chào !</h6>
            </div>

            <!-- item-->
            <a href="javascript:void(0);" class="dropdown-item notify-item" routerLink='/user-profile'>
              <i class="fi-head"></i> <span>Thông tin tài khoản</span>
            </a>

            <!-- item-->
            <a href="javascript:void(0);" class="dropdown-item notify-item" routerLink="/change-password">
              <i class="fi-lock"></i> <span>Đổi mật khẩu</span>
            </a>

            <!-- item-->
            <a href="javascript:void(0);" class="dropdown-item notify-item" (click)="logOut()">
              <i class="fi-power"></i> <span>Đăng xuất</span>
            </a>

          </div>
        </li>

      </ul>

      <ul class="list-inline menu-left mb-0">
        <li class="float-left">
          <button class="button-menu-mobile open-left disable-btn">
            <i class="dripicons-menu"></i>
          </button>
        </li>
        <li>
          <div class="page-title-box">
            <h4 class="page-title">{{title}}</h4>
          </div>
        </li>

      </ul>

    </nav>

  </div>
  <!-- Top Bar End -->
  <!-- Start Page content -->
  <div class="content">
    <div class="container-fluid">
      <router-outlet>
      </router-outlet>
      <ng-template #customLoadingTemplate>
        <div class="custom-class">
        </div>
      </ng-template>
      <ngx-loading [show]="elementLoading" [template]="customLoadingTemplate">
      </ngx-loading>
    </div>
  </div> <!-- content -->

  <footer class="footer">
    {{settingConfig.footer}}
  </footer>
  <!-- ============================================================== -->
  <!-- End Right content here -->
  <!-- ============================================================== -->


</div>
<!-- END wrapper -->
