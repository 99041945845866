import { Component, OnInit } from '@angular/core';
import {DropdownModel} from 'src/app/models/dropdown.model';
import { UserService } from 'src/app/services/user.service';
import { HardcodeService } from 'src/app/services/hardcode.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationService } from 'src/app/services/notify.service';
import { UserShortInfo } from 'src/app/models/userinfo.model';
import { NgForm } from '@angular/forms';
import { ProjectService } from 'src/app/services/project.service';
import { ProjectModel, GetProjectModel } from '../../../models/project.models';
import { HARDCODE_CONST } from '../../../app.constants';
import { GlobalService } from 'src/app/services/global.service';
import { AuthService } from 'src/app/services/auth.service';
import { CustomerService } from 'src/app/services/customer.service';
import { GetCustomerModel } from 'src/app/models/customer.models';
import { dateToLocalArray } from '@fullcalendar/core/datelib/marker';

@Component({
  selector: 'project',
  templateUrl: './add-project.component.html',
  styleUrls: ['./add-project.component.css']
})
export class AddProjectComponent implements OnInit {
  model = new ProjectModel();
  status:  DropdownModel[];
  hardCodeUserList: DropdownModel[];
  customers: DropdownModel[];
  users: UserShortInfo[] = [];
  chosenUsers: UserShortInfo[] = [];
  projectUser: UserShortInfo[] = [];
  projectLogo: any;
  logoImageName: string;
  action: string;
  chooseLeaderTitle: string;
  usersSource: any;
  isAdmin: boolean;
  defaultValue: DropdownModel;
  constructor(
    private userService: UserService,
    private customerService: CustomerService,
    private hardcodeService: HardcodeService,
    private router: Router,
    private notifyService: NotificationService,
    private projectService: ProjectService,
    public route: ActivatedRoute,
    public globalService: GlobalService,
  ) {
  }

  ngOnInit() {
    this.isAdmin = AuthService.isAdmin();

    this.hardcodeService.getHardcode(HARDCODE_CONST.projectStatus)
      .subscribe(data => {
        this.status = data;
        this.defaultValue = this.status.find(p=>p.value === 1);
      });

      this.customerService.getCustomers(new GetCustomerModel())
      .subscribe(data => {
        this.customers = this.toDropdownModel(data);
        this.defaultValue = this.customers.find(p=>p.value === 1);
      });

    this.route.params.subscribe(params => {
      this.action = "Hoàn tất";

      if (!params['id']) {
        this.model.id = 0;
        this.hardCodeUserList = [];

        this.userService.getUsers()
          .subscribe(data => {
            this.users = data.filter(emp=>!emp.isDeactive && !emp.isStatusTakeOff);
            this.usersSource = data.filter(emp=>!emp.isDeactive && !emp.isStatusTakeOff);
          });
      }
      else {
        var id = +params['id'];
        let getModel = new GetProjectModel(0,id);
        this.projectService.getProjects(getModel)
          .subscribe(project => {
            this.model = project[0];
            this.projectUser = project[0].userInfos;
            this.model.statusTitle = this.status.find(p=>p.value === this.model.status);
            this.model.customerTitle = this.customers.find(p=>p.value === this.model.customerId);
            this.userService.getUsers(true)
              .subscribe(data => {
                data = data.filter(emp=>!emp.isDeactive && !emp.isStatusTakeOff);
                this.usersSource = data;
                data.forEach(user => {
                  this.projectUser.forEach(chosenUser => {
                    if (chosenUser.id == user.id) {
                      user.isChecked = true;
                      let leaderUser = Object.assign({}, user);
                      leaderUser.isChecked = chosenUser.isLeader;
                      this.chosenUsers.push(leaderUser);
                    }
                  });

                  this.users.push(user);
                  if (this.chosenUsers.length > 0)
                    this.chooseLeaderTitle = "Chọn người quản lý dự án";
                });
              });

          });
      }
    });
  }

  toDropdownModel(data :any) {
    let models = [];
    for (let index = 0; index < data.length; index++) {
      let model = new DropdownModel();
      model.name = data[index].customerName;
      model.value = data[index].id;

      models.push(model);
    }

    return models;
  }

  searchUser(term) {
    if (!term) {
      this.users = this.usersSource;
      return;
    }
    this.users = this.usersSource.filter(u => this.removeAccents(u.fullName.toLowerCase()).indexOf(this.removeAccents(term.toLowerCase())) !== -1);
  }

  removeAccents(value) {
    var result = value.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    return result;
  }

  onChangeLogo(event) {
    const filesUpload1: File = event.target.files[0];
    const reader1 = new FileReader();
    reader1.onload = () => this.projectLogo = reader1.result;
    reader1.readAsDataURL(filesUpload1);
    this.model.uploadedFile = filesUpload1;
    this.logoImageName = filesUpload1.name.toString();
  }

  userSelectedToggle(id: number) {
    this.users.forEach(user => {
      if (user.id == id) {
        user.isChecked = !user.isChecked;
        if (!user.isChecked) {
          let chosenUser = this.chosenUsers.find(p => p.id == user.id);
          this.chosenUsers.splice(this.chosenUsers.indexOf(chosenUser), 1);
        }
        else {
          let newChosenUser = Object.assign({}, user);
          newChosenUser.isChecked = false;
          this.chosenUsers.push(newChosenUser);
        }
      }
    });
    if (this.chosenUsers.length > 0)
      this.chooseLeaderTitle = "Chọn người quản lý dự án";
    else
      this.chooseLeaderTitle = "";
  }

  leaderUseroggle(id: number) {
    this.chosenUsers.forEach(user => {
      if (user.id == id) {
        user.isChecked = !user.isChecked;
      }
    });
  }

  addProject(form: NgForm) {
    if (form.invalid)
      return;
    let chosenUsers: any[] = [];
    this.users.forEach(user => {
      if (user.isChecked) {
        let chosenUser = this.chosenUsers.find(p => p.id == user.id);
        let obj: {} = {
          Id: user.id,
          IsLeader: chosenUser.isChecked
        };
        chosenUsers.push(obj);
      }
    });

    if (chosenUsers.length == 0) {
      this.notifyService.warning("Dự án chưa có nhân viên nào !!");
      return;
    }
    this.model.status = this.model.statusTitle.value;
    this.model.customerId = this.model.customerTitle.value;
    this.model.member = JSON.stringify(chosenUsers);
    if (this.model.id != 0) {
      this.projectService.editProject(this.model)
        .subscribe(() => {
          this.router.navigate(['/list-project']);
        });
    } else {
      this.projectService.addProject(this.model)
        .subscribe(() => {
          this.router.navigate(['/list-project']);
        });
    }

  }
}
