import { Component, OnInit } from '@angular/core';
import { DropdownModel } from 'src/app/models/dropdown.model';
import { UserService } from 'src/app/services/user.service';
import { HardcodeService } from 'src/app/services/hardcode.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationService } from 'src/app/services/notify.service';
import { UserShortInfo } from 'src/app/models/userinfo.model';
import { NgForm } from '@angular/forms';
import { DepartmentService } from 'src/app/services/department.service';
import { DepartmentModel, GetDepartmentModel } from '../../../models/department.models';
import { HARDCODE_CONST } from '../../../app.constants';
import { GlobalService } from 'src/app/services/global.service';
import { DepartmentRole } from '../../../models/enums';

@Component({
  selector: 'department',
  templateUrl: './add-department.component.html',
  styleUrls: ['./add-department.component.css']
})
export class AddDepartmentComponent implements OnInit {
  model = new DepartmentModel();
  status: DropdownModel[];
  hardCodeUserList: DropdownModel[];
  defaultValue: DropdownModel;
  users: UserShortInfo[] = [];
  chosenUsers: UserShortInfo[] = [];
  departmentUsers: UserShortInfo[] = [];
  departmentLogo: any;
  logoImageName: string;
  action: string;
  chooseManagerTitle: string;
  usersSource: any;
  uploadedFile: File;
  constructor(
    private userService: UserService,
    private hardcodeService: HardcodeService,
    private router: Router,
    private notifyService: NotificationService,
    private departmentService: DepartmentService,
    public route: ActivatedRoute,
    public globalService: GlobalService,
  ) {
  }

  ngOnInit() {
    this.hardcodeService.getHardcode(HARDCODE_CONST.departmentStatus)
      .subscribe(data => {
        this.status = data;
        this.defaultValue = this.status.find(p=>p.value === 1);
      });

    this.route.params.subscribe(params => {
      if (!params['id']) {
        this.model.id = 0;
        this.action = "Hoàn tất";
        this.hardCodeUserList = [];

        this.userService.getUsers()
          .subscribe(data => {
            data.forEach(user => {
              user.roleId = DepartmentRole.None;
            })
            this.users = data;
            this.usersSource = data;
          });
      }
      else {
        this.action = "Hoàn tất";
        var id = +params['id'];
        let getModel = new GetDepartmentModel(0, id);
        this.departmentService.getDepartments(getModel)
          .subscribe(department => {
            this.model = department[0];
            this.departmentUsers = department[0].usersInfo;
            this.model.statusModel = this.status.find(p=>p.value === this.model.status);
            this.userService.getUsers()
              .subscribe(data => {
                this.usersSource = data;
                data.forEach(user => {
                  user.roleId = DepartmentRole.None;
                  this.departmentUsers.forEach(chosenUser => {
                    if (chosenUser.id == user.id) {
                      user.roleId = chosenUser.roleId;
                      this.chosenUsers.push(chosenUser);
                    }
                  });

                  this.users.push(user);
                  if (this.chosenUsers.length > 0)
                    this.chooseManagerTitle = "Chọn Trưởng phòng";
                });
              });

          });
      }

    });

  }

  searchUser(term) {
    if (!term) {
      this.users = this.usersSource;
      return;
    }
    this.users = this.usersSource.filter(u => u.fullName.toLowerCase().indexOf(term.toLowerCase()) !== -1);
  }

  onChangeLogo(event) {
    const filesUpload1: File = event.target.files[0];
    const reader1 = new FileReader();
    reader1.onload = () => this.departmentLogo = reader1.result;
    reader1.readAsDataURL(filesUpload1);
    this.uploadedFile = filesUpload1;
    this.logoImageName = filesUpload1.name.toString();
  }

  userSelectedToggle(id: number) {
    this.users.forEach(user => {
      if (user.id == id) {
        if (user.roleId != DepartmentRole.None) {
          user.roleId = DepartmentRole.None;
          let chosenUser = this.chosenUsers.find(p => p.id == user.id);
          this.chosenUsers.splice(this.chosenUsers.indexOf(chosenUser), 1);
        }
        else {
          let newChosenUser = Object.assign({}, user);
          user.roleId = DepartmentRole.Member;
          newChosenUser.roleId = DepartmentRole.Member;
          this.chosenUsers.push(newChosenUser);
        }
      }
    });
    if (this.chosenUsers.length > 0)
      this.chooseManagerTitle = "Chọn Trưởng phòng";
    else
      this.chooseManagerTitle = "";
  }

  managerUserToggle(id: number) {
    this.chosenUsers.forEach(user => {
      if (user.id == id) {
        if (user.roleId != DepartmentRole.Manager)
          user.roleId = DepartmentRole.Manager
        else
          user.roleId = DepartmentRole.Member
      }
      else {
        if (user.roleId == DepartmentRole.Manager)
          user.roleId = DepartmentRole.Member
      }
    });
  }

  addDepartment(form: NgForm) {
    if (form.invalid)
      return;
    let chosenUsers: any[] = [];
    this.chosenUsers.forEach(user => {
      let obj: {} = {
        Id: user.id,
        RoleId: user.roleId
      };
      chosenUsers.push(obj);
    })

    if (chosenUsers.length == 0) {
      this.notifyService.warning("Phòng ban chưa có nhân viên nào !!");
      return;
    }

    this.model.usersInfo = chosenUsers;
    this.model.status = this.model.statusModel.value;
    this.departmentService.addOrEditDepartment(this.model)
      .subscribe((data) => {
        if (data && data.id && this.uploadedFile) {
          this.departmentService.updateDepartmentLogo(this.uploadedFile, data.id).subscribe(() => {
            this.router.navigate(['/list-department']);
          });
        } else {
          this.router.navigate(['/list-department']);
        }
      });

  }

}
