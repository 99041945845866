import { Component, OnInit } from '@angular/core';
import { GetSalaryModel } from 'src/app/models/getsalary.model';
import { SalaryModel, SalaryType, OverTimeInfos } from 'src/app/models/salary.model';
import { SalaryService } from 'src/app/services/salary.service';
import { UserService } from 'src/app/services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { UserShortInfo } from 'src/app/models/userinfo.model';
import { DatetimeUtils } from 'src/app/utils/dateutil';
import { ChangeDateButtonEnum } from 'src/app/models/enums';

@Component({
  selector: 'app-salary-detail',
  templateUrl: './salary-detail.component.html',
  styleUrls: ['./salary-detail.component.css']
})
export class SalaryDetailComponent implements OnInit {
  salaryType = SalaryType;
  model = new GetSalaryModel();
  salary = new SalaryModel();
  date = new Date();
  isAdmin;
  userId: number;
  rowspanOT: number
  constructor(private salaryService: SalaryService, private route: ActivatedRoute, private router: Router,
    private userService: UserService) {
    this.userId = 0;
  this.salary.userModel = new UserShortInfo();
  }

  ngOnInit() {
    this.isAdmin = AuthService.isAdmin();
    this.route.params.subscribe(params => {
      if (params['id'])
        this.userId = +params['id'];
      this.GetSalary();
    });
  }

  async GetSalary() {
    this.model.time = DatetimeUtils.toShortDateTimeFormat(this.date.toString());
    this.model.userId = this.userId;
    this.salaryService.getSalary(this.model)
      .subscribe(data => {
        if(data)
          this.salary = data;
          this.rowspanOT = this.salary.overTimeInfos?.length * 4 + 1;
          this.salary.totalRealSalary = this.salary?.realSalary + this.salary?.otSalaryExemptPIT;
      });
  };

  exportSalaryForCurrentUser(){
    let listId: number[] = [];
    listId.push(this.userId);
    this.userService.exportSalaryWithUserName(listId, this.salary.userModel.fullName, this.date);
  };

  lockSalary() {
    if(this.salary.isApprove || this.salary.isEmptySalary)
      return;
    let ids: number[]=[];
    ids.push(this.userId);
    this.salaryService.lockSalary(ids).subscribe(data => {
      this.router.navigate(['/users']);
    });
  }

  updateMonth(code: ChangeDateButtonEnum) {
    if(!this.salary.userModel)
      return;
    switch (code) {
      case  ChangeDateButtonEnum.Previous:
        this.date = DatetimeUtils.addMonths(this.date, -1);
        break;
      case ChangeDateButtonEnum.Next:
        if(DatetimeUtils.addMonths(this.date, 1) > new Date())
          return;
        this.date = DatetimeUtils.addMonths(this.date, 1);
        break;
      default:
        this.date = new Date();
        break;
    }
  this.GetSalary();
  }
}
