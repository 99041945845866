import { Component, OnInit, HostBinding, TemplateRef, ViewChild, ChangeDetectorRef, OnDestroy, AfterViewInit, EventEmitter } from '@angular/core';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
import { Title } from '@angular/platform-browser';
import { SettingService } from './services/setting.service';
import { Router } from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-root',
  animations: [
    trigger('emptyfull', [
      // ...
      state('empty', style({
        width: '0%'
      })),
      state('full', style({
        width: '100%'
      })),
      transition('empty => full', [
        animate('1s')
      ]),
      transition('full => empty', [
        animate('0s')
      ]),
    ]),
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {

  isFull = false;

  constructor(private titleService: Title, private settingService: SettingService,
    private router: Router) { }

  ngOnInit() {
    this.settingService.getDocumentTitle()
      .subscribe(data => {
        if (data) {
          this.updateFavIconAndTitle(data.favIcon, data.webTitle);
        }
      });
  }
  ngAfterViewInit() {
    let that = this;
    $(document).ready(function () {
      $(document).on('click', 'button', (e) => {
        e.stopPropagation();
        let link = e.currentTarget.dataset.itemLink;
        if (!link)
          return;
        that.router.navigateByUrl(link);
      });
    });
  }

  ngOnDestroy(): void {
  }

  navigationStarted() {

    if (!this.isFull) {

      this.isFull = true;

    }
    else {

      this.isFull = false;

      setTimeout(() => {
        this.isFull = true;
      }, 100);

    }
  }

  navigationEnded() {

  }

  navigationCanceled() {

  }

  navigationRecognized() {
  }

  updateFavIconAndTitle(favIcon: string, title: string) {
    this.titleService.setTitle(title);
    if (favIcon) {
      document.getElementById('favicon').setAttribute('href', favIcon);
    }
  }

  animationDone(event) {
    if (this.isFull)
      this.isFull = false;
  }
}
