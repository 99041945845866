import { Component, OnInit } from '@angular/core';
import { DatetimeUtils } from '../../../utils/dateutil';
import { UserService } from '../../../services/user.service';
import { OverTimeModel } from '../../../models/overtime.model';
import { DialogService } from 'src/app/services/dialog.service';
import { OverTimeInfomationModel } from 'src/app/models/overtime.model';
import { BsDatepickerViewMode } from 'ngx-bootstrap/datepicker/models';
import { ChangeDateButtonEnum } from 'src/app/models/enums';
declare var $: any;
@Component({
    selector: 'app-overtime-view',
    templateUrl: './overtime-view.component.html',
    styleUrls: ['./overtime-view.component.css'],
})
export class OvertimeViewComponent implements OnInit {
    overTimeInfo: OverTimeInfomationModel = new OverTimeInfomationModel();
    minMode: BsDatepickerViewMode = 'month';
    dateTime: string;
    date = new Date();

    descriptionSearch: string = '';
    // sort row data table
    sortName: string | null = null;
    sortValue: string | null = null;
    listOTs: Array<OverTimeModel> = [];
    listOfDisplayOTs: Array<OverTimeModel> = [];

    constructor(
        private userService: UserService, private dialogService: DialogService) { }

    ngOnInit() {
        this.getOvertimeInfo();
        this.dateTime = DatetimeUtils.toShortDateTimeFormat((this.date.toDateString()));
        this.getOvertimeList(this.dateTime);
    }
    ngAfterViewInit() {
        let that = this;
        (function () {
            $(document).on('click', '#btn-deleteOT', (obj) => {
                that.deleteOT(obj.currentTarget.dataset.itemId);
            });
        })();
    }
    deleteOT(id: number) {
        this.dialogService.confirm("Xóa", "Bạn có chắc muốn xóa giờ làm thêm này?", () => {
            this.userService.deleteOT(id)
                .subscribe(res => {
                    this.getOvertimeList(this.dateTime);
                })
        });
    }
    getOvertimeList(dateTime: string) {
      this.userService.getUserOverTimes(dateTime, 0).subscribe(data => {
          this.listOTs = data;
          this.listOfDisplayOTs = [...this.listOTs];
      },
      error => {
        this.listOTs = [];
        this.listOfDisplayOTs = [];
      });

    };
    getOvertimeInfo() {
        this.userService.getOvertimeInfo().subscribe(data => {
            this.overTimeInfo = data;
        },
            error => { this.overTimeInfo = new OverTimeInfomationModel() });

    };

    updateMonth(action: string) {
        switch (action) {
            case ChangeDateButtonEnum.Previous:
                this.date = DatetimeUtils.addMonths(this.date, -1);
                break;
            case ChangeDateButtonEnum.Next:
                this.date = DatetimeUtils.addMonths(this.date, 1);
                break;
            default:
                this.date = new Date();
                break;
        }
        this.dateTime = DatetimeUtils.toShortDateTimeFormat(this.date.toDateString());
        this.getOvertimeList(this.dateTime);
        this.getOvertimeInfo();
    }

    sort(sort: { key: string; value: string }): void {
      this.sortName = sort.key;
      this.sortValue = sort.value;
      this.search();
    }

    search(): void {
      const filterFunc = (item: OverTimeModel) => true;
      const data = this.listOTs.filter(item => filterFunc(item));
      if (this.sortName && this.sortValue) {
        this.listOfDisplayOTs = data.sort((a, b) =>
          this.sortValue === 'ascend'
            ? a[this.sortName!] > b[this.sortName!]
              ? 1
              : -1
            : b[this.sortName!] > a[this.sortName!]
            ? 1
            : -1
        );
      } else {
        this.listOfDisplayOTs = data;
      }
    }

    // search by description
    searchByDescription() {
      if(this.descriptionSearch && this.descriptionSearch.length > 0) {
        this.listOfDisplayOTs = this.listOTs.filter(ot => ot.description.toLowerCase().includes(this.descriptionSearch.toLowerCase()))
      } else {
        this.listOfDisplayOTs = [...this.listOTs];
      }
    }
}
