import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { NgForm } from '@angular/forms';
import { FormDialogModel } from 'src/app/models/dialog.model';

declare function formInputDialogInit(content: string, hint: string): any;
declare function saveFormInputValue(): string;

@Component({
  selector: 'app-confirm-dialog-input',
  templateUrl: './input-dialog.component.html',
  styleUrls: ['./input-dialog.component.css']
})
export class InputDialogComponent implements OnInit {

  model: FormDialogModel;
  content: string;
  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
    formInputDialogInit(this.model.initContent, this.model.hint);
  }

  submit() {
    this.content = saveFormInputValue();

    if (this.model.formSubmitCallback != null)
      this.model.formSubmitCallback.call(this, this.content);
  }

  dismiss() {
    this.bsModalRef.hide();
  }

  noBtnClicked() {

    this.bsModalRef.hide();
    if (this.model.cancelCallback != null)
      this.model.cancelCallback.call(this);

  }

}
