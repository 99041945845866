import { DropdownModel } from './dropdown.model';

export class CustomerModel {
    constructor() {
    }
    id: number;
    customerName: string;
    statusTitle: DropdownModel;
    status: number;
    description: string;
    uploadedFile: File;
    avatar: string;
}

export class CustomerDetailModel {
    constructor() {
        this.statusModel = new DropdownModel();
    }
    id: number;
    status: number;
    statusModel: DropdownModel;
    description: string;
    avatar: string;
    customerName: string;
}

export class GetCustomerModel {
    constructor(userId = 0, id = 0, customerName = "") {
        this.userId = userId;
        this.id = id;
        this.customerName = customerName;
    }
    userId: number;
    id: number;
    customerName: string;
}