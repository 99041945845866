import { DropdownModel } from './dropdown.model';
import { UserShortInfo } from './userinfo.model';

export class RoomModel {

  id: number;
  description: string;
  roomId: number;
  ownerId: number;
  projectId: number;
  isAccess: boolean;

  constructor(init?: Partial<RoomModel>) {
		Object.assign(this, init);
	}
}

export class GetRoomModel {
  constructor(userId = 0, roomId = 0) {
    this.userId = userId;
    this.roomId = roomId;
  }
  userId: number;
  roomId: number;
}

export class EstimateModel {
  constructor() {

  }
  id: number;
  roomId: number;
  workId: number;
  hour: number;
}
