import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CashAdvanceModel, MaxCashAdvanceApiModel, CashAdvanceApiModel } from '../../models/salary.model';
import { SalaryService } from '../../services/salary.service';
import { DialogService } from '../../services/dialog.service';
import { BsDatepickerConfig, BsModalService } from 'ngx-bootstrap';
import { BsDatepickerViewMode } from 'ngx-bootstrap/datepicker/public_api';
import { DatetimeUtils } from 'src/app/utils/dateutil';
import { CashAdvanceStatus } from 'src/app/models/enums';
declare function createDonutGraph(selector, labels, datas, colors): any;
declare var $: any;
@Component({
    selector: 'app-cash-advance',
    templateUrl: './cash-advance.component.html',
    styleUrls: ['./cash-advance.component.css']
})
export class CashAdvanceComponent implements OnInit {
    @ViewChild('modalContent') modalContent: TemplateRef<any>;
    bodyHTML = document.getElementById('body');
    model: CashAdvanceModel = new CashAdvanceModel();
    maxCash: MaxCashAdvanceApiModel = new MaxCashAdvanceApiModel();
    minMode: BsDatepickerViewMode = 'month';
    bsConfig: Partial<BsDatepickerConfig>;
    stringDateTime: string;
    date = new Date();
    totalCash: number = 0;
    cashAdvanceStatus: CashAdvanceStatus;
    isSalaryLocked: boolean;

    reasonSearch: string = '';
    // sort row data table
    sortName: string | null = null;
    sortValue: string | null = null;
    listCardAdvanceds: Array<CashAdvanceApiModel> = [];
    listOfDisplayAdvanceds: Array<CashAdvanceApiModel> = [];

    constructor(private salaryService: SalaryService,
        private dialogService: DialogService,
        private modal: BsModalService) { }

    ngOnInit() {
        this.bsConfig = Object.assign({}, {
            minMode: this.minMode,
            containerClass: 'theme-blue',
            dateInputFormat: 'MM/YYYY'
        });
        this.stringDateTime = DatetimeUtils.toShortDateTimeFormat((new Date().toDateString()));
        this.getMaxCashAdvance();
        this.getCashAdvanceList(this.stringDateTime);
    }

    ngAfterViewInit() {
        let that = this;
        (function () {
          $(document).on('click', '#cancelCashAdvance', (e) => {
            that.cancelRequest(e.currentTarget.dataset.itemId);
          });
        })();
      }

    initDonutChart() {
        let donutlabels = ["Số tiền đã ứng", "Số tiền có thể ứng", "Tổng số lương"];
        let donutdatas = [this.maxCash.cashAdvance, this.maxCash.maxValue, this.maxCash.salaryValue];
        let donutcolors = ['#000000', 'red', '#02c0ce'];
        createDonutGraph("#donut-chart #donut-chart-container", donutlabels, donutdatas, donutcolors);
    }

    showModal() {
        if (this.isSalaryLocked) {
            this.dialogService.message("Thông báo", "Bạn không thể ứng lương khi đã khóa sổ!!!", () => {
            }, "Đóng");
            return;
        }
        if (this.maxCash.maxValue < 1000000) {
            this.dialogService.message("Thông báo", "Bạn đã hết khả năng ứng lương!!!", () => {
            }, "Đóng");
            return;
        }
        else {
            this.modal.show(this.modalContent, {
                ignoreBackdropClick: true
            });
            let values = [];
            let value = this.maxCash.maxValue / 1000000;
            for (let index = 1; index < value; index++) {
                values.push((index * 1000000).toLocaleString());
            }
            $("#selectCash").ionRangeSlider({
                grid: false,
                from: 0,
                values: values
            });
        }
    }

    getCashAdvanceList(dateTime: string) {
        this.salaryService.getCashAdvanceList(dateTime, CashAdvanceStatus.None).subscribe(data => {
            this.listCardAdvanceds = data;
            this.listOfDisplayAdvanceds = [...this.listCardAdvanceds];

            this.totalCash = 0;
            data.forEach((value: CashAdvanceApiModel) => {
                if (value.isApprove) {
                    this.totalCash += value.cash;
                }
            })
        })
    }

    getMaxCashAdvance() {
        this.salaryService.getMaxCashAdvance().subscribe(data => {
            this.maxCash = data.data;
            if(!this.maxCash)
            {
                this.maxCash =  new MaxCashAdvanceApiModel();
                this.isSalaryLocked = true;
            }
            else
            {
                this.initDonutChart();
            }
        },
        error => {
          this.maxCash =  new MaxCashAdvanceApiModel();
          this.isSalaryLocked = true;
        });
    }

    cancelRequest(id: number) {
        this.dialogService.confirm("Xóa", "Bạn có chắc muốn hủy yêu cầu ứng tiền này?", () => {
            this.salaryService.cancelRequestCashAdvance(id)
                .subscribe(res => {
                    this.getCashAdvanceList(this.stringDateTime);
                })
        });
    }

    onValueChange(value: Date): void {
        this.stringDateTime = DatetimeUtils.toShortDateTimeFormat(value.toDateString());
        this.getCashAdvanceList(this.stringDateTime);
    }

    updateMonth(code: number) {
        switch (code) {
            case 1:
                this.date = DatetimeUtils.addMonths(this.date, -1);
                break;
            case 2:
                this.date = DatetimeUtils.addMonths(this.date, 1);
                break;
            default:
                this.date = new Date();
                break;
        }
        this.stringDateTime = DatetimeUtils.toShortDateTimeFormat(this.date.toString());
        this.getCashAdvanceList(this.stringDateTime);
    }

    submitCashAdvance(form: NgForm) {
        if (form.invalid) {
            return;
        }

        let selectedCash = $("#selectCash").val();
        selectedCash = selectedCash.split(',').join("");
        this.model.cashAdvance = +selectedCash;

        this.salaryService.submitCashAdvance(this.model).subscribe(data => {
            this.dialogService.message("Thông báo", "Bạn đã gửi yêu cầu ứng tiền thành công!!!", () => {
                this.model = new CashAdvanceModel();
                this.closeModal();
                this.getCashAdvanceList(this.stringDateTime);
            }, "Đóng");
        });
    }

    closeModal(): void {
        this.bodyHTML.classList.remove('modal-open');
        this.modal._hideModal(this.modal.getModalsCount());
    }

    sort(sort: { key: string; value: string }): void {
      this.sortName = sort.key;
      this.sortValue = sort.value;
      this.search();
    }

    search(): void {
      const filterFunc = (item: CashAdvanceApiModel) => true;
      const data = this.listCardAdvanceds.filter(item => filterFunc(item));
      if (this.sortName && this.sortValue) {
        this.listOfDisplayAdvanceds = data.sort((a, b) =>
          this.sortValue === 'ascend'
            ? a[this.sortName!] > b[this.sortName!]
              ? 1
              : -1
            : b[this.sortName!] > a[this.sortName!]
            ? 1
            : -1
        );
      } else {
        this.listOfDisplayAdvanceds = data;
      }
    }

    // search by description
    searchByReason() {
      if(this.reasonSearch && this.reasonSearch.length > 0) {
        this.listOfDisplayAdvanceds = this.listCardAdvanceds.filter(ca => ca.reason.toLowerCase().includes(this.reasonSearch.toLowerCase()))
      } else {
        this.listOfDisplayAdvanceds = [...this.listCardAdvanceds];
      }
    }
}
