<div class="row">
  <div class="col-12 grid-margin">
    <div class="card">
      <form #form="ngForm" autocomplete="on">
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group row  d-flex justify-content-center align-items-center">
                <div class="thumb-xxl member-thumb m-b-10">
                  <app-shared-image [srcImg]="model.departmentLogo | image"
                                    [classImg]="'img-cover rounded-circle img-thumbnail no-border'"
                                    [altImg]="'image'">
                  </app-shared-image>
                </div>
              </div>


            </div>

            <div class="col-md-6">

              <div class="form-group row">
                <div class="col-sm-12">
                  <app-text-box class="app-text" title="Tên phòng ban" type="text" name="departmentName"
                                [(ngModel)]="model.departmentName" disabled>

                  </app-text-box>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-12">
                  <app-text-box class="app-text" title="Trạng thái phòng ban" type="text" name="departmentStatus"
                                [(ngModel)]="model.statusModel.name" disabled>

                  </app-text-box>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <div class="form-group row">
                    <div class="col-sm-12">
                      <label for="descripton">Mô tả</label>
                      <textarea class="form-group col-md-12" name="departmentDescription"
                                [(ngModel)]="model.description" rows="3" disabled>
                    </textarea>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div *ngIf="model.usersInfo" class="row">
            <div class="col-md-12">
              <label>Thành viên đang tham gia</label>
              <div class="form-group row ml-1">
                <div *ngFor="let user of model.usersInfo" class="ml-2">
                  <a [routerLink]="['/user-profile', user.id]" data-toggle="tooltip" data-placement="top" title="{{user.fullName}}">
                    <app-shared-image *ngIf="user.roleId == 1"
                                      [srcImg]="user.avatar | image"
                                      [classImg]="'project-leader-mark rounded-circle thumb-md'"
                                      [altImg]="'image'">
                    </app-shared-image>
                    <app-shared-image *ngIf="user.roleId == 3"
                                      [srcImg]="user.avatar | image"
                                      [classImg]="'rounded-circle thumb-md'"
                                      [altImg]="'image'">
                    </app-shared-image>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="table-wrapper">
            <nz-table #rowSelectionTable nzShowPagination nzShowSizeChanger [nzData]="model.usersInfo"
                      nzPageSize="50">
              <thead>
                <tr>
                  <th>
                    Họ và tên
                  </th>
                  <th>
                    Chức vụ
                  </th>
                </tr>
              </thead>
              <tbody *ngIf="model.usersInfo">
                <tr *ngFor="let user of model.usersInfo" (click)="goToProfile($event, user.id)">
                  <td class="tr-view-profile">
                    <app-shared-image [srcImg]="user.avatar | image"
                                      [classImg]="'rounded-circle img-cover'"
                                      [altImg]="'image'"
                                      [height]="48"
                                      [width]="48">
                    </app-shared-image>
                    <label class="ml-2">{{user.fullName}}</label>
                  </td>
                  <td class="tr-view-profile">
                    <p *ngIf="user.roleId == 1">Trưởng phòng</p>
                    <p *ngIf="user.roleId == 2">Phó phòng</p>
                    <p *ngIf="user.roleId == 3">Nhân viên</p>
                  </td>
                </tr>
              </tbody>
            </nz-table>
          </div>

        </div>
      </form>
    </div>
  </div>
</div>
