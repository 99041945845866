<label class="label" *ngIf="title">{{title}}<span *ngIf="required" class="required">*</span></label>
<nz-select
  [(ngModel)]="value"
  [disabled]="disabled"
  (ngModelChange)="updateChanges()"
  nzShowSearch="false"
  nzServerSearch="false"
  nzShowArrow="true"
  nzSize="large"
>
  <nz-option *ngFor="let p of dataValue" [nzValue]="p" [nzLabel]="p.name"></nz-option>
</nz-select>
