<div *ngIf="isAdmin" class="row">
    <div class="col-sm-4">
        <button type="button" class="btn btn-custom btn-rounded w-md waves-effect waves-light mb-4"
            routerLink="/add-customer">
            <i class="mdi mdi-plus-circle"></i> Thêm khách hàng
        </button>
    </div>
</div>
<div *ngIf="this.customers" class="row">
    <div class="col-md-6 col-lg-4" *ngFor="let customer of this.customers">

        <div class="card-box customer-box ribbon-box">

            <div *ngIf="customer.status == 1" class="ribbon-two ribbon-two-custom">
                <span>{{customer.statusModel.name}}</span></div>
            <div *ngIf="customer.status == 2" class="ribbon-two ribbon-two-warning">
                <span>{{customer.statusModel.name}}</span></div>
            <div *ngIf="customer.status == 3" class="ribbon-two ribbon-two-danger">
                <span>{{customer.statusModel.name}}</span></div>
            <div *ngIf="customer.status == 4" class="ribbon-two ribbon-two-dark">
                <span>{{customer.statusModel.name}}</span></div>

            <div *ngIf="isAllowModify(customer.id)" class="dropdown float-right">

                <a class="dropdown-toggle card-drop arrow-none" data-toggle="dropdown" aria-expanded="false">
                    <h3 class="m-0 text-muted"><i class="mdi mdi-dots-horizontal"></i></h3>
                </a>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="btnGroupDrop1">
                    <a class="dropdown-item" [routerLink]="['/edit-customer', customer.id]"><span
                            class="mdi mdi-table-edit"></span> Chỉnh sửa</a>
                    <a *ngIf="isAdmin" class="dropdown-item" (click)="deleteCustomer(customer.id)"><span
                            class="mdi mdi-delete-circle"></span> Xóa</a>
                </div>

            </div>

            <p class="project-hide-overflow ml-3">

                <span class="thumb-lg member-thumb mr-15 m-b-10 d-inline-block">
                    <app-shared-image [srcImg]="customer.avatar | image"
                        [classImg]="'rounded-circle img-thumbnail'" [altImg]="'friend'"
                        [titleImg]="customer.projectName">
                    </app-shared-image>
                </span>

                <span class="projectNameDisplay mt-4">

                    <span class="text-dark font-15 text-bold"><b> {{customer.projectName}} </b>
                    </span>
                    <span class="text-dark font-13 text-uppercase"><b>{{customer.customerName}} </b>
                    </span>

                </span>

            </p>

            <p class="text-dark font-13 description-hide-overflow" title="{{customer.description}}">
                {{customer.description}}
            </p>
        </div>
    </div>
</div>

<div *ngIf="isAdmin" class="text-right mb-3">
    <nz-pagination [nzPageIndex]="pageIndex" [nzTotal]="totalCustomer" [nzPageSize]="limit"
        (nzPageIndexChange)="onPageIndexChange($event)" (nzPageSizeChange)="onPageSizeChange($event)"
        nzShowSizeChanger></nz-pagination>
</div>

<div *ngIf="this.customers.length == 0" class="row">
    <p class="text-muted font-18">Hiện tại không có khách hàng nào !!!</p>
</div>