import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { OverTimeModel } from 'src/app/models/overtime.model';
import { UserService } from '../../../services/user.service';
import { BsDatepickerConfig, BsDatepickerViewMode } from 'ngx-bootstrap/datepicker';
import { Router } from '@angular/router';
import { ProjectService } from 'src/app/services/project.service';
import { DatetimeUtils } from 'src/app/utils/dateutil';
import { ProjectDetailModel, GetProjectModel } from '../../../models/project.models';
import { LocalService } from 'src/app/services/common/local.service';
import * as moment from 'moment';
import { NotificationService } from 'src/app/services/notify.service';
import { FORMAT_DATE_CONSTANT, MOMENT_FORMAT } from 'src/app/app.constants';

@Component({
  selector: 'submit-overtime',
  templateUrl: './submit-overtime.component.html',
  styleUrls: ['./submit-overtime.component.css']
})
export class SubmitOvertimeComponent implements OnInit {
  startTime = new Date();
  endTime = new Date();
  dateTime = new Date();
  model = new OverTimeModel();
  startDay = new Date();
  finishDay = new Date();
  projects: ProjectDetailModel[] = [];
  selectedProjIds: number[] = [];
  formatDateConstant = FORMAT_DATE_CONSTANT.short_date_format;
  formatTimeConstant = MOMENT_FORMAT.client_time;
  constructor(private projectService: ProjectService,
    private userService: UserService,
    private router: Router,
    private notification: NotificationService) {

  }


  ngOnInit() {
    this.getProjects();
  }

  projSelectedToggle(id: number) {
    this.projects.forEach(proj => {
      if (proj.id == id) {
        proj.isChecked = !proj.isChecked;
        if (!proj.isChecked) {
          let chosenProj = this.selectedProjIds.find(p => p == proj.id);
          this.selectedProjIds.splice(this.selectedProjIds.indexOf(chosenProj), 1);
        }
        else {
          this.selectedProjIds.push(id);
        }
      } 
      else {
        proj.isChecked = false;
        let removeProj = this.selectedProjIds.find(p => p == proj.id);
        removeProj && this.selectedProjIds.splice(this.selectedProjIds.indexOf(removeProj), 1);
      }
    })
  }

  getProjects() {
    let getProjectModel = new GetProjectModel(LocalService.getUserId());
    this.projectService.getProjects(getProjectModel)
      .subscribe(projects => {
        this.projects = projects;
      });
  }

  submit(form: NgForm) {
    console.log(this.startTime);
    console.log(this.endTime);
    if (form.invalid) {
      return;
    }

    if (!this.selectedProjIds || this.selectedProjIds.length <= 0) {
      this.notification.error("Vui lòng chọn dự án");
      return;
    }

    this.model.projectIds = JSON.stringify(this.selectedProjIds);

    this.model.timeStart = DatetimeUtils.convertServerDateTimeString(this.startDay, this.startTime);
    this.model.timeFinish = DatetimeUtils.convertServerDateTimeString(this.finishDay, this.endTime);

    this.userService.submitOtLog(this.model)
      .subscribe(() => {
        this.router.navigate(['/overtime-list'])
      });
  }

}

