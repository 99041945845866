import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { LocalService } from 'src/app/services/common/local.service';
import { PockerService } from 'src/app/services/pocker.service';
import { Router, ActivatedRoute } from '@angular/router';
import { APP_ROUTER } from 'src/app/app.constants';

@Component({
  selector: 'app-pocker',
  templateUrl: './pocker.component.html',
  styleUrls: ['./pocker.component.css']
})
export class PockerComponent implements OnInit {
  title: string;
  content: string;
  rooms = [];
  roomId: string;
  projectId: number = 0;
  userId: number;
  joinedRoomIds: number[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NzModalService,
    private pockerService: PockerService) { }

  ngOnInit() {
    this.userId = LocalService.getUserId();

    this.route.params.subscribe(params => {
      this.projectId = +params['id'];
    });

    this.getListRoom();
    this.getJoinedRooms();
  }

  getListRoom(): void {
    this.pockerService.getRoomsByProjectId(this.projectId)
      .subscribe(data => {
        this.rooms = data;
      })
  }

  createRoom(): void {
    this.pockerService.createRoom(this.projectId).subscribe(data => {
      this.router.navigate([APP_ROUTER.room, data.id]);
    });
  }

  joinRoom(event, roomId): void {
    event.stopImmediatePropagation();

    this.pockerService.getRoomByRoomId(roomId).subscribe(data => {
      if (data) {
        if (data.isAccess) {
          this.router.navigate([APP_ROUTER.room, data.id]);
        }
        else {
          this.modalService.error({
            nzTitle: "Cảnh Báo",
            nzContent: "Bạn không có quyền truy cập vào phòng này"
          });
        }
      } else {
        this.modalService.error({
          nzTitle: "Cảnh Báo",
          nzContent: "Mã phòng không tồn tại hoặc đã bị xóa"
        });
      }
    });
  }

  goToRoom(id) {
    if (this.joinedRoomIds.includes(id)) {
      this.router.navigate([APP_ROUTER.room, id]);
    }
  }

  deleteRoom(event, id): void {
    event.stopImmediatePropagation();
    this.modalService.confirm({
      nzTitle: 'Bạn có muốn xóa room này không?',
      nzContent: '',
      nzOkText: 'Đồng ý',
      nzOkType: 'default',
      nzOkDanger: true,
      nzOnOk: () => this.pockerService.deleteRoom(id).subscribe(() => {
        this.getListRoom();
      }),
      nzCancelText: 'Không',
    });
  }

  getJoinedRooms() {
    this.pockerService.getJoinedRoomsByProjectId(this.projectId).subscribe(data => {
      if (data) {
        this.joinedRoomIds = data.map(x => x.roomId)
      }
    });
  }
}
