import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { LeavingService } from '../../services/leaving.service';
import { AuthService } from 'src/app/services/auth.service';
import { HardcodeService } from 'src/app/services/hardcode.service';
import { BsModalService } from 'ngx-bootstrap';

// fullcalendar
import { OptionsInput, EventInput } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import viLocale from '@fullcalendar/core/locales/vi';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { DatetimeUtils } from '../../utils/dateutil';
import { HARDCODE_CONST } from 'src/app/app.constants';
import { DateClickApi } from '@fullcalendar/core/Calendar';
import { RateOvertimeModel, RateOvertimeModels, RateOvertimeApiModel } from 'src/app/models/leaving';
import { Router } from '@angular/router';
import { DropdownModel } from 'src/app/models/dropdown.model';
import { DialogService } from 'src/app/services/dialog.service';
declare var $: any;

class ModalData {
  id: number;
  rate: number;
  rateOption: DropdownModel;
  date: Date | string;
  isHasEvent:boolean;
}

@Component({
  selector: 'app-rate-overtimes',
  templateUrl: './rateovertimes.component.html',
  styleUrls: ['./rateovertimes.component.css']
})
export class RateOverTimesComponent implements OnInit {
  @ViewChild('fullcalendar') fullcalendar: FullCalendarComponent;
  @ViewChild('modalContent') modalContent: TemplateRef<any>;
  @ViewChild('dialogModal') dialogModal: TemplateRef<any>;
  bodyHTML = document.getElementById('body');
  plugins = [dayGridPlugin, interactionPlugin, listPlugin];
  isAdmin: boolean;
  options: OptionsInput;
  modalData: ModalData;
  eventsModel: EventInput[];
  model: RateOvertimeModel;
  models: RateOvertimeModels = new RateOvertimeModels();
  rateOvertimeList: RateOvertimeModel[] = [];
  rate: DropdownModel[];
  defaultRate: number = 2;
  stringDateTime: string;
  isListView: boolean = false;

  // sort row data table
  sortName: string | null = null;
  sortValue: string | null = null;
  listOfRates: Array<RateOvertimeApiModel> = [];
  listOfDisplayRates: Array<RateOvertimeApiModel> = [];

  constructor(
    public router: Router,
    private leavingService: LeavingService,
    private hardcodeService: HardcodeService,
    private modal: BsModalService,
    private dialogService: DialogService
  ) {
  }

  ngOnInit() {
    this.model = new RateOvertimeModel();
    this.hardcodeService.getHardcode(HARDCODE_CONST.overTimeRateType)
        .subscribe(data => {
            this.rate = data;
            this.model.rate = this.defaultRate;
        });
    this.isAdmin = AuthService.isAdmin();
    this.stringDateTime = DatetimeUtils.toShortDateTimeFormat((new Date().toDateString()));
    this.getRateOverTime();
    this.options = {
      eventLimitText: 'yêu cầu',
      listDayFormat: true,
      listDayAltFormat: true,
      unselectAuto: false,
      displayEventEnd: true,
      selectMinDistance: 2,
      selectable: this.viewOptions.selectable,
      eventLimit: 1,
      editable: false,
      aspectRatio: this.aspectRatio,
      locale: viLocale,
      footer: this.viewOptions.footer,
      plugins: this.plugins,
      noEventsMessage: 'Không có tỷ giá tăng ca nào!',
      buttonText: {
        listMonth: 'Danh sách tháng'
      },
      customButtons: {
        viewListRatesOvertime: {
          text: 'Danh sách',
          icon: 'mdi mdi mdi-format-line-style',
          click: () => this.viewListRatesOvertime()
        }
      },
      header: this.viewOptions.header
    };
  }

  ngAfterViewInit() {
    let that = this;
    (function () {
        $(document).on('click', '.editRate', (e) => {
            that.genModelInListView(e.currentTarget.dataset.itemId, e.currentTarget.dataset.itemDate, e.currentTarget.dataset.itemRate);
        });
        $(document).on('click', '.deleteRate', (e) => {
          that.deleteRateOverTime(e.currentTarget.dataset.itemId);
      });
    })();
}

  viewListRatesOvertime() {
    this.isListView = !this.isListView;
    this.getRateOverTime();
  }

  get viewOptions(): any {
    let result = {
      selectable: true,
      footer: { center: 'submitDayOff', right: 'viewHolidayDetail' } || false,
      header: { left: 'prev,next today', center: 'title', right: '' } || false
    };
    if (this.isAdmin) {
      result.footer = false;
      result.selectable = false;
      result.header = { left: 'prev,next today', center: 'title', right: 'viewListRatesOvertime' };
    }
    return result;
  }

  get aspectRatio(): number {
    const currentWindowSize = window.innerWidth;
    if (currentWindowSize > 768) return 2.4;
    if (currentWindowSize > 413) return 0.8;
    if (currentWindowSize > 374) return 0.69;
    else return 0.575;
  }

  // view actions
  dateClick(model: DateClickApi) {
    if (this.isAdmin) {
        //this.genModalToApprove(model);
        let rate = this.isDayHasEvent(model.dateStr);
        if(rate)
        {
            this.modalData = this.genModalToSubmit(rate.id,model.date, rate.rate);
            this.modalData.isHasEvent = true;
        }
        else
        {
            this.modalData = this.genModalToSubmit(0,model.date, 2);
            this.modalData.isHasEvent = false;
        }
        this.modal.show(this.modalContent);
    }
  }

  eventClick(model: EventInput): any {
    if (this.isAdmin) {
        this.genModelToview(model)
        this.modalData.isHasEvent = true;
        this.modal.show(this.modalContent);
    }
  }

  genModelInListView(id, date, rate){
    this.modalData = {
      id, date, rate, rateOption: this.rate.find(p=>p.value === rate) ,
      isHasEvent : true
    };
    this.modalData.isHasEvent = true;
    this.modal.show(this.modalContent);
  }

  closeModal(): void {
    this.bodyHTML.classList.remove('modal-open');
    this.modal._hideModal(this.modal.getModalsCount());
  }

  updateView() {
    this.fullcalendar.getApi().destroy();
    this.fullcalendar.getApi().render();
    this.getRateOverTime();
  }

  genModalToSubmit(id: number, date: Date, rate: number): ModalData {
    return {
        id:id,
        date: date,
        rate: rate,
        rateOption: this.rate.find(p=>p.value === rate),
        isHasEvent : false
    };
  }

  eventRender(info: any) {
    if (this.isAdmin) {
      info.el.innerHTML = `
      <div class="fc-content"> <span class="fc-title"> x${info.event.extendedProps.rate}</span></div>
      `;
    }
  }

  genModelToview(model) {
    const pros = model.event.extendedProps;
    this.defaultRate = pros.rate;
    this.modalData = {
      id:pros.id,
      date: pros.date,
      rateOption: this.rate.find(p=>p.value === pros.rate),
      rate: pros.rate,
      isHasEvent : true
    };
  }
  getRateOverTime(): void {
    this.leavingService.getRateOvertime(this.stringDateTime).subscribe(data => {
      this.listOfRates = data;
      this.listOfDisplayRates = [...this.listOfRates];

      this.eventsModel = data.map<EventInput>(day => {
        return {
          title: "x"+ day.rate,
          date: day.dateTime,
          start:day.dateTime,
          end:day.dateTime,
          extendedProps: {
            id: day['id'],
            date: day.dateTime,
            rate: day.rate,
            rateOption: this.rate.find(x=>x.value === day.rate)
          },
          classNames: [
            'x' + day.rate
          ]
        };
      });
    });
  }
  submit() {
    this.model.datetime = DatetimeUtils.toShortDateTimeFormat((this.modalData.date.toString()));
    this.model.rate = this.modalData.rateOption.value;
    this.updateRateOvertime();
  }
  updateRateOvertime() {
    this.leavingService.submitRateOvertime(this.model).subscribe(() => {
        this.closeModal();
        this.dialogService.message("Chúc mừng", `Bạn đã ${this.modalData.isHasEvent ? 'cập nhật' : 'thêm'} tỷ giá thành công!!!`, () => {
            this.model = new RateOvertimeModel();
            this.model.rate = this.defaultRate;
            this.stringDateTime = DatetimeUtils.toShortDateTimeFormat((new Date().toDateString()));
        }, "Hoàn tất");
        this.updateRateOvertimeList(this.stringDateTime);
        if (!this.isListView) this.updateView();
    });
    }
    updateRateOvertimeList(dateTime: string) {
        this.leavingService.getRateOvertime(dateTime).subscribe(data => {
            this.listOfRates = data;
        });
    }

    isDayHasEvent(date) {
        return this.listOfRates.find(
          day => DatetimeUtils.toShortDateStandard(day.dateTime + "") === date
        );
    }

    deleteRateOverTime(id = 0) {
        this.dialogService.confirm("Xóa", "Bạn có chắc muốn xóa tỷ giá tăng ca của ngày này?", () => {
            let apiModel = new RateOvertimeApiModel();
            apiModel.id = !this.isListView ? this.modalData.id : id;
            this.leavingService.deleteRateOT(apiModel)
                .subscribe(res => {
                    this.stringDateTime = DatetimeUtils.toShortDateTimeFormat((new Date().toDateString()));
                    this.updateRateOvertimeList(this.stringDateTime);
                    if (!this.isListView) this.updateView();
                })
            this.closeModal();
        });
    }

    sort(sort: { key: string; value: string }): void {
      this.sortName = sort.key;
      this.sortValue = sort.value;
      this.search();
    }

    search(): void {
      const filterFunc = (item: RateOvertimeApiModel) => true;
      const data = this.listOfRates.filter(item => filterFunc(item));
      if (this.sortName && this.sortValue) {
        this.listOfDisplayRates = data.sort((a, b) =>
          this.sortValue === 'ascend'
            ? a[this.sortName!] > b[this.sortName!]
              ? 1
              : -1
            : b[this.sortName!] > a[this.sortName!]
            ? 1
            : -1
        );
      } else {
        this.listOfDisplayRates = data;
      }
    }
}
