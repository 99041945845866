import { Component, Input } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

@Component({
    selector: 'select-image',
    templateUrl: './select-image.component.html',
    styleUrls: ['./select-image.component.css']
  })
export class SelectImageComponent implements ControlValueAccessor {
  value: any;
  @Input() title: string;
  @Input() placeholder = '';
  @Input() name: string;
  @Input() disabled: boolean;

  onChange: (_: any) => void = (_: any) => { };
  onTouched: () => void = () => { };
  writeValue(obj: any): void {
  }
  registerOnChange(fn: any): void {
  }
  registerOnTouched(fn: any): void {
  }
  setDisabledState?(isDisabled: boolean): void {
  }
}
