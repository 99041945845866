
export class OverTimeInfomationModel {
    constructor() {
        this.userOverTime = new UserNameModel();
    }
    totalOverTime: number;
    totalUser: number;
    maxOverTime: number;
    totalPaymentOverTime: number;
    userOverTime: UserNameModel;
}
export class OverTimeModel {
    constructor() {
        this.projects = [];
    }
    submitTime: string;
    timeStart: string;
    timeFinish: string;
    workTime: number;
    description: string;
    projectIds: string;
    projects: ProjectShortInfo[];
    user: UserNameModel;
    userId: number;
    rateTime: string;
    isLocked: boolean = false;
}
export class ProjectShortInfo {
    id: string;
    projectName: string;
    projectAvatar: string;
}
export class UserNameModel {
    userId: number;
    userName: string;
    userImage: string;
}
