import { Injectable } from '@angular/core';
import { BaseService } from './common/base.service';
import { HttpClient } from '@angular/common/http';
import { ErrorService } from './common/error.service';
import { GlobalService } from './global.service';
import { CreateMeetingModel, DeleteMeetingModel, MeetingApiModel } from '../models/meeting.model';
import { API_ENDPOINT } from '../app.constants';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MeetingService extends BaseService {
    constructor(protected http: HttpClient,
        protected errorHandler: ErrorService,
        protected globalService: GlobalService) {
        super(http, errorHandler, globalService);
    }
    createMeeting(model: CreateMeetingModel) {
        return this.post(API_ENDPOINT.create_meeting, model, true);
    }
    editMeeting(model: CreateMeetingModel) {
        return this.post(API_ENDPOINT.edit_meeting, model, true);
    }
    deleteMeeting(model: DeleteMeetingModel) {
        return this.post(API_ENDPOINT.delete_meeting, model, true);
    }
    getMeetings(): Observable<MeetingApiModel[]> {
        var result = this.get(API_ENDPOINT.get_meetings, null, true);
        return result;
    }
}
