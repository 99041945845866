import { Injectable, OnInit, Output } from '@angular/core';
import * as signalR from "@aspnet/signalr";
import { Subject } from 'rxjs';
import { NotifyModel, NotificationsIdsModel, NotificationsModel, NotifyRequestModel } from '../models/notify.model';
import { ExpectedConditions } from 'protractor';
import { reject } from 'q';
import { AlertGroups } from '../models/enums';
import { IEstimateModel } from '../models/pocker';
import { GlobalService } from './global.service';

@Injectable({
    providedIn: 'root'
})
export class RealtimeClientService {

    private hubconnection: signalR.HubConnection;

    notifyPushed = new Subject<any>();
    notifyPushedEmiiter$ = this.notifyPushed.asObservable;

    notifyPushedList = new Subject<any>();
    notifyPushedListEmiiter$ = this.notifyPushedList.asObservable;

    notifyCountUpdated = new Subject<number>();
    notifyCountUpdatedEmitter$ = this.notifyCountUpdated.asObservable;

    forceLogOut = new Subject();
    forceLogOutEmitter$ = this.forceLogOut.asObservable;

    estimates = new Subject<IEstimateModel[]>();

    constructor(private globalService: GlobalService) { }

    isServerConnected(): boolean {
        return (this.hubconnection != null && this.hubconnection.state == signalR.HubConnectionState.Connected);
    }

    clientInit(token: string, userId: number, callback?: () => void) {

        if (!token)
            return;

        if (this.hubconnection != null && this.hubconnection.state == signalR.HubConnectionState.Connected) {
            if (callback != null)
                callback.call(this);
            return;
        }

        this.hubconnection = new signalR.HubConnectionBuilder()
            .configureLogging(signalR.LogLevel.None)
            .withUrl("/isHub", { accessTokenFactory: () => { return token; }, transport: signalR.HttpTransportType.LongPolling })
            .build();

        this.hubconnection.on("Notify", (notify) => {
            this.notifyPushed.next(notify);
        });

        this.hubconnection.on("NotifyCountUpdated", (count) => {
            this.notifyCountUpdated.next(count);
        });

        this.hubconnection.on("ForceLogOut", (count) => {
            this.forceLogOut.next(count);
        });

        this.hubconnection.on("NotifiesUpdated", (notifies) => {
            if (notifies)
                return;
            //this.notifyPushedList.next(notifies);
        });

        this.hubconnection.on("UpdateEstimate", () => {
            this.globalService.updateEstimates.next(true);
        });

        this.hubconnection.on("UpdateDescription", (des) => {
          this.globalService.updateDescription.next(des);
        });

        this.hubconnection.start()
            .then(() => {

                this.hubconnection.send("Login", userId)
                    .then(() => {
                        if (callback != null)
                            callback.call(this);
                    })
                    .catch(res => {
                    });
            })
            .catch(res => console.log(res));
    }

    revokeUnreadNotifyCount(userId: number): Promise<void> {
        return this.hubconnection.send("InvokeUnreadMessageCount", userId);
    }

    getNotifications(userId: number, page, numOfPage, notifyType: AlertGroups): Promise<NotificationsModel> {
        var model = new NotifyRequestModel(userId, page, numOfPage, notifyType);
        return this.hubconnection.invoke<NotificationsModel>("GetNotifies", model);
    }

    asReadNotify(notifyId: number, isAdRead: boolean): Promise<boolean> {
        return this.hubconnection.invoke<boolean>("AsRead", notifyId, isAdRead);
    }

    asReadNotifies(model: NotificationsIdsModel): Promise<boolean> {
        return this.hubconnection.invoke<boolean>("AsReadNotifies", model);
    }

    markAsreadAllNotifications(userId: number): Promise<void> {
        return this.hubconnection.send("AsReadAllNotifications", userId);
    }

    removeNotify(notifyId: number, userId: number): Promise<void> {
        return this.hubconnection.invoke("RemoveNotify", notifyId, userId);
    }

    removeNotifies(model: NotificationsIdsModel): Promise<void> {
        return this.hubconnection.invoke("RemoveNotifies", model);
    }

    disConnecting(callback?: () => void) {
        this.hubconnection.stop()
            .then(() => {
                callback.call(this);
                this.hubconnection = null;
            })
            .catch(() => {
                this.hubconnection = null;
            });
    }
}
