import { Injectable } from '@angular/core';
import { DayOffParent, DayOffForView, DayOffChildren, RateOvertimeModels, RateOvertimeApiModel, Holiday, DayOffToUpdate, SpecialDayModel, RateOvertimeModel } from '../models/leaving';
import { Observable, of } from 'rxjs';

import { BaseService } from './common/base.service';
import { HttpClient } from '@angular/common/http';
import { ErrorService } from './common/error.service';
import { GlobalService } from './global.service';
import { API_ENDPOINT } from '../../app/app.constants';
import { DayToPost } from '../models/fullcalendar.model';

@Injectable({
    providedIn: 'root'
})
export class LeavingService extends BaseService {
    constructor(protected http: HttpClient,
        protected errorHandler: ErrorService,
        protected globalService: GlobalService) {
        super(http, errorHandler, globalService);
    
      }

  updateDayoff(model: DayOffToUpdate): Observable<any> {
    return this.post(API_ENDPOINT.update_dayoff, model, true);
  }

  updateWorkRemote(model: DayOffToUpdate): Observable<any> {
    return this.post(API_ENDPOINT.update_workremote, model, true);
  }

  getLeavings(userId:number = 0): Observable<DayOffForView[]> {
      var abc = this.get(`${API_ENDPOINT.get_daysoff}?userId=${userId}`, null, true);
    return abc;
    }

  getSpecialDay(): Observable<DayOffForView[]> {
    var abc = this.get(API_ENDPOINT.get_daysoff_holiday, null, true);
    return abc;
  }
  getHolidays(): Observable<DayOffForView[]> {
    var data = this.get(API_ENDPOINT.get_holidays, null, true);
    return data;
  }

  submit(model: DayOffParent | DayToPost): Observable<number> {
      return this.post(API_ENDPOINT.submit_daysoff, model, true);
  }

  submitHolidays(model: DayOffParent): Observable<number> {
    return this.post(API_ENDPOINT.submit_holiday, model, true);
  }

  deleteDayoff(model: SpecialDayModel) {
    return this.post(API_ENDPOINT.delete_dayoff, model, true);
  }

  deleteHoliday(model: Holiday) {
    return this.post(API_ENDPOINT.delete_dayoff, model, true);
  }

  submitRateOvertime(model: RateOvertimeModel): Observable<number> {
    return this.post(API_ENDPOINT.submit_rateovertime, model, true);
  }

  getRateOvertime(dateTime: string):Observable<RateOvertimeApiModel[]> {
    return this.get(`${API_ENDPOINT.get_rateovertime}?dateTime=${dateTime}`, null, true);
  }

  deleteRateOT(model: RateOvertimeApiModel) {
    return this.post(API_ENDPOINT.delete_rateovertime, model,true);
  }

  getDayoffNotApproved() {
    return this.get(API_ENDPOINT.get_daysoff_not_approved, null, false);
  }

  getWorkRemoteDays(): Observable<DayOffForView[]> {
    var data = this.get(API_ENDPOINT.get_workremote_day, null, true);
    return data;
  }

  submitWorkRemoteDays(model: DayOffParent| DayToPost): Observable<number> {
    return this.post(API_ENDPOINT.submit_workremote_day, model, true);
  }
}
