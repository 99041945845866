import { Injectable } from '@angular/core';
//
import { NotificationService } from '../services/notify.service';
import { MAX_SIZE_FILE_UPLOAD } from './constants';

@Injectable(
  {
    providedIn: 'root'
  }
)
export class NotificationHelper {
  public constructor(private notifyService: NotificationService){}

  public isValidationSizeUpload(file: any): boolean {
    if (file.size/1024/1024 > MAX_SIZE_FILE_UPLOAD) {
      this.notifyService.warning(`Max size file upload must be less ${MAX_SIZE_FILE_UPLOAD}MB !!`);
      return false;
    }
    return true;
  }
}
