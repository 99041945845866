import { BaseService } from './common/base.service';
import { Injectable } from '@angular/core';
import { ErrorService } from './common/error.service';
import { GlobalService } from './global.service';
import { HttpClient } from '@angular/common/http';
import { API_ENDPOINT } from '../app.constants';
import { ReportCreateModel, ReportGetModel } from '../models/report.model';

@Injectable({
    providedIn: 'root'
})
export class ReportService extends BaseService {
    constructor(protected http: HttpClient,
        protected errorHandler: ErrorService,
        protected globalService: GlobalService) {
        super(http, errorHandler, globalService);
  }
  createReport(model: ReportCreateModel){
      return this.post(API_ENDPOINT.create_daily_report, model, true);
  }
  getReports(model: ReportGetModel){
    return this.post(API_ENDPOINT.get_daily_reports, model, true);
  }
  getReportById(reportId: number){
    return this.get(`${API_ENDPOINT.get_report_by_id}?reportId=${reportId}`);
  }
  editReport(model: ReportCreateModel){
    return this.post(API_ENDPOINT.edit_daily_report, model, true);
  }
}