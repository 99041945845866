import { Component, OnInit } from '@angular/core';
import { RewardService } from 'src/app/services/reward.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RewardResponseModel, CreateRewardModel } from 'src/app/models/reward.model';
import { NgForm } from '@angular/forms';
import { DialogService } from 'src/app/services/dialog.service';
declare function initSummerNoteTemplate(id, content: string): any;
declare function saveFormInputValue(): string;
declare function initSelect2(): any;
declare var $: any;
@Component({
  selector: 'app-reward-view',
  templateUrl: './reward-view.component.html',
  styleUrls: ['./reward-view.component.css']
})
export class RewardViewComponent implements OnInit {
  model: RewardResponseModel;
  submitModel: CreateRewardModel = new CreateRewardModel();
  constructor(
    private rewardService: RewardService,
    private dialogService: DialogService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.model = new RewardResponseModel();
    this.route.params.subscribe(params => {
      if (!params['id']) {
        return;
      }
      else {
        let rewardId = +params['id'];
        this.rewardService.getRewardTemplateById(rewardId).subscribe(data => {
          this.model = data;
          initSummerNoteTemplate("#summernote", this.model.template);
          this.appendSelectedUsers();

        });
      }
    });

  }

  appendSelectedUsers() {
    let selectedUsers = [];
    this.model.users.forEach((user) => {
      selectedUsers.push(user.userId.toString());
    });
    (setTimeout(() => {
      initSelect2();

      $("#selectUser").val(selectedUsers).change();
      if (this.model.isSendReward) {
        $('#summernote').summernote('disable');
        $('#selectUser').attr("disabled", true);
      }
    }));


  }

  sendSaveOrSave(form: NgForm, isSend: boolean) {
    if (form.invalid) return;

    let eleSelectUsers = document.getElementById("selectUser");

    this.submitModel.userIds.userIds = $(eleSelectUsers).val();
    this.submitModel.isSendReward = isSend;
    this.submitModel.template = saveFormInputValue();
    this.submitModel.subject = this.model.subject;
    this.submitModel.value = this.model.value;
    this.submitModel.id = this.model.id;

    this.rewardService.createOrSendReward(this.submitModel).subscribe(() => {
      this.dialogService.message("Thành công", `Bạn đã chỉnh sửa mẫu khen thưởng thành công.`, () => {
        this.router.navigate(['/reward-list']);
      }, "Đóng");
    });
  }

  deleteTemplate() {
    this.model = new RewardResponseModel();
    $(".note-editable").first().text("");
    $("#selectUser").val([]).change();
  }

}
