<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">{{model.title}}</h5>
  <button type="button" class="close" data-dismiss="modal" (click)="closeModal()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <h5>{{model.content}}</h5>
  <div *ngIf="model.HTML" [innerHTML]="model.HTML"></div>
</div>
<div class="modal-footer">
  <button class="btn btn-custom save-event waves-effect waves-light btn-fw" type="button" (click)="yesBtnClicked()">{{model.mainActionButtonText}}</button>
  <button class="btn btn-light waves-effect btn-fw" type="button" (click)="noBtnClicked()">{{model.subActionButtonText}}</button>
</div>