<div class="row">
  <div class="col-md-12">
    <div class="card m-b-30">
      <div class="row card-header bg-transparent">
        <div class="">
          <h5 id="roomId">Mã Phòng: {{room.roomId}}</h5>
        </div>
        <button nz-button (click)="copyToClipboard(room.roomId)">Sao chép</button>
      </div>

      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <label for="descripton">Mô tả</label>
            <textarea id="description" class="form-group col-md-12 textArea m-b-3" [(ngModel)]="room.description"
              (ngModelChange)="changeDesciption($event)" name="estimateDescription" rows="5" [disabled]="!isOwner">
            </textarea>
          </div>
        </div>

        <div class="row">

          <div class="col-12 pocker-wrapper">
            <div class="card pocker" *ngFor="let item of estimeData">
              <div class="card-body">
                <h4 class="card-text">
                  {{item.name}}
                </h4>
                <button nz-button (click)="submitEstimate(item.value)"
                  [disabled]="item.value == hourOfUser ? true : false">
                  Submit
                </button>
              </div>
            </div>
          </div>

        </div>


        <div class="row" *ngIf="isOwner">
          <div class="col-md-12">
            <h3>Kết Quả</h3>
            <div class="table-wrapper">
              <div class="btn-group d-flex justify-content-between py-3">
                <button nz-button (click)="deleteRoom()">CLEAR ROOM</button>
                <button nzType="danger" nz-button (click)="deleteEstimates()">DELETE ESTIMATES</button>
              </div>
              <nz-table #basicTable [nzData]="estimates">
                <thead>
                  <tr>
                    <th>Họ tên</th>
                    <th>Thời gian</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of basicTable.data">
                    <td>{{ data.userFullName }}</td>
                    <td> <span *ngIf="data.hour">{{ data.hour}} Giờ</span></td>
                  </tr>
                </tbody>
              </nz-table>
            </div>
          </div>
        </div>
      </div>

      <div class="row card-footer" *ngIf="!isOwner">
        <button nz-button nzType="danger" (click)="exitRoom()">Thoát Room</button>
      </div>
    </div>
  </div>
</div>
