import { BaseService } from './common/base.service';
import { Injectable } from '@angular/core';
import { ErrorService } from './common/error.service';
import { GlobalService } from './global.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class HomepageService extends BaseService {
    constructor(protected http: HttpClient,
        protected errorHandler: ErrorService,
        protected globalService: GlobalService) {
        super(http, errorHandler, globalService);

  }

  getHomepageDetail() {
    return this.post('/Home/Index');
    };
    
    getAdminHomepage(dateTimeLocal: string) {
        return this.get(`/Home/GetHomeAdminIndex?dateTimeLocal=${dateTimeLocal}`, null, true);
    };
}
