import { Component, OnInit, Inject, forwardRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { UserInfo, UserIdentity, UserSalaryCustomer, UserSalaryCustomerData } from 'src/app/models/userinfo.model';
import { AppComponent } from 'src/app/app.component';
import { UserService } from 'src/app/services/user.service';
import { HardcodeService } from 'src/app/services/hardcode.service';
import { DropdownModel } from 'src/app/models/dropdown.model';
import { DEFAULT_SETTINGS, HARDCODE_CONST } from 'src/app/app.constants';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notify.service';
import { BsDatepickerConfig } from 'ngx-bootstrap';
import { MainLayoutComponent } from '../../main-layout/main-layout.component';
import { DatetimeUtils } from 'src/app/utils/dateutil';
import { NotificationHelper } from 'src/app/utils';
import { CurrencyPipe } from '@angular/common';
import { GetCustomerModel } from 'src/app/models/customer.models';
import { CustomerService } from 'src/app/services/customer.service';
import { RoleUserEnum, SalaryType, CurrencyType, Gender } from 'src/app/models/enums';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'user-profile-update',
  templateUrl: './user-profile-update.component.html',
  styleUrls: ['./user-profile-update.component.css']
})
export class UserProfileUpdateComponent implements OnInit {
  userInfo = new UserInfo();
  userIdentity = new UserIdentity();
  genderModel: DropdownModel[];
  salaryTypeModel: DropdownModel[];
  roleModel: DropdownModel[];
  currencyTypeModel: DropdownModel[];
  currencyBasicTypeModel: DropdownModel[];
  userId: number;
  imageInfo: string;
  idFrontImageInfo: string;
  idBackImageInfo: string;
  isAdmin: boolean;
  tempBirthday: string;
  tempDateJoinCompany: string;
  tempIdIssueDate: string;
  isSelfEditting: boolean = true;
  avatar;
  idFrontImage;
  idBackImage;
  bsConfig: Partial<BsDatepickerConfig>;
  currentDate: string;
  timeZones: DropdownModel[];
  defaultRole: DropdownModel;
  defaultSalaryType: DropdownModel;
  defaultCurrencyType: DropdownModel;
  defaultCurrencyBasicType: DropdownModel;
  defaultGender: DropdownModel;

  basicSalary: string = "";
  lunchMoney: string = "";
  telephoneFee: string = "";
  petrolMoney: string = "";
  housingSupport: string = "";
  reduceYourself: string = "";

  listOfData : UserSalaryCustomer[]= [];
  customers: DropdownModel[];
  index: number = 0;
  currencyUSD: CurrencyType = CurrencyType.USD;
  roleFreelance: RoleUserEnum = RoleUserEnum.Freelance;

  constructor(
    private customerService: CustomerService,
    private userService: UserService,
    private hardcodeService: HardcodeService,
    private router: Router,
    private activatedRouter: ActivatedRoute,
    private notify: NotificationHelper,
    private currencyPipe: CurrencyPipe,
    @Inject(forwardRef(() => AppComponent)) private appComm: AppComponent,
    private mainLayout: MainLayoutComponent) {

  }

  ngOnInit() {
    this.bsConfig = Object.assign({}, {
      containerClass: 'theme-blue',
      dateInputFormat: 'DD/MM/YYYY'
    });

    this.customerService.getCustomers(new GetCustomerModel())
    .subscribe(data => {
      this.customers = this.toDropdownModel(data);
    });

    this.isAdmin = AuthService.isAdmin();
    const id = Number.parseInt(this.activatedRouter.snapshot.paramMap.get('id'));
    if (id) {
      this.userId = id;
      this.isSelfEditting = false;
    }

  }

  ngAfterViewInit() {
    this.getDataInPage();
  }

  async callHardCode(typeHardCode: string): Promise<void> {
    let data = await lastValueFrom(this.hardcodeService.getHardcode(typeHardCode));

    switch (typeHardCode) {
      case HARDCODE_CONST.gender:
        this.genderModel = data;
        this.defaultGender = this.genderModel && this.genderModel.find(p => p.value === Gender.Male);
        break;
      case HARDCODE_CONST.userRole:
        this.roleModel = data;
        this.defaultRole = this.roleModel && this.roleModel.find(x => x.value === RoleUserEnum.Employee);
        break;
      case HARDCODE_CONST.salaryType:
        this.salaryTypeModel = data;
        this.defaultSalaryType = this.salaryTypeModel && this.salaryTypeModel.find(x => x.value === SalaryType.Net);
        break;
      case HARDCODE_CONST.currencyType:
        this.currencyTypeModel = data;
        this.defaultCurrencyType = this.currencyTypeModel && this.currencyTypeModel.find(x => x.value === CurrencyType.USD);

        this.currencyBasicTypeModel = data;
        this.defaultCurrencyBasicType = this.currencyBasicTypeModel && this.currencyBasicTypeModel.find(x => x.value === CurrencyType.VND);
        break;
    }
  }

  async callTimeZones(): Promise<void> {
    let data = await lastValueFrom(this.userService.GetTimeZones());
    if (data) {
      this.timeZones = data.map(q => {
        return {
          name: q.name,
          value: q.id
        }
      });
    }
  }

  async getDataInPage(): Promise<void> {
    try {
      await this.callHardCode(HARDCODE_CONST.gender);
      await this.callHardCode(HARDCODE_CONST.userRole);
      await this.callHardCode(HARDCODE_CONST.salaryType);
      await this.callHardCode(HARDCODE_CONST.currencyType);
      await this.callTimeZones();
      await this.getUserInfo();
    } catch (error) {
      console.error('Error calling APIs:', error);
    }
  }

  onChangeIdFrontImage(event) {
    if (event.target.files && event.target.files[0]) {
      if(this.notify.isValidationSizeUpload(event.target.files[0])) {
        const filesUpload: File = event.target.files[0];
        const reader = new FileReader();
        reader.onload = e => this.idFrontImage = reader.result;
        reader.readAsDataURL(filesUpload);
        this.userIdentity.idFrontImageUpload = filesUpload;
        this.idFrontImageInfo = filesUpload.name.toString();
        this.userIdentity.isDeleteIdFrontImage = false;
      }
    }
  }

  deleteIdFrontImage() {
    this.userInfo.idFrontImage = "";
    this.userIdentity.isDeleteIdFrontImage = true;
  }

  deleteIdBackImage() {
    this.userInfo.idBackImage = "";
    this.userIdentity.isDeleteIdBackImage = true;
  }

  onChangeIdBackImage(event) {
    if (event.target.files && event.target.files[0]) {
      if(this.notify.isValidationSizeUpload(event.target.files[0])) {
        const filesUpload: File = event.target.files[0];
        const reader = new FileReader();
        reader.onload = e => this.idBackImage = reader.result;
        reader.readAsDataURL(filesUpload);
        this.userIdentity.idBackImageUpload = filesUpload;
        this.idBackImageInfo = filesUpload.name.toString();
        this.userIdentity.isDeleteIdBackImage = false;
      }
    }
  }

  onChange(event) {
    if (event.target.files && event.target.files[0]) {
      if(this.notify.isValidationSizeUpload(event.target.files[0])) {
        const filesUpload: File = event.target.files[0];
        const reader = new FileReader();
        reader.onload = e => this.avatar = reader.result;
        reader.readAsDataURL(filesUpload);
        this.userInfo.uploadedFile = filesUpload;
        this.imageInfo = filesUpload.name.toString();
      }
    }
  }
  async getUserInfo(): Promise<void> {
    let apiUserInfo = await lastValueFrom(this.userService.getUserProfile(this.userId));
    if (apiUserInfo) {
      this.userInfo = apiUserInfo;
      this.userInfo.timeZone = this.userInfo.timeZone || DEFAULT_SETTINGS.time_zone_linux;
      this.userIdentity.idIssuePlace = this.userInfo.idIssuePlace;
      this.userIdentity.userIdentity = this.userInfo.userIdentity;
      
      this.userInfo.salaryTypeOption = this.salaryTypeModel && this.salaryTypeModel.find(p => p.value === this.userInfo.salaryType);
      this.userInfo.timeZoneOption = this.timeZones.find(p => p.value === this.userInfo.timeZone) || this.timeZones.find(p => p.value === DEFAULT_SETTINGS.time_zone_linux);
      this.userInfo.genderOption = this.genderModel && this.genderModel.find(p => p.value === this.userInfo.gender);
      this.userInfo.roleOption = this.roleModel && this.roleModel.find(p => p.value === this.userInfo.role);
      this.userInfo.currencyTypeOption = this.currencyTypeModel && this.currencyTypeModel.find(p => p.value === this.userInfo.currencyType);
      this.userInfo.currencyBasicTypeOption = this.currencyBasicTypeModel && this.currencyBasicTypeModel.find(p => p.value === this.userInfo.currencyBasicType);

      //formart date
      this.tempBirthday = this.userInfo.birthday;
      this.userInfo.birthday = DatetimeUtils.toShortDateFormat(this.userInfo.birthday);

      this.tempDateJoinCompany = this.userInfo.dateJoinCompany;
      this.userInfo.dateJoinCompany = DatetimeUtils.toShortDateFormat(this.userInfo.dateJoinCompany);

      this.tempIdIssueDate = this.userInfo.idIssueDate;
      this.userIdentity.idIssueDate = DatetimeUtils.toShortDateFormat(this.userInfo.idIssueDate);

      //formart currency
      this.basicSalary = this.formatNumberCurrency(this.userInfo.basicSalary.toString());
      this.lunchMoney = this.formatNumberCurrency(this.userInfo.lunchMoney.toString());
      this.telephoneFee = this.formatNumberCurrency(this.userInfo.telephoneFee.toString());
      this.petrolMoney = this.formatNumberCurrency(this.userInfo.petrolMoney.toString());
      this.housingSupport = this.formatNumberCurrency(this.userInfo.housingSupport.toString());
      this.reduceYourself = this.formatNumberCurrency(this.userInfo.reduceYourself.toString());

      this.tolistOfData(this.userInfo.customerUserSalary);
    }
  }

  editUserIdentity(form: NgForm) {
    if (form.invalid) {
      return;
    }
    
    
    let payload = { ...this.userIdentity };

    let tempIdIssueDateFormat = DatetimeUtils.toShortDateFormat(this.tempIdIssueDate ? this.tempIdIssueDate : this.userIdentity.idIssueDate);

    if (this.userIdentity.idIssueDate != tempIdIssueDateFormat) {
      payload.idIssueDate = DatetimeUtils.toShortDateTimeFormat(this.userIdentity.idIssueDate);
    }
    else {
      payload.idIssueDate = DatetimeUtils.toShortDateTimeFormat(this.tempIdIssueDate);
    }

    this.userService.updateUserIdentity(payload)
      .subscribe(data => {
        if (data) {
          this.router.navigate(['/user-profile']);
        }
      }, (error) => {
          this.getUserInfo();
      })
  }

  editUser(form: NgForm) {
    if (form.invalid) {
      return;
    }
    this.userInfo.userId = this.userId;
    this.userInfo.role =  this.userInfo.roleOption && this.userInfo.roleOption.value;
    this.userInfo.salaryType = this.userInfo.salaryTypeOption && this.userInfo.salaryTypeOption.value;
    this.userInfo.gender = this.userInfo.genderOption && this.userInfo.genderOption.value;
    this.userInfo.timeZone = this.userInfo.timeZoneOption && this.userInfo.timeZoneOption.value;
    this.userInfo.currencyType = this.userInfo.currencyTypeOption && this.userInfo.currencyTypeOption.value;
    this.userInfo.currencyBasicType = this.userInfo.currencyBasicTypeOption && this.userInfo.currencyBasicTypeOption.value;
    
    this.userInfo.basicSalary = Number(this.currencyToNumber(this.basicSalary))
    this.userInfo.lunchMoney = Number(this.currencyToNumber(this.lunchMoney))
    this.userInfo.telephoneFee = Number(this.currencyToNumber(this.telephoneFee))
    this.userInfo.petrolMoney = Number(this.currencyToNumber(this.petrolMoney))
    this.userInfo.housingSupport = Number(this.currencyToNumber(this.housingSupport))
    this.userInfo.reduceYourself = Number(this.currencyToNumber(this.reduceYourself))
    
    this.userInfo.userSalaryCustomer = JSON.stringify(this.toUserSalaryCustomerData(this.listOfData));

    let payload = { ...this.userInfo };

    this.currentDate = DatetimeUtils.toShortDateFormat((new Date().toDateString()));

    let tempBirthdayFormat = DatetimeUtils.toShortDateFormat(this.tempBirthday);
    let tempDateJoinCompanyFormat = DatetimeUtils.toShortDateFormat(this.tempDateJoinCompany ? this.tempDateJoinCompany : this.userInfo.dateJoinCompany);

    if (this.userInfo.birthday != tempBirthdayFormat) {
      payload.birthday = DatetimeUtils.toShortDateTimeFormat(this.userInfo.birthday);
    }
    else {
      payload.birthday = DatetimeUtils.toShortDateTimeFormat(this.tempBirthday);
    }

    if (this.userInfo.dateJoinCompany != tempDateJoinCompanyFormat) {
      payload.dateJoinCompany = DatetimeUtils.toShortDateTimeFormat(this.userInfo.dateJoinCompany);
    }
    else {
      payload.dateJoinCompany = DatetimeUtils.toShortDateTimeFormat(this.tempDateJoinCompany);
    }

    this.userService.editUser(payload)
      .subscribe(data => {
        if (data) {
          if (!this.isAdmin)
            this.router.navigate(['']);
          else
            this.router.navigate(['/users']);
          this.mainLayout.screenReload();
        }
      })
  }

  formatNumberCurrency(input: string){
    let output = this.currencyPipe.transform(input.replace(/,/g, ''), ' ', 'symbol-narrow', '1.0-0');
    return output;
  }

  currencyToNumber(input: string) {
    if (input == null) {
      return 0;
    }

    let output = input.replace(/,/g, '');
    return output;
  }

  formatPrice(type :string) {
    switch(type){
      case 'basicSalary': 
      this.basicSalary = this.formatNumberCurrency(this.basicSalary); 
      break;
      case 'lunchMoney': 
      this.lunchMoney = this.formatNumberCurrency(this.lunchMoney); 
      break;
      case 'telephoneFee': 
      this.telephoneFee = this.formatNumberCurrency(this.telephoneFee); 
      break;
      case 'petrolMoney': 
      this.petrolMoney = this.formatNumberCurrency(this.petrolMoney); 
      break;
      case 'housingSupport': 
      this.housingSupport = this.formatNumberCurrency(this.housingSupport); 
      break;
      case 'reduceYourself': 
      this.reduceYourself = this.formatNumberCurrency(this.reduceYourself); 
      break;
    }
  }

  deleteRow(id: any): void {
    this.listOfData = this.listOfData.filter(d => d.id !== id);
  }

  addRow(): void {
    let newRow = new UserSalaryCustomer();
    newRow.id = this.listOfData?.length + 1 ;
    newRow.customerModel = new DropdownModel();
    newRow.hourSalaryCustomer = 0;
    newRow.isIncludePIT = true;

    this.listOfData.push(newRow);
  }

  toDropdownModel(data :any) {
    let models = [];
    for (let index = 0; index < data.length; index++) {
      let model = new DropdownModel();
      model.name = data[index].customerName;
      model.value = data[index].id;

      models.push(model);
    }

    return models;
  }

  toUserSalaryCustomerData(data :any) {
    let models = [];
    for (let index = 0; index < data.length; index++) {
      let model = new UserSalaryCustomerData();
      model.hourSalary = data[index].hourSalaryCustomer;
      model.customerId = data[index].customerModel.value;
      model.isIncludePIT = data[index].isIncludePIT;

      models.push(model);
    }

    return models;
  }

  tolistOfData(data :any) {

    for (let index = 0; index < data.length; index++) {
      let model = new UserSalaryCustomer();
      model.hourSalaryCustomer = data[index].hourSalary; 
      let dropdown = this.customers.find((x) => x.value == data[index].customerId);
      model.customerModel = dropdown;
      model.isIncludePIT = data[index].isIncludePIT;
      model.id = index;

      this.listOfData.push(model);
    }
  }
}