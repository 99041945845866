import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { NotifyModel, NotificationsModel } from '../models/notify.model';
import { MenuItems } from '../models/enums';


@Injectable(
  {
    providedIn: 'root'
  }
)
export class GlobalService {
  loader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  signalRClientLoader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  notifyComming: BehaviorSubject<NotifyModel> = new BehaviorSubject<NotifyModel>(null);
  notifyList: BehaviorSubject<NotificationsModel> = new BehaviorSubject<NotificationsModel>(null);
  updateEstimates = new Subject<boolean>();
  updateDescription = new Subject<string>();

  private countLoading = 0;

  constructor() { }

  loading() {
    this.countLoading++;
    this.loader.next(!!this.countLoading);
  }

  loaded() {
    this.countLoading--;
    this.loader.next(!!this.countLoading);
  }

  signalRClientInitDone() {
    this.signalRClientLoader.next(true);
  }

  notifyCommingInvoke(notify: NotifyModel) {
    this.notifyComming.next(notify);
  }

  notifyListComing(notifyList: NotificationsModel) {
    this.notifyList.next(notifyList);
  }
}
