<div class="form-group">
    <app-shared-image [altImg]="'image'">
    </app-shared-image>
</div>
<div class="form-group">
    <input type="file" class="file-upload-default" (change)="onChange($event)" #imageUpload>
    <div class="input-group col-sm-9">
        <input type="text" class="form-control file-upload-info" disabled
            placeholder="Tải Ảnh Lên">
        <span class="input-group-append">
            <button class="btn btn-info" type="button" (click)="imageUpload.click()">Tải
                Lên</button>
        </span>
    </div>
</div>
