<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-body">

        <div class="row mb-3">
          <div class="col-sm-6 join-room">
            <input class="mr-3" nz-input placeholder="Nhập mã phòng" [(ngModel)]="roomId" />
            <button nzType="primary" nz-button (click)="joinRoom($event, roomId)">Tham gia vào phòng</button>
          </div>
          <div class="col-sm-6 add-room">
            <button nzType="primary" nz-button (click)="createRoom()">Tạo phòng mới</button>
          </div>
        </div>

        <nz-table #basicTable [nzData]="rooms">
          <thead>
            <tr>
              <th>Mã Phòng</th>
              <th>Ngày Tạo</th>
              <th width="120"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of basicTable.data" (click)="goToRoom(data.id)">
              <td>{{ data.roomId }}</td>
              <td>{{ data.createDate | date: 'dd-MM-yyyy' }}</td>
              <td>
                <button *ngIf="!joinedRoomIds.includes(data.id)" nzType="primary" nz-button
                  (click)="joinRoom($event, data.roomId)" class="mr-3">Tham Gia</button>
                <button *ngIf="data.ownerId == userId" nzType="danger" nz-button
                  (click)="deleteRoom($event, data.id)">Xóa Phòng</button>
              </td>
            </tr>
          </tbody>
        </nz-table>
      </div>
    </div>
  </div>
</div>