import { Component, OnInit, ViewChild, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { LeavingService } from '../../services/leaving.service';
import { AuthService } from 'src/app/services/auth.service';
import { HardcodeService } from 'src/app/services/hardcode.service';
import { BsModalService } from 'ngx-bootstrap';

// fullcalendar
import { OptionsInput, EventInput } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import viLocale from '@fullcalendar/core/locales/vi';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { DayToPost, LeavingDay } from '../../models/fullcalendar.model';
import {
  SpecialDayType,
  DayOffOption,
  DialogStyles,
  DayoffStatus,
  DayoffStatusName_Vi
} from 'src/app/models/enums';
import { DatetimeUtils } from '../../utils/dateutil';
import { HARDCODE_CONST } from 'src/app/app.constants';
import { DateClickApi } from '@fullcalendar/core/Calendar';
import { DialogModel } from 'src/app/models/dialog.model';
import { DayOffToUpdate, DayOffForView, HolidayData, Holiday, DayOffParent, SpecialDayModel, UpdateDayOffModel, EventModel } from 'src/app/models/leaving';
import { Router } from '@angular/router';
import { DialogService } from 'src/app/services/dialog.service';
import {DropdownModel} from "../../models/dropdown.model";
declare var $: any;

interface DaySelected {
  date: Date;
  option?: DayOffOption;
}

interface ModalData {
  reason: string;
  isurgent?: boolean;
  start: Date | string;
  end?: Date | string;
  userName?: string;
  status?: string;
  option?: string;
}

@Component({
  selector: 'app-work-remote',
  templateUrl: './work-remote.component.html',
  styleUrls: ['./work-remote.component.css']
})
export class WorkRemoteComponent implements OnInit {
  @ViewChild('fullcalendar') fullcalendar: FullCalendarComponent;
  @ViewChild('modalContent') modalContent: TemplateRef<any>;
  @ViewChild('modalContentHoliday') modalContentHoliday: TemplateRef<any>;
  @ViewChild('dialogModal') dialogModal: TemplateRef<any>;
  @ViewChild('modalContentOnlyView') modalContentOnlyView: TemplateRef<any>;
  bodyHTML = document.getElementById('body');
  plugins = [dayGridPlugin, interactionPlugin, listPlugin];
  ClassList = ['default', 'fullday', 'morning', 'afternoon'];
  ColorList = ['#ffffff', '#3f51b6', '#01bcd6', '#5677fc'];
  HolidayClassList = ["makeup", "certain", "un-certain", "company-specified"];
  ONE_DAY = 8400000;
  isListView: boolean = false;
  isAdmin: boolean;
  dayoffOption: any;
  options: OptionsInput;
  modalData: ModalData;
  dialogData: DialogModel;
  eventsModel: EventInput[];
  currentDayOffSelect: DaySelected[] = [];
  specialDays: DayOffForView[] = [];
  modalShowData: HolidayData;
  holidayType: DropdownModel[];
  config = {
    ignoreBackdropClick: true
  };
  isEditHoliday: boolean;
  isFormHoliday: boolean;
  currentEventId: any;
  postData = new DayOffParent();

  nameSearch: string = '';
  // sort row data table
  sortName: string | null = null;
  sortValue: string | null = null;
  listWorkRemoteDays: Array<DayOffForView> = [];
  listOfDisplayWorkRemoteDays: Array<DayOffForView> = [];

  constructor(
    public router: Router,
    private leavingService: LeavingService,
    private hardcodeService: HardcodeService,
    private modal: BsModalService,
    private cd: ChangeDetectorRef,
    private dialogService: DialogService
  ) {
    this.hardcodeService
      .getHardcode(HARDCODE_CONST.dayOffOption)
      .subscribe(data => {
        this.dayoffOption = data;
      });
  }
  ngAfterViewInit() {
    let that = this;
    $(document).ready(function () {
      $(document).on('click', '.leving-detail-bt', (obj) => {
        that.updateWorkRemoteDay(obj.currentTarget.dataset.itemId, obj.currentTarget.dataset.itemStatus);
      });
      $(document).on('click', '.remove-dayoff-bt', (obj) => {
        that.deleteWorkRemoteDate(obj.currentTarget.dataset.itemId, obj.currentTarget.dataset.itemDate);
      });
    });
  }
  ngOnInit() {
    this.getSpecialDay();
    this.isAdmin = AuthService.isAdmin();
    this.options = {
      eventLimitText: 'yêu cầu',
      listDayFormat: true,
      listDayAltFormat: true,
      unselectAuto: false,
      displayEventEnd: true,
      selectMinDistance: 2,
      selectable: this.viewOptions.selectable,
      eventLimit: 1,
      editable: false,
      aspectRatio: this.aspectRatio,
      locale: viLocale,
      footer: this.viewOptions.footer,
      plugins: this.plugins,
      noEventsMessage: 'Không có nhân viên nào xin làm việc ở nhà!',
      buttonText: {
        listMonth: 'Danh sách tháng'
      },
      customButtons: {
        submitWorkRemoteDate: {
          text: 'Xin làm việc từ xa',
          click: () => this.onSubmitWorkRemoteDate()
        },
        viewListLeaving: {
          text: 'Danh sách',
          icon: 'mdi mdi mdi-format-line-style',
          click: () => this.onViewListWorkRemote()
        }
      },
      header: this.viewOptions.header
    };

    this.hardcodeService.getHardcode(HARDCODE_CONST.holidayType).subscribe(data => {
      this.holidayType = data;
    });
  }

  getWorkRemoteDays() {
    this.leavingService.getWorkRemoteDays().subscribe(leavings => {
      this.listWorkRemoteDays = leavings;
      this.listOfDisplayWorkRemoteDays = [...this.listWorkRemoteDays];
    });
  }

  onViewListWorkRemote() {
    this.isListView = !this.isListView;
    if (this.isListView) {
      this.getWorkRemoteDays();
    } else {
      this.getSpecialDay();
    }
  }

  get viewOptions(): any {
    let result = {
      selectable: true,
      footer: { center: 'submitWorkRemoteDate', right: 'viewHolidayDetail' } || false,
      header: { left: 'prev,next today', center: 'title', right: 'viewListLeaving' } || false
    };
    if (this.isAdmin) {
      result.footer = false;
      result.selectable = false;
      result.header = { left: 'prev,next today', center: 'title', right: 'viewListLeaving' };
    }
    return result;
  }

  get aspectRatio(): number {
    const currentWindowSize = window.innerWidth;
    if (currentWindowSize > 768) return 2.4;
    if (currentWindowSize > 413) return 0.8;
    if (currentWindowSize > 374) return 0.69;
    else return 0.575;
  }

  // view actions
  dateClick(model: DateClickApi) {
    if (this.detectIsAllow(model.date) && !this.isAdmin) {
      this.changeOption(model);
    }
  }

  isDayHasEvent(dateInput) {
    return this.specialDays.find(
      day => DatetimeUtils.toShortDateStandard(day.dateTime + "") === DatetimeUtils.toShortDateStandard(dateInput)
    );
  }

  eventClick(model: EventInput): any {
    const pros = model.event.extendedProps;
    this.currentEventId = pros.id;
    if (pros.type != SpecialDayType.Remote) {
      this.isEditHoliday = true;
      this.isFormHoliday = true;
      this.modalShowData = {
        titleDay: model.event.title,
        holidayType: pros.holidayType,
        holidayTypeOption: this.holidayType.find(p=>p.value === pros.holidayType),
        datetime: pros.datetime,
        date: model.event.start,
        option: pros.option,
        isMakeup: pros.isMakeup
      };
      this.modal.show(this.modalContentHoliday, this.config);
      return;
    }
    if (this.isAdmin) {
      if (
        model.event.extendedProps.dayOffStatus !== DayoffStatus.Approve &&
        !pros.isUrgent
      ) {
        this.genModalToApprove(model);
        return this.modal.show(this.dialogModal);
      }
    }
    this.genModelToview(model);
    return this.modal.show(this.modalContentOnlyView);
  }

  goToHoliday() {
    this.router.navigate(['/holiday-detail']);
  }

  onSubmitWorkRemoteDate() {
    const len = this.currentDayOffSelect.length;
    if (len) {
      this.currentDayOffSelect.sort(
        (pday, cday) => pday.date.getTime() - cday.date.getTime()
      );
      const start = this.currentDayOffSelect[0].date;
      const end = this.currentDayOffSelect[len - 1].date;
      this.modalData = this.genModalToSubmit(start, end);
      this.modal.show(this.modalContent);
    }
  }

  closeModal(): void {
    this.isFormHoliday = false;
    this.isEditHoliday = false;
    this.modal._hideModal(this.modal.getModalsCount());
    this.bodyHTML.classList.remove('modal-open');
  }

  // handle
  changeOption(model: DateClickApi): void {
    const findIs = day => model.date.toString() === day.date.toString();

    if (this.isDayHasEvent(model.date)) {

      return;
    }

    if (!this.currentDayOffSelect.find(day => findIs(day))) {

      this.currentDayOffSelect.push({

        date: model.date,

        option: DayOffOption.FullDay
      });

      model.dayEl.classList.add(this.ClassList[DayOffOption.FullDay] + '-day');

    } else {

      this.currentDayOffSelect = this.currentDayOffSelect.filter(day => {

        if (day.date.toString() === model.date.toString()) {

          model.dayEl.classList.remove(this.ClassList[day.option] + '-day');

          if (day.option === 1) {

            model.dayEl.classList.remove(this.ClassList[day.option] + '-day');

            return;
          }

        }

        return day;
      });
    }
  }

  updateView() {
    this.currentDayOffSelect = [];
    this.fullcalendar.getApi().destroy();
    this.fullcalendar.getApi().render();
    this.getSpecialDay();
  }

  toLeavingDay(
    day: Date,
    option: DayOffOption = DayOffOption.FullDay
  ): LeavingDay {
    return {
      date: day,
      datetime: DatetimeUtils.toShortDateTimeFormat(day.toString()),
      type: SpecialDayType.Remote,
      option: option
    };
  }

  genModalToSubmit(start: Date, end: Date = null): ModalData {
    return {
      reason: '',
      isurgent: false,
      start: start,
      end: end || start
    };
  }

  genModalToApprove(model) {
    const pros = model.event.extendedProps;
    this.dialogData = {
      title: 'Xác nhận ngày làm ở nhà',
      mainActionButtonText: 'Đồng ý',
      subActionButtonText: 'Từ chối',
      mainActionButton: () => {
        this.closeModal();
        this.updateLeaving({
          dayoffIds: [model.event.extendedProps.id],
          isApprove: true
        });
      },
      subActionButton: () => {
        this.closeModal();
        this.updateLeaving({
          dayoffIds: [model.event.extendedProps.id],
          isApprove: false
        });
      },
      content: `Nhân viên: ${pros.user.userName}`,
      HTML: `
        <h5>Lý do: ${model.event.title}</h5>
        <h5>Ngày làm từ xa: ${pros.date.split('-').join('/')}</h5>

      `,
      style: DialogStyles.Confirm,
      hint: '',
      typeOfInput: ''
    };
  }

  genModelToview(model) {
    const pros = model.event.extendedProps;
    this.modalData = {
      reason: model.event.title,
      start: model.event.start,
      userName: pros.user.userName,
      status: pros.isUrgent ? DayoffStatusName_Vi.Urgent : DayoffStatusName_Vi[DayoffStatus[pros.dayOffStatus]]
    };
  }

  detectIsAllow(date: Date): boolean {
    const notAllow = /\b(\w*Sun|Sat\w*)\b/g;
    const dayAllow = new Date();
    dayAllow.setDate(dayAllow.getDate() - 1);
    const isAreadly = e =>
      e.date === DatetimeUtils.toShortDateStandard(date.toString());
    if (date.toDateString().match(notAllow)) return false;
    if (date < dayAllow) return false;
    const event = this.eventsModel.find(e => isAreadly(e)) as EventInput;
    if (!event)
      return true;
    const pros = event.extendedProps as EventModel;
    if (event && pros.type != SpecialDayType.Remote) {
      return false;
    }
    return true;
  }

  submitWorkRemoteDate() {
    const dataToReq: DayToPost = {
      isurgent: this.modalData.isurgent,
      title: this.modalData.reason,
      specialdaymodels: this.currentDayOffSelect.map(day =>
        this.toLeavingDay(day.date, day.option)
      )
    };
    this.closeModal();
    this.leavingService.submitWorkRemoteDays(dataToReq).subscribe(res => {
      if (res) {
        this.currentDayOffSelect = [];
        this.updateView();
      }
    });
  }

  getSpecialDay(): void {
    this.leavingService.getWorkRemoteDays().subscribe(leavings => {
      this.specialDays = leavings;
      this.eventsModel = leavings.map<EventInput>(day => {
        let approveClass = '';
        if (!day['isUrgent']) {
          switch (day.dayOffStatus) {
            case DayoffStatus.Approve: {
              approveClass = 'approve-event';
              break;
            }
            case DayoffStatus.Waiting: {
              approveClass = 'waitting-event';
              break;
            }
            case DayoffStatus.Reject: {
              approveClass = 'reject-event';
              break;
            }
          }
        }
        return {
          title: day.reason,
          date: DatetimeUtils.toShortDateStandard(day.dateTime),
          extendedProps: {
            id: day['id'],
            option: day.option,
            isApprove: day.isApprove,
            dayOffStatus: day.dayOffStatus,
            isUrgent: day['isUrgent'],
            user: day.user,
            userId: day.userId,
            type: day.type,
            holidayType: day.holidayType,
            isMakeup: day.type == SpecialDayType.Makeup,
            date: DatetimeUtils.toShortDateStandard(day.dateTime)
              .split('-')
              .reverse()
              .join('-')
          },
          classNames: [
            this.ClassList[day.option],
            day['isUrgent'] ? 'urgent-event' : '',
            approveClass,
            this.HolidayClassList[day.holidayType],
            day.type == SpecialDayType.Makeup ? this.HolidayClassList[0] : ''
          ]
        };
      });
      this.cd.detectChanges();
    });
  }

  updateLeaving(model: DayOffToUpdate): void {
    this.leavingService.updateWorkRemote(model).subscribe(() => this.updateView());
  }

  onAddWorkRemoteDate() {
    const holiday = [new Holiday(this.modalShowData)];
    this.updateHolidays(holiday);
  }

  onDeleteHoliday() {
    const holiday = new Holiday(this.modalShowData);
    this.deleteHoliday(holiday);
  }

  updateHolidays(daysoff: Holiday[]) {
    this.postData.specialdayModels = daysoff;
    this.closeModal();
    this.leavingService.submitHolidays(this.postData).subscribe(() => {
      this.getSpecialDay();
    });
  }

  deleteHoliday(dayoff: Holiday) {
    this.closeModal();
    this.dialogService.confirm("Xóa", `Bạn có chắc bạn muốn xóa ngày làm việc từ xa này?`, () => {
      this.leavingService
        .deleteHoliday(dayoff).subscribe(() => {
          this.getSpecialDay();
          this.closeModal();
          this.cd.detectChanges();
          this.dialogService.message("Thông báo", `Bạn đã xóa ngày làm việc từ xa thành công!!!`, () => {
          }, "Đóng");
        });
    });
  }

  deleteWorkRemoteDate(id: number, datetime: Date) {
    this.closeModal();
    let model = new SpecialDayModel();
    model.dateTime = DatetimeUtils.toShortDateTimeFormat(datetime.toString());
    model.type = SpecialDayType.Remote;
    model.specialDayId = id;
    this.dialogService.confirm("Xóa", `Bạn có chắc bạn muốn xóa ngày làm việc từ xa này?`, () => {
      this.leavingService.deleteDayoff(model).subscribe(() => {

        if (this.isListView) {
          this.getWorkRemoteDays();
        } else {
          this.getSpecialDay();
        }
        this.cd.detectChanges();
        this.dialogService.message("Thông báo", `Bạn đã xóa ngày làm việc từ xa thành công!!!`, () => {
        }, "Đóng");
      });
    });
  }

  updateWorkRemoteDay(dayoffId: number, isApprove: boolean) {
    let updateRemoteModel = new UpdateDayOffModel();
    updateRemoteModel.dayoffIds.push(dayoffId);
    updateRemoteModel.isApprove = this.isBooleanTrue(isApprove);
    this.dialogService.confirm("Xác nhận", `Bạn có chắc bạn muốn ${this.isBooleanTrue(isApprove) ? 'chấp nhận' : 'từ chối'} yêu cầu làm việc từ xa này?`, () => {
      this.leavingService.updateWorkRemote(updateRemoteModel).subscribe(() => {
        updateRemoteModel = new UpdateDayOffModel();
        if (this.isListView) {
          this.getWorkRemoteDays();
        } else {
          this.getSpecialDay();
        }
        this.cd.detectChanges();
        this.dialogService.message("Thông báo", `Bạn đã ${this.isBooleanTrue(isApprove) ? 'chấp nhận' : 'từ chối'} yêu cầu làm việc từ xa này!!!`, () => {
        }, "Đóng");
      });
    });
  }

  sort(sort: { key: string; value: string }): void {
    this.sortName = sort.key;
    this.sortValue = sort.value;
    this.search();
  }

  search(): void {
    const filterFunc = (item: DayOffForView) => true;
    const data = this.listWorkRemoteDays.filter(item => filterFunc(item));
    if (this.sortName && this.sortValue) {
      this.listOfDisplayWorkRemoteDays = data.sort((a, b) =>
        this.sortValue === 'ascend'
          ? a[this.sortName!] > b[this.sortName!]
            ? 1
            : -1
          : b[this.sortName!] > a[this.sortName!]
            ? 1
            : -1
      );
    } else {
      this.listOfDisplayWorkRemoteDays = data;
    }
  }

  // search by name
  searchByName() {
    if (this.nameSearch && this.nameSearch.length > 0) {
      this.listOfDisplayWorkRemoteDays = this.listWorkRemoteDays.filter(dayoff => dayoff.user.userName.toLowerCase().includes(this.nameSearch.toLowerCase()))
    } else {
      this.listOfDisplayWorkRemoteDays = [...this.listWorkRemoteDays];
    }
  }

  isBooleanTrue(value) {

    if (!value) {
      return false;
    }

    return value.toString().trim().toLowerCase() == "true";

  }
}
