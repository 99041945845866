import { Component, OnInit, Inject, forwardRef } from '@angular/core';
import { SettingService } from 'src/app/services/setting.service';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap';
import { UploadType } from 'src/app/models/enums';

//
import { SettingModel,
  SystemCurrencyTokenModel,
  SystemFixedCostModel,
  SystemEmailRemindModel,
  SystemBirthdayTemplateConfig,
  SystemLogoBackgroundConfig,
  SettingForms,
  SystemRemindTemplateConfig,
  SystemSalaryTemplateConfig,
  SystemForgotPasswordTemplateConfig,
  CelebratorModel,
  CelebratorTemplateModel} from 'src/app/models/setting.model';
import { MainLayoutComponent } from '../main-layout/main-layout.component';
import { DialogService } from 'src/app/services/dialog.service';
import { PatternTemplateEnum } from 'src/app/models/enums';
import { DatetimeUtils } from 'src/app/utils/dateutil';

declare function initSummerNoteTemplate(id: string, content: string): any;
declare function initSummerNoteTemplateWithHeight(id: string, content: string, height: number): any;
declare function getContentFromSummerNote(id: string): string;

@Component({
    selector: 'setting',
    templateUrl: './setting.component.html',
    styleUrls: ['./setting.component.css']
})
export class SettingComponent implements OnInit {
    template = {
        birthdayTemplate: "#birthDayTemplate",
        salaryTemplate: "#salaryTemplate",
        remindTemplate: "#remindDayOffTemplate",
        celebratorEmailTemplate: '#celebratorEmailTemplate',
        forgotPasswordTemplate: "#forgotPasswordTemplate"
    };

    laborContract: string;
    laborContractSize: number;
    laborContractFile: File;

    subContract: string;
    subContractSize: number;
    subContractFile: File;

    probationaryContract: string;
    probationaryContractSize: number;
    probationaryContractFile;

    collaboratorContract: string;
    collaboratorContractSize: number;
    collaboratorContractFile: File;

    salary: string;
    salarySize: number;
    salaryFile;

    salaryBank: string;
    salaryBankSize: number;
    salaryBankFile;

    footer: string;
    documentTitle: string;
    logoInfo: string;
    logonLogoInfo: string;
    backgroundInfo: string;
    favIconInfo: string;
    favIcon;
    logo;
    logonLogo;
    background;

    model = new SettingModel();
    systemCurrencyTokenModel = new SystemCurrencyTokenModel();
    systemFixedCostModel = new SystemFixedCostModel();
    systemEmailRemindModel = new SystemEmailRemindModel();
    systemLogoBackgroundModel = new SystemLogoBackgroundConfig();

    systemBirthdayTemplateModel = new SystemBirthdayTemplateConfig();
    systemRemindTemplateModel = new SystemRemindTemplateConfig();
    systemSalaryTemplateModel = new SystemSalaryTemplateConfig();
    setupCompanyTemplateModel = new CelebratorTemplateModel();
    systemForgotPasswordTemplateModel = new SystemForgotPasswordTemplateConfig();

    patternTemplateTypes = PatternTemplateEnum;

    bsConfig: Partial<BsDatepickerConfig>;
    setupCompany: CelebratorModel = new CelebratorModel();

    constructor(private settingService: SettingService,
        private router: Router, private dialogService: DialogService,
        @Inject(forwardRef(() => MainLayoutComponent))
        private mainComm: MainLayoutComponent) {
    }

    ngOnInit() {
        this.bsConfig = Object.assign({}, {
          containerClass: 'theme-blue',
          dateInputFormat: 'DD/MM/YYYY'
        });

        this.laborContract = "LaborContract.docx";
        this.subContract = "SubContract.docx";
        this.probationaryContract = "ProbationaryContract.docx";
        this.collaboratorContract = "CollaboratorContract.docx";
        this.salary = "salary_net.xlsx";
        this.salaryBank = "salary_bank.xlsx";

        this.settingService.getSetting()
            .subscribe(data => {
                this.model = data;
                this.init(this.model);
            });
    }
    init(model: SettingModel) {
        this.systemCurrencyTokenModel.curencyExchange = model.curencyExchange;
        this.systemCurrencyTokenModel.dateExpireToken = model.dateExpireToken;

        this.systemFixedCostModel.tradeUnionFees = model.tradeUnionFees;

        this.systemEmailRemindModel.password = model.password;
        this.systemEmailRemindModel.port = model.port;
        this.systemEmailRemindModel.timeRemind = model.timeRemind;
        this.systemEmailRemindModel.userName = model.userName;
        this.systemEmailRemindModel.smtpHost = model.smtpHost;
        this.systemEmailRemindModel.secureConnection = model.secureConnection;
        this.systemEmailRemindModel.sendMailFrom = model.sendMailFrom;
        this.setupCompany.celebratorDate = DatetimeUtils.toShortDateTimeFormat(model.celebratorDate);

        this.logo = model.logo;
        this.logonLogo = model.logonLogo;
        this.background = model.backgroundImage;
        this.favIcon = model.favIcon;

        this.documentTitle = model.documentTitle;
        this.footer = model.footer;

        setTimeout(() => {
            initSummerNoteTemplate(this.template.birthdayTemplate, model.birthdayEmailTemplate);
            initSummerNoteTemplate(this.template.remindTemplate, model.remindEmailTemplate);
            initSummerNoteTemplate(this.template.salaryTemplate, model.salaryEmailTemplate);
            initSummerNoteTemplate(this.template.celebratorEmailTemplate, model.celebratorEmailTemplate);

            initSummerNoteTemplateWithHeight(this.template.forgotPasswordTemplate, model.forgotPasswordEmailTemplate, 458);
        });
    }
    uploadFiles() {
        let forms = new SettingForms();

        forms.uploadLaborContract = this.laborContractFile;
        forms.uploadSubContract = this.subContractFile;
        forms.uploadCollaboratorContract = this.collaboratorContractFile;
        forms.uploadSalaryTemplate = this.salaryFile;
        forms.uploadSalaryBankTemplate = this.salaryBankFile;
        forms.uploadedProbationaryContract = this.probationaryContractFile;

        this.settingService.UpdateSettingForms(forms)
            .subscribe((data) => {
                this.dialogService.message("Thông báo", "Upload tệp thành công !!!", () => {
                }, "Đóng");
            });

    }

    systemBirthdayTemplateSetting() {
        this.systemBirthdayTemplateModel.birthdayEmailTemplate = getContentFromSummerNote(this.template.birthdayTemplate);

        this.settingService.updateSystemBirthdayTemplateSetting(this.systemBirthdayTemplateModel)
            .subscribe(data => {
                this.dialogService.message("Thông báo", "Cập nhật mẫu email sinh nhật thành công !!!", () => {
                }, "Đóng");
            });
    }

    saveRemindDayOffTemplate() {
        this.systemRemindTemplateModel.remindEmailTemplate = getContentFromSummerNote(this.template.remindTemplate);

        this.settingService.updateRemindDayoffTemplateSetting(this.systemRemindTemplateModel)
            .subscribe(data => {
                this.dialogService.message("Thông báo", "Cập nhật mẫu email nhắc nhở ngày nghỉ thành công !!!", () => {
                }, "Đóng");
            });
    }

    saveSalaryTemplate() {
        this.systemSalaryTemplateModel.salaryEmailTemplate = getContentFromSummerNote(this.template.salaryTemplate);

        this.settingService.updateSalaryTemplateSetting(this.systemSalaryTemplateModel)
            .subscribe(data => {
                this.dialogService.message("Thông báo", "Cập nhật mẫu email bảng lương thành công !!!", () => {
                }, "Đóng");
            });
    }

    saveSetupCompanyTemplateSetting() {
      this.setupCompanyTemplateModel.celebratorEmailTemplate = getContentFromSummerNote(this.template.celebratorEmailTemplate);

      this.settingService.updateCelebratorCompanyTemplateSetting(this.setupCompanyTemplateModel)
          .subscribe(data => {
              this.dialogService.message("Thông báo", "Cập nhật mẫu email chúc mừng ngày thành lập công ty thành công! !!!", () => {
              }, "Đóng");
          });
    }

    saveForgotPasswordTemplate() {
        this.systemForgotPasswordTemplateModel.forgotPasswordTemplate = getContentFromSummerNote(this.template.forgotPasswordTemplate);

        this.settingService.updateForgotPasswordTemplateSetting(this.systemForgotPasswordTemplateModel)
            .subscribe(data => {
                this.dialogService.message("Thông báo", "Cập nhật mẫu email quên mật khẩu thành công !!!", () => {
                }, "Đóng");
            });
    }

    systemEmailRemindSetting(form: NgForm) {

        if (form.invalid) {
            return;
        }

        this.settingService.UpdateSystemEmailRemindSetting(this.systemEmailRemindModel)
            .subscribe(data => {
                this.dialogService.message("Thông báo", "Cập nhật email hệ thống và nhắc lịch thành công !!!", () => {
                    this.mainComm.screenReload();
                }, "Đóng");
            });
    }

    systemCurrencyTokenSetting(form: NgForm) {

        if (form.invalid) {
            return;
        }

        this.settingService.updateCurrencyTokenSetting(this.systemCurrencyTokenModel)
            .subscribe(data => {
                this.dialogService.message("Thông báo", "Cập nhật tỷ giá và Token thành công !!!", () => {
                    this.mainComm.screenReload();
                }, "Đóng");
            });
    }

    updateFixedCost(form: NgForm) {

        if (form.invalid) {
            return;
        }

        this.settingService.updateFixedCost(this.systemFixedCostModel)
            .subscribe(data => {
                this.dialogService.message("Thông báo", "Cập nhật các khoản phí cố định thành công !!!", () => {
                    this.mainComm.screenReload();
                }, "Đóng");
            });
    }

    updateSetupCompany(form: NgForm) {
      if (form.invalid) {
          return;
      }

      this.setupCompany.celebratorDate = DatetimeUtils.toShortDateTimeFormat(this.setupCompany.celebratorDate);

      this.settingService.updateCelebratorCompany(this.setupCompany)
          .subscribe(data => {
              this.dialogService.message("Thông báo", "Cập nhật ngày thành lập công ty thành công! !!!", () => {
                  this.mainComm.screenReload();
              }, "Đóng");
          });
    }

    systemLogoBackgroundSetting() {

        this.systemLogoBackgroundModel.documentTitle = this.documentTitle;
        this.systemLogoBackgroundModel.footer = this.footer;

        this.settingService.UpdateSystemLogoBackgroundSetting(this.systemLogoBackgroundModel)
            .subscribe(data => {
                this.dialogService.message("Thông báo", "Cập nhật Logo, ảnh nền và tiêu đề trang web thành công!!!", () => {
                    this.mainComm.screenReload();
                }, "Đóng");
            })
    }

    getFileUpload(event, type: UploadType): File {
        const filesUpload: File = event.target.files[0];
        const reader = new FileReader();

        switch(type){
            case UploadType.LogonLogo: 
                reader.onload = (e) => { this.logonLogo = reader.result; }
            break;
            case UploadType.Logo: 
                reader.onload = (e) => { this.logo = reader.result; }
            break;
            case UploadType.Background:
                reader.onload = (e) => { this.background = reader.result; }
            break;
        }

        reader.readAsDataURL(filesUpload);

        return filesUpload;
    }

    onChangeLogonLogo(event) {
        this.systemLogoBackgroundModel.uploadedLogonLogo = this.getFileUpload(event, UploadType.LogonLogo);
        this.logonLogoInfo = this.systemLogoBackgroundModel.uploadedLogonLogo.name.toString();
        this.systemLogoBackgroundModel.isLogonLogoDelete = false;
    }

    onChangeLogo(event) {
        this.systemLogoBackgroundModel.uploadedLogo = this.getFileUpload(event, UploadType.Logo);
        this.logoInfo = this.systemLogoBackgroundModel.uploadedLogo.name.toString();
        this.systemLogoBackgroundModel.isLogoDelete = false;
    }

    onChangeBackground(event) {
        this.systemLogoBackgroundModel.uploadedBackgound = this.getFileUpload(event, UploadType.Background);
        this.backgroundInfo = this.systemLogoBackgroundModel.uploadedBackgound.name.toString();
        this.systemLogoBackgroundModel.isBackgroundDelete = false;
    }

    onChangeLaborContract(event) {

        const filesUpload: File = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = (e) => { }

        reader.readAsDataURL(filesUpload);

        this.laborContractFile = filesUpload;
        this.laborContract = this.laborContractFile.name.toString();
        this.laborContractSize = this.laborContractFile.size / 1000;
    }

    onChangeSubContract(event) {

      const filesUpload: File = event.target.files[0];
      const reader = new FileReader();

      reader.onloadend = (e) => { }

      reader.readAsDataURL(filesUpload);

      this.subContractFile = filesUpload;
      this.subContract = this.subContractFile.name.toString();
      this.subContractSize = this.subContractFile.size / 1000;
    }

    onChangeProbationaryContract(event) {

        const filesUpload: File = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = (e) => { }

        reader.readAsDataURL(filesUpload);

        this.probationaryContractFile = filesUpload;
        this.probationaryContract = this.probationaryContractFile.name.toString();
        this.probationaryContractSize = this.probationaryContractFile.size / 1000;
    }

    onChangeCollaboratorContract(event) {

      const filesUpload: File = event.target.files[0];
      const reader = new FileReader();

      reader.onloadend = (e) => { }

      reader.readAsDataURL(filesUpload);

      this.collaboratorContractFile = filesUpload;
      this.collaboratorContract = this.collaboratorContractFile.name.toString();
      this.collaboratorContractSize = this.collaboratorContractFile.size / 1000;
    }

    onChangeSalary(event) {

        const filesUpload: File = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = (e) => { }

        reader.readAsDataURL(filesUpload);

        this.salaryFile = filesUpload;
        this.salary = this.salaryFile.name.toString();
        this.salarySize = this.salaryFile.size / 1000;

    }

    onChangeSalaryBank(event) {

        const filesUpload: File = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = (e) => { }

        reader.readAsDataURL(filesUpload);

        this.salaryBankFile = filesUpload;
        this.salaryBank = this.salaryBankFile.name.toString();
        this.salaryBankSize = this.salaryBankFile.size / 1000;
    }

    onChangeFavIcon(event) {

        const filesUpload: File = event.target.files[0];
        const reader = new FileReader();

        reader.onload = (e) => { this.favIcon = reader.result; }

        reader.readAsDataURL(filesUpload);

        this.systemLogoBackgroundModel.uploadedFavIcon = filesUpload;
        this.favIconInfo = this.systemLogoBackgroundModel.uploadedFavIcon.name.toString();
        this.systemLogoBackgroundModel.isFavIconDelete = false;
    }

    deleteFavIcon() {
        this.favIcon = "";
        this.systemLogoBackgroundModel.isFavIconDelete = true;
        this.favIconInfo = "";
    }

    deleteLogonLogo() {
        this.logonLogo = "";
        this.systemLogoBackgroundModel.isLogonLogoDelete = true;
        this.logonLogoInfo = "";
    }

    deleteLogo() {
        this.logo = "";
        this.systemLogoBackgroundModel.isLogoDelete = true;
        this.logoInfo = "";
    }

    deleteBackground() {
        this.background = "";
        this.systemLogoBackgroundModel.isBackgroundDelete = true;
        this.backgroundInfo = "";
    }
    downloadContract(parrternEnum: PatternTemplateEnum) {
        this.settingService.downloadPatternTemplate(parrternEnum);
    }
}
