import { Component, ViewChild, TemplateRef, OnInit } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { UserInfo } from 'src/app/models/userinfo.model';
import { DEFAULT_SETTINGS, HARDCODE_CONST } from 'src/app/app.constants';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { DatetimeUtils } from 'src/app/utils/dateutil';
import { ProjectService } from 'src/app/services/project.service';
import { UserIndexApiModel, UserIdModel } from 'src/app/models/user';
import { CashAdvanceStatus } from 'src/app/models/enums';
import { CashAdvanceApiModel, SalaryModel } from 'src/app/models/salary.model';
import { SalaryService } from 'src/app/services/salary.service';
import { GetSalaryModel } from 'src/app/models/getsalary.model';
import { HardcodeService } from 'src/app/services/hardcode.service';
import { DropdownModel } from 'src/app/models/dropdown.model';
import { DayOffForView } from 'src/app/models/leaving';
import { OverTimeModel } from 'src/app/models/overtime.model';
import { LeavingService } from 'src/app/services/leaving.service';
import { GetProjectModel, ProjectDetailModel } from 'src/app/models/project.models';
import { LocalService } from 'src/app/services/common/local.service';
import { BsModalService } from 'ngx-bootstrap';
import { NotificationService } from 'src/app/services/notify.service';
import { FORMAT_DATE_CONSTANT } from 'src/app/app.constants';

declare function initModule(element: string): any;
declare var $: any;
@Component({
  selector: 'user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {
  @ViewChild('modalContent') modalContent: TemplateRef<any>;
  user = new UserInfo();
  userId: number = 0;
  isSelfProfile: boolean = true;
  isAdmin: boolean;
  template: string;
  projects: ProjectDetailModel[] = [];
  userIndex: UserIndexApiModel = new UserIndexApiModel();
  listCashAdvance: CashAdvanceApiModel[] = [];
  salary = new SalaryModel();
  hardCodeSalaryType: DropdownModel[] = [];
  listRequestOff: DayOffForView[] = [];
  listOvertime: OverTimeModel[] = [];
  startDay = null;
  endDay = null;
  takeOffPermanent = false;
  formTakeOff = false;
  isDisabledStartDay = false;
  isDisabledEndDay = true;
  errorStartDay = null;
  errorEndDay = null;
  isHasTakeOff = false;
  formatDateConstant = FORMAT_DATE_CONSTANT.short_date_format;

  constructor(
    private userService: UserService,
    private projectService: ProjectService,
    private router: ActivatedRoute,
    private salaryService: SalaryService,
    private hardCodeService: HardcodeService,
    private leavingService: LeavingService,
    private modal: BsModalService,
    private notifyService: NotificationService,) { }

  ngOnInit() {
    this.isAdmin = AuthService.isAdmin();

    const id = Number.parseInt(this.router.snapshot.paramMap.get('id'));
    if (id) {
      this.userId = id;
      this.isSelfProfile = false;
    }
    this.getUsers();

    this.getUserIndex();

    this.getProjects();


    this.getCashAdvanceList(DatetimeUtils.toShortDateTimeFormat(new Date().toString()));

    if ((!this.isAdmin && !this.isSelfProfile) || (this.isAdmin && this.isSelfProfile))
      return;

    this.getSalary();

    this.getOvertimeList();

    this.getListRequestOff();
  }

  getLabourContract() {
    if (this.isAdmin) {
      this.userService.exportContractUser(this.userId);
    }else {
      this.userService.exportContract();
    }
  }

  getSalaryTypeHardCode() {
    this.hardCodeService.getHardcode(HARDCODE_CONST.salaryType).subscribe(data => {
      this.hardCodeSalaryType = data;
      this.initSalaryToolTip();

    });
  }

  getSalary() {
    let getSalaryModel = new GetSalaryModel();
    getSalaryModel.time = DatetimeUtils.toShortDateTimeFormat(new Date().toString());
    getSalaryModel.userId = this.userId;
    this.salaryService.getSalary(getSalaryModel)
      .subscribe(data => {
        this.salary = data;
        this.getSalaryTypeHardCode();
      });
  };
  
  showModalTakeOff(){
    this.modal.show(this.modalContent, {
      ignoreBackdropClick: true
    });
  }

  closeModalTakeOff(): void {
    this.modal.hide(1);
  }

  changedTakeOffPermanent(event){
    if(event){
      this.isDisabledEndDay = event;
      this.isDisabledStartDay = event;
      this.formTakeOff = event;
      this.user.startDateTakeOff = null;
      this.user.endDateTakeOff = null;
    }else{
      var today = new Date();
      this.isDisabledEndDay = event;
      this.isDisabledStartDay = event;
      this.formTakeOff = event;
      this.user.startDateTakeOff = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0).toString();
      this.user.endDateTakeOff = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0).toString();
    }
    this.errorEndDay = null;
    this.errorStartDay = null;
  }

  submitTakeOff(){
    let startDate = DatetimeUtils.toShortDateFormat(this.user.startDateTakeOff);
    let endDate = DatetimeUtils.toShortDateFormat(this.user.endDateTakeOff);
    this.user.startDateTakeOff = startDate;
    this.user.endDateTakeOff = endDate;
    this.userService.setUserTakeOff(this.user)
      .subscribe(data => {
        // location.reload();
        this.getUsers();
        this.closeModalTakeOff();
        if (data) {
          this.notifyService.success("Cho nhân viên tạm nghỉ thành công !!");
        }else{
          this.notifyService.warning("Cho nhân viên tạm nghỉ không thành công !!");
        }
      });
  }

  cancelTakeOff(){
    this.userService.cancelTakeOffUser(this.userId)
      .subscribe(data => {
        //location.reload();
        this.getUsers();
        this.closeModalTakeOff();
        this.isHasTakeOff = false;
        if (data) {
          this.notifyService.success("Hủy tạm nghỉ thành công !!");
        }else{
          this.notifyService.warning("Hủy tạm nghỉ không thành công !!");
        }
      });
  }

  checkDateTakeOff(){
    var today = new Date();
    var myToday = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
    if(myToday > new Date(this.user.startDateTakeOff)){
      this.errorStartDay = "*Không được chọn ngày quá khứ!"
      this.formTakeOff = false;
    }
    else{
      this.errorStartDay = null;
      this.isDisabledEndDay = false;
    }
    if(new Date(this.user.endDateTakeOff) <= new Date(this.user.startDateTakeOff)){
      this.errorEndDay = "*Chọn ngày lớn hơn ngày bắt đầu!"
      this.formTakeOff = false;
    }else if(myToday > new Date(this.user.startDateTakeOff)){
      this.formTakeOff = false;
    }
    else{
      this.errorEndDay = null;
      this.isDisabledEndDay = false;
      this.formTakeOff = true;
    }
  }

  initSalaryToolTip() {

    $('#tooltip-salary').tooltipster({
      content: $(`
      <div class="row">
      <div class="col-xs-6 col-md-6">
        <p>Lương cơ bản : ${this.salary.basicSalary.toLocaleString()} VNĐ</p>
        <p>Lương theo giờ : ${this.salary.otSalary.toLocaleString()} VNĐ</p>
        <p>Khấu trừ bản thân : ${this.salary.reduceYourself.toLocaleString()} VNĐ</p>
        <p>Loại lương : <span class="badge badge-success font-13">${this.hardCodeSalaryType[this.salary.salaryType - 1].name}</span></p>
      </div>
      <div class="col-xs-6 col-md-6">
        <p>Phí điện thoại : ${this.salary.telephoneFee.toLocaleString()} VNĐ</p>
        <p>Phí xăng xe : ${this.salary.petrolMoney.toLocaleString()} VNĐ</p>
        <p>Tiền ăn trưa : ${this.salary.lunchMoney.toLocaleString()} VNĐ</p>
        <p>Hỗ trợ tiền nhà : ${this.salary.housingSupport.toLocaleString()} VNĐ</p>
      </div>
    </div>`),
      minWidth: 600,
      maxWidth: 600,
      position: 'right'
    });
  }

  initTooltip() {

  }

  getUserIndex() {
    let userIdModel = new UserIdModel();
    userIdModel.isDeactive = false;
    userIdModel.userId = this.userId;
    this.userService.getUserIndex(userIdModel).subscribe(data => {
      this.userIndex = data;
    });
  }

  getProjects() {
    let getProjectModel: any;
    if (this.isSelfProfile)
      getProjectModel = new GetProjectModel(LocalService.getUserId());
      else
      getProjectModel = new GetProjectModel(this.userId);
    this.projectService.getProjects(getProjectModel)
      .subscribe(projects => {
        this.projects = projects;
      });
  }

  getCashAdvanceList(dateTime: string) {
    this.salaryService.getCashAdvanceList(dateTime, CashAdvanceStatus.None,  this.userId ).subscribe(data => {
      this.listCashAdvance = data;


    })
  }

  getUsers(): void {

    this.userService.getUserProfile(this.userId)
      .subscribe(apiUsers => {

        initModule(".tooltips");

        if (apiUsers) {
          this.user = apiUsers;
        }

        if (this.user.birthday) {
          this.user.birthday = DatetimeUtils.toShortDateFormat(this.user.birthday);
        }

        if (this.user.dateJoinCompany) {
          this.user.dateJoinCompany = DatetimeUtils.toShortDateFormat(this.user.dateJoinCompany);

        }

        if (this.user.idIssueDate) {
          this.user.idIssueDate = DatetimeUtils.toShortDateFormat(this.user.idIssueDate);
        }

        if (this.user.lastLogin) {
          this.user.lastLogin = DatetimeUtils.toDateFormatTwelveHours(this.user.lastLogin);
        }

        this.userService.GetTimeZones().subscribe((data: any[]) => {
          this.user.timeZoneName = data.find(p => p.id == this.user.timeZone)?.name || data.find(p=>p.value === DEFAULT_SETTINGS.time_zone_linux)?.name;
        })

        let start = DatetimeUtils.toShortDateFormat(this.user.startDateTakeOff);
        let end = DatetimeUtils.toShortDateFormat(this.user.endDateTakeOff);
        if(this.user.isTakeOffPermanent || start != end){
          this.isHasTakeOff = true;
        }

        if(this.user.isTakeOffPermanent){
          this.user.startDateTakeOff = null;
          this.user.endDateTakeOff = null;
          this.isDisabledEndDay = true;
          this.isDisabledStartDay = true;
        }else{
          if(start == end){
            this.isDisabledEndDay = true;
          }else{
            this.isDisabledEndDay = false;
          }
        }
      });
  }

  getListRequestOff() {
    this.leavingService.getLeavings(this.userId).subscribe(leaving => {
      this.listRequestOff = leaving;
    });
  }

  getOvertimeList() {
    this.userService.getOTList(this.userId, DatetimeUtils.toShortDateTimeFormat(new Date().toString()))
    .subscribe(apiOTList => {
      this.listOvertime = apiOTList;
    });
  }
}
