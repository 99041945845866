<label class="label" *ngIf="title">{{title}}<span *ngIf="required" class="required">*</span></label>
<div class="input-group">
  <input autocomplete="off" type="text" class="form-control" [bsConfig]="bsConfig" #dp="bsDatepicker"
         bsDatepicker placeholder="{{placeholder}}" [disabled]="disabled" [type]="type" [(ngModel)]="value"
         (ngModelChange)="updateChanges()" [class.parsley-error]="invalid()" (bsValueChange)="onValueChange($event)"
         readonly="readonly">
  <div class="input-group-prepend bg-primary border-primary" (click)="dp.toggle()"
       [attr.aria-expanded]="dp.isOpen">
    <span class="input-group-text bg-transparent">
    <i class="mdi mdi mdi-calendar-clock text-white"></i>
  </span>
  </div>
</div>
