import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { UntypedFormBuilder } from "@angular/forms";
import {debounceTime, distinct} from 'rxjs/operators';
import { LocalService } from "src/app/services/common/local.service";
import { DialogService } from "src/app/services/dialog.service";
import { PockerService } from "src/app/services/pocker.service";
import { RoomModel } from "../../../models/pocker.models";
import { GlobalService } from "src/app/services/global.service";
import { POCKER_ESTIMATE_DATA, APP_ROUTER } from "src/app/app.constants";
import { Subject } from "rxjs";
import { RealtimeClientService } from 'src/app/services/realtime.client.service';
import {NzModalService} from 'ng-zorro-antd/modal';
import {NzNotificationService} from 'ng-zorro-antd/notification';

@Component({
  selector: "room-detail",
  templateUrl: "./room-detail.component.html",
  styleUrls: ["./room-detail.component.css"],
})
export class RoomDetailComponent implements OnInit {
  description = "";
  descriptionChanged = new Subject<string>();

  room = new RoomModel();
  roomId: string;
  id: number;
  estimeData = POCKER_ESTIMATE_DATA;
  estimates = [];
  isOwner = false;
  updateEstimatessub: any;
  title: string;
  content: string;
  updateDescribeSubcription: any;
  userId: number;
  hourOfUser: number = 0;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public route: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    private pockerService: PockerService,
    private globalService: GlobalService,
    private modalService: NzModalService,
    private notification: NzNotificationService,
    private realtimeService: RealtimeClientService,
  ) { }

  ngOnInit() {
    this.id = +this.route.snapshot.params.id;
    this.getRoomDetails();

    this.updateEstimatessub = this.globalService.updateEstimates.subscribe(
      (data) => {
        if (data) {
          this.getEstimates(false);
        }
      })

    this.updateDescribeSubcription = this.globalService.updateDescription.subscribe(
      (data) => {
        if (data) {
          this.room.description = data;
        }
      })

    this.descriptionChanged
      .pipe(debounceTime(300))
      .pipe(distinct())
      .subscribe((value) => {
        this.pockerService
          .updateDescription(this.id, this.room.description)
          .subscribe(() => { });
      });


    this.userId = LocalService.getUserId();

  }

  ngOnDestroy() {
    this.updateEstimatessub.unsubscribe();
    this.updateDescribeSubcription.unsubscribe();
  }

  changeDesciption(value) {
    this.descriptionChanged.next(value);
  }

  getRoomDetails() {
    this.pockerService.getRoom(this.id).subscribe((data) => {
      if (data) {
        if (!data.isAccess) {
          this.modalService.error({
            nzTitle: "Cảnh Báo",
            nzContent: "Bạn không có quyền truy cập vào phòng này",
            nzOnOk: () => this.router.navigate([APP_ROUTER.room])
          });
        }
        else {
          this.room = data;
          this.isOwner = this.room.ownerId === LocalService.getUserId();

        }

      } else {
        this.modalService.error({
          nzTitle: "Cảnh Báo",
          nzContent: "Mã phòng không tồn tại hoặc đã bị xóa",
          nzOnOk: () => this.router.navigate([APP_ROUTER.room]),
        });
      }
    });
    this.getEstimates();
  }

  submitEstimate(value: number) {
    this.pockerService.estimate(this.room.id, value).subscribe();
  }

  getEstimates(loading = true) {
    this.pockerService.getEstimates(this.id, loading).subscribe((data) => {
      this.estimates = data;
      const currentUserEstimate = data.find(x => x.userId == this.userId);
      this.hourOfUser = currentUserEstimate ? currentUserEstimate.hour : 0;
    });
  }

  deleteEstimates() {
    this.modalService.confirm({
      nzTitle: "Thông Báo",
      nzContent: "Bạn có muốn xóa tất cả các ước tính?",
      nzOkText: "Đồng ý",
      nzOkType: "default", nzOkDanger: true,
      nzOnOk: () =>
        this.pockerService.deleteEstimates(this.id).subscribe(() => {
          this.estimates = [];
        }),
      nzCancelText: "Không",
    });
  }

  deleteRoom(): void {
    this.modalService.confirm({
      nzTitle: "Thông Báo",
      nzContent: "Bạn có muốn xóa room này không?",
      nzOkText: "Đồng ý",
      nzOkType: "default", nzOkDanger: true,
      nzOnOk: () =>
        this.pockerService.deleteRoom(this.id).subscribe(() => {
          this.router.navigate([APP_ROUTER.room]);
        }),
      nzCancelText: "Không",
    });
  }


  updateDescription() {
    this.pockerService.updateDescription(this.id, this.room.description).subscribe(data => {
      this.notification.create(
        'success',
        'Thành Công',
        'Cập nhật thành công'
      );
    });
  }

  copyToClipboard(text) {
    var input = document.createElement('input');
    input.setAttribute('value', text);
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);
    this.notification.create(
      'success',
      'Thành Công',
      'Đã sao chép'
    );
  }

  exitRoom(): void {
    this.modalService.confirm({
      nzTitle: "Thông Báo",
      nzContent: "Bạn có muốn thoát room này không?",
      nzOkText: "Đồng ý",
      nzOkType: "default", nzOkDanger: true,
      nzOnOk: () =>
        this.pockerService.exitRoom(this.id).subscribe(data => {
          if (data) {
            this.notification.create(
              'success',
              'Thành Công',
              'Đã thoát Room'
            );
          }
          else {
            this.notification.create(
              'error',
              'Lỗi',
              'Thao tác không thành công'
            );
          }
          let urlBack = APP_ROUTER.scrum_pocker + '/' + this.room.projectId;
          this.router.navigate([urlBack]);
        }),
      nzCancelText: "Không",
    });
  }

}
