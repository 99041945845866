import { Component, OnInit } from '@angular/core';
import { DepartmentService } from 'src/app/services/department.service';
import { AuthService } from 'src/app/services/auth.service';
import { UntypedFormControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DialogService } from 'src/app/services/dialog.service';
import { debounceTime, tap } from 'rxjs/operators';
import { DepartmentDetailModel, GetDepartmentModel } from '../../../models/department.models';
import { DepartmentStatus, DepartmentSearchBy } from '../../../models/enums';
declare var $: any;
@Component({
  selector: 'list-department',
  templateUrl: './list-department.component.html',
  styleUrls: ['./list-department.component.css']
})
export class ListDepartmentComponent implements OnInit {

  departmentName = new UntypedFormControl();
  departments: DepartmentDetailModel[];
  originList: DepartmentDetailModel[];
  isLoadMore = false;
  deptForm: UntypedFormGroup = this.formBuilder.group({
    departmentName: this.departmentName
  });
  isAdmin: boolean;

  constructor(private formBuilder: UntypedFormBuilder, private departmentService: DepartmentService, private dialogService: DialogService) { }

  ngOnInit() {

    this.isAdmin = AuthService.isAdmin();
    this.departments = [];

    this.departmentName.valueChanges.pipe(debounceTime(1000), tap())
      .subscribe(res => this.departmentService.getDepartments(res)
        .subscribe(departments => {
          this.originList = departments;
          this.departments = this.originList && this.originList.length >= 6 ? this.originList.slice(0, 6) : this.originList;
        }));

    this.getDepartments();

    this.sortListDepartment(DepartmentSearchBy.DepartmentName);

  }

  getDepartments() {
    this.departmentService.getDepartments(new GetDepartmentModel())
      .subscribe(departments => {
        this.originList = departments;
        this.departments = this.originList && this.originList.length >= 6 ? this.originList.slice(0, 6) : this.originList;
      });
  }

  getDepartmentByStatus(statusCode: number) {
    if (!this.originList) return;
    switch (statusCode.toString()) {
      case DepartmentStatus.All:
        if (this.originList.length >= 6)
          this.departments = this.isLoadMore ? this.originList : this.originList.slice(0, 6);
        else
          this.departments = this.originList;
        break;
      default:
        if (this.originList.length >= 6)
          this.departments = this.isLoadMore ? this.originList.filter(p => p.status == statusCode) : this.originList.filter(p => p.status == statusCode).slice(0, 6);
        else
          this.departments = this.originList.filter(p => p.status == statusCode);
        break;
    }
  }

  sortListDepartment(sortMethod: string) {
    switch (sortMethod) {
      case DepartmentSearchBy.DepartmentName:
        this.departments = this.departments.sort((a, b) => {
          if (a.departmentName.toLowerCase() > b.departmentName.toLowerCase()) { return 1; }
          if (a.departmentName.toLowerCase() < b.departmentName.toLowerCase()) { return -1; }
          return 0;
        });
        break;
      case DepartmentSearchBy.Status:
        this.departments = this.departments.sort((a, b) => {
          if (a.statusModel.name.toLowerCase() > b.statusModel.name.toLowerCase()) { return 1; }
          if (a.statusModel.name.toLowerCase() < b.statusModel.name.toLowerCase()) { return -1; }
          return 0;
        });
        break;
      default:
        break;
    }
  }

  loadMoreDepartments() {
    this.isLoadMore = true;

    let elPharseSelect = document.getElementById("phase-select");
    $(elPharseSelect).val(0);

    let elSortSelect = document.getElementById("sort-select");
    $(elSortSelect).val("byDepartmentName");

    this.departments = this.originList;

  }

  deleteDepartment(id: number) {
    this.dialogService.confirm("Xóa phòng ban", "Bạn có chắc muốn xóa phòng ban này?", () => {
      this.departmentService.deleteDepartment(id)
        .subscribe(data => {
          if (data)
            this.getDepartments();
        });
    });
  }

}
