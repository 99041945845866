export class ReportCreateModel {
    projectId: number;
    userId: number;
    reportId: number;
    content: string;
    successfully: number;
}

export class ReportGetModel {
    month: string;
    userId: number;
}

export class ReportReturnModel {
    id: number;
    created: Date;
    modified: Date;
    content: string;
    successfully: number;
    projectId: number;
    projectName: string;
}