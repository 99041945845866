import { BaseService } from './common/base.service';
import { Injectable } from '@angular/core';
import { ErrorService } from './common/error.service';
import { GlobalService } from './global.service';
import { HttpClient } from '@angular/common/http';
import { GetSalaryModel } from '../models/getsalary.model';
import { CurencyExchangeModel, CashAdvanceModel, UpdateCashAdvanceModel } from '../models/salary.model';
import { API_ENDPOINT } from '../app.constants';
import { CashAdvanceStatus } from '../models/enums';
@Injectable({
    providedIn: 'root'
})
export class SalaryService extends BaseService {
    constructor(protected http: HttpClient,
        protected errorHandler: ErrorService,
        protected globalService: GlobalService) {
        super(http, errorHandler, globalService);

    }
    getSalary(data: GetSalaryModel) {
        return this.get('/Salary/GetSalary', data)
    }

    updateCurrencyExchange(data: CurencyExchangeModel) {
        return this.post('/Salary/UpdateCurencyExchange', data);
    }
    lockSalary(ids: number[] = []) {
        return this.get('/Salary/ApproveSalaries', { ids }, true);
    }
    getMaxCashAdvance() {
        return this.get(API_ENDPOINT.get_max_cash_advance, null, true);
    }
    getCashAdvanceList(datetime:string, status: CashAdvanceStatus, userId : number = 0) {
        return this.get(`${API_ENDPOINT.get_cash_advance_list}?status=${status}&dateTime=${datetime}&userId=${userId}`, null, true);
    }
    submitCashAdvance(model: CashAdvanceModel) {
        return this.post(API_ENDPOINT.submit_cash_advance, model, true);
    }
    cancelRequestCashAdvance(id: number) {
        return this.postFormData(API_ENDPOINT.cancel_request_cash_advance, { id }, true);
    }
    updateCashAdvance(model: UpdateCashAdvanceModel) {
        return this.post(API_ENDPOINT.update_cash_advance, model, true);
    }
    getCashAdvanceByStatus(dateTime: string, status: number) {
        return this.get(`${API_ENDPOINT.get_cash_advance_list}?dateTime=${dateTime}&status=${status}`, null, true);
    }
}
