import { Component, OnInit } from '@angular/core';
import { DropdownModel } from '../../../models/dropdown.model';
import { ActivatedRoute } from '@angular/router';
import { ProjectService } from '../../../services/project.service';
import { ProjectDetailModel, GetProjectModel } from '../../../models/project.models';
import { LocalService } from 'src/app/services/common/local.service';

@Component({
    selector: 'app-view-project',
    templateUrl: './view-project.component.html',
    styleUrls: ['./view-project.component.css']
})
export class ViewProjectComponent implements OnInit {
    model = new ProjectDetailModel();
    status: DropdownModel[];
    projectId: number = 0;
    getModel = new GetProjectModel();
    constructor(private route: ActivatedRoute,
        private projectService: ProjectService) {
    }

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.projectId = +params['id'];
        });

        this.getModel = new GetProjectModel(LocalService.getUserId(), this.projectId);

        this.projectService.getProjects(this.getModel)
            .subscribe(project => {
                this.model = project[0];
            });
    }
}
