import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DialogService } from 'src/app/services/dialog.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ChangePasscode } from 'src/app/models/changepasscode.model';
import { ForgotPasscodeService } from 'src/app/services/forgotpasscode.service';
import { SettingService } from 'src/app/services/setting.service';
@Component({
  selector: 'app-change-passcode',
  templateUrl: './reset-passcode.component.html',
  styleUrls: ['./reset-passcode.component.css']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

  model = new ChangePasscode();
  token: string;
  background: any;

  constructor(private forgotPascodeService: ForgotPasscodeService, 
    private dialogService: DialogService, 
    private route: ActivatedRoute, 
    private router: Router,
    private settingService: SettingService) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params['token']) {
        this.token = params['token'];
      }
    });

    this.settingService.getBackground()
    .subscribe(data => {
      if (data) {
        this.background = data;
      }
    })
  }

  submit(form: NgForm) {

    if (form.invalid) {
      return;
    }

    this.forgotPascodeService.newPasscodeSubmit(this.model.newPassCode, this.token)
      .subscribe(res => {
        this.dialogService.message("Thông báo", "Đặt lại mật khẩu thành công!!", () => {
          this.router.navigate(['/login']);
        }, "Xong");
      });
  }

  ngOnDestroy(): void {
  }

}
