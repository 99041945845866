import { Component, OnInit, forwardRef, Inject, OnDestroy } from '@angular/core';
import { MainLayoutComponent } from '../main-layout/main-layout.component';
import { GlobalService } from 'src/app/services/global.service';
import { Subscription } from 'rxjs';
import { AlertGroups } from 'src/app/models/enums';
import { NotifyModel, NotificationsIdsModel, NotificationsModel } from 'src/app/models/notify.model';
import { LocalService } from 'src/app/services/common/local.service';
import { DropdownModel } from 'src/app/models/dropdown.model';
import { HardcodeService } from 'src/app/services/hardcode.service';
import { HARDCODE_CONST } from 'src/app/app.constants';

@Component({
  selector: 'app-notify-list',
  templateUrl: './notify-list.component.html',
  styleUrls: ['./notify-list.component.css']
})
export class NotifyListComponent implements OnInit, OnDestroy {

  notifyComing: Subscription;
  notifyListComing: Subscription;
  notifications: NotifyModel[] = [];
  allNotification: NotificationsModel;
  userId: number;
  countReadNotifiesFromChosenList: number = 0;
  numberRecordInOnePage: number = 10;
  currentPage: number = 1;
  totalNotification: number = 0;
  currentFrom: number = 0;
  currentTo: number = 0;
  alertGroup : AlertGroups = AlertGroups.All;
  alertGroups: DropdownModel[];
  defaulAlertGroup: DropdownModel;

  constructor(private hardcodeService: HardcodeService,
    @Inject(forwardRef(() => MainLayoutComponent)) private mainComp: MainLayoutComponent,
    private globalService: GlobalService) {
  }

  ngOnInit() {
    this.userId = LocalService.getUserId();

    this.notifyComing = this.globalService.notifyComming
      .subscribe(() => {

      });

    this.notifyListComing = this.globalService.notifyList
      .subscribe(notify => {
        if(notify)
        {
          this.allNotification = notify;
          this.notifications = notify.notifyMessageApis;
          this.totalNotification = notify.total;
          this.currentFrom = (this.currentPage-1)*10 +1;
          this.currentTo = this.currentPage*10;
          if(this.currentTo > this.totalNotification)
            this.currentTo = this.totalNotification;
        }
      })

    this.pagingNotifies();

    this.hardcodeService.getHardcode(HARDCODE_CONST.alertGroups)
    .subscribe(data => {
      this.alertGroups = data;
      this.defaulAlertGroup = this.alertGroups.find(x=>x.value === 7);
    });

  }

  pagingNotifies() {
    this.mainComp.getNotifications(this.currentPage, this.numberRecordInOnePage, this.alertGroup);
  }
  pagingNext() {
    if(this.currentPage*this.numberRecordInOnePage  < this.totalNotification)
       this.currentPage += 1;
    else
       return;

    this.pagingNotifies();
  }
  pagingPrev() {
    if(this.currentPage > 1)
       this.currentPage -= 1;
    else
       return;

    this.pagingNotifies();
  }

  showReadNotifies() {
    this.currentPage = 1;
    this.alertGroup = AlertGroups.AllRead;
    this.pagingNotifies();
  }

  showUnreadNotifies() {
    this.currentPage = 1;
    this.alertGroup = AlertGroups.AllUnread;
    this.pagingNotifies();
  }

  onChangeNotifi(data :any) {
    this.currentPage = 1;
    this.alertGroup = data.value;
    this.pagingNotifies();
  }

  getSelectedNotifies(): number[] {
    let notifyIds = [];
    this.notifications.forEach(notify => {
      if (notify.isChecked) {
        notify.isChecked = false;
        notifyIds.push(notify.id);
      }
      if (notify.isRead) {
        this.countReadNotifiesFromChosenList += 1;
      }
    });
    return notifyIds;
  }

  markAsUnReadOrUnreadNotifies(isRead: boolean) {
    let model = new NotificationsIdsModel(this.getSelectedNotifies(), this.userId, isRead);

    if (isRead)
      this.mainComp.markAsUnReadOrReadNotifies(model, this.countReadNotifiesFromChosenList, this.currentPage, this.numberRecordInOnePage, this.alertGroup);
    else
      this.mainComp.markAsUnReadOrReadNotifies(model, this.countReadNotifiesFromChosenList - model.listIds.length, this.currentPage, this.numberRecordInOnePage, this.alertGroup );
  }

  notifyOnClick(id: number) {
    this.mainComp.notifyOnClick(id);
  }

  removeNotifies() {
    let model = new NotificationsIdsModel(this.getSelectedNotifies(), this.userId, true);
    this.mainComp.removeNotifies(model);
    this.pagingNotifies();
  }

  ngOnDestroy(): void {
    this.notifyComing.unsubscribe();
    this.notifyListComing.unsubscribe();

  }

}
