<div class="row">
  <div class="col-12 grid-margin">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">{{projectDetail.projectName}} </h4>

        <div class="text-center mt-4">
          <div class="row">
            <div class="col-md-6 col-xl-3">
              <div class="card-box card-normal widget-flat border-custom bg-custom text-white">
                <i class="fi-clock"></i>
                <h3 class="m-b-10">{{projectDetail.totalTimeWorkMonth}} giờ</h3>
                <p class="text-uppercase m-b-5 font-13 font-600" title="Tổng số giờ tăng ca trong tháng">Tổng số giờ đã làm trong tháng</p>
              </div>
            </div>
            <div class="col-md-6 col-xl-3">
              <div class="card-box card-normal bg-primary widget-flat border-primary text-white">
                <i class="fi-head"></i>
                <h3 class="m-b-10">{{projectDetail.totalTimeWorkProject}} giờ</h3>
                <p class="text-uppercase m-b-5 font-13 font-600" title="Tổng số giờ đã làm trong tháng">Tổng số giờ đã làm trong dự án</p>
              </div>
            </div>
            <div class="col-md-6 col-xl-3">
              <div class="card-box card-normal widget-flat border-success bg-success text-white">
                <i class="fi-bar-graph"></i>
                <h3 class="m-b-10">{{projectDetail.totalSalaryMonth | appNumber}} VNĐ</h3>
                <p class="text-uppercase m-b-5 font-13 font-600" title="Tổng số tiền tăng ca trong tháng">Tổng số tiền tăng ca trong tháng</p>
              </div>
            </div>
            <div class="col-md-6 col-xl-3">
              <div class="card-box card-normal bg-danger widget-flat border-danger text-white">
                <i class="fi-paper"></i>
                <h3 class="m-b-10" title="{{0 | appNumber}} VNĐ">{{projectDetail.totalSalaryProject | appNumber}} VNĐ</h3>
                <p class="text-uppercase m-b-5 font-13 font-600"  title="Tổng số tiền tăng ca trong dự án" >Tổng số tiền tăng ca trong dự án</p>
              </div>
            </div>
          </div>
        </div>    
        
        <div class="d-flex justify-content-end mb-3">
          <span class="d-flex align-items-center mr-2">
            Tìm: &nbsp;
            <app-text-box type="text"
                          [placeholder]="'Nội dung..'"
                          [(ngModel)]="descriptionSearch"
                          (ngModelChange)="searchByDescription()">
            </app-text-box>
          </span>
          <button type="button" class="btn btn-light ml-2" (click)="updateMonth('previous')">
            <i class="mdi mdi-arrow-left-bold"></i>
          </button>
          <button type="button" class="btn btn-light mr-1" (click)="updateMonth('next')">
            <i class="mdi mdi-arrow-right-bold"></i>
          </button>
          <button type="button" class="btn btn-custom" (click)="updateMonth('today')">
            Hôm nay
          </button>
        </div>

        <nz-divider nzText="Tăng ca trong tháng  {{date | date: 'MM/yyyy'}}" nzOrientation="left"></nz-divider>


        <div class="">
          <nz-table
            #filterTable
            nzShowPagination
            nzShowSizeChanger
            [nzData]="listOfDisplayOTs"
            nzPageSize="50">
            <thead (nzSortChange)="sort($event)" nzSingleSort>
              <tr>
                <th nzWidth="60px"></th>

                <th nzShowSort nzSortKey="user.userId">
                  Mã NV
                </th>
                <th nzShowSort nzSortKey="user.userName">
                  Nhân viên
                </th>
                <th nzShowSort nzSortKey="workTime">
                  Số giờ
                </th>
                <th nzShowSort nzSortKey="TotalHourSalary">
                  Tổng tiền tăng ca
                </th>
              </tr>
            </thead>

            <tbody>
              <ng-container *ngFor="let emp of filterTable.data">
              <tr>
                <td [nzExpand]="expandSet.has(emp.userId)" (nzExpandChange)="onExpandChange(emp.userId, $event)"></td>              
                <td>
                  {{emp.userId}}
                </td>
                <td>
                  <app-shared-image [srcImg]="emp.userImage | image"
                                    [classImg]="'rounded-circle img-cover'"
                                    [altImg]="'image'"
                                    [height]="48"
                                    [width]="48">
                  </app-shared-image>
                  <label class="ml-2">{{emp.userName}}</label>
                </td>
                <td>
                  {{emp.totalTime}}
                </td>
                <td>
                  {{emp.totalSalaryOT | appNumber}} VNĐ
                </td>       
              </tr>
              <tr [nzExpand]="expandSet.has(emp.userId)">
                <ng-container>
                  <table class="table table-data">
                  <tbody>
                    <tr class="tr-child-table">
                      <th style="width: 5%;"></th>
                      <th style="width: 20%;">Thời gian bắt đầu</th>
                      <th style="width: 20%;">Thời gian kết thúc</th>
                      <th style="width: 10%;">Chế độ</th>
                      <th style="width: 5%;">Số giờ</th>
                      <th style="width: 35%;">Nội dung</th>
                    </tr>
                    <ng-container *ngFor="let overtime of emp.overtimeItems">
                      <tr>
                        <td style="width: 5%;"></td>
                        <td style="width: 20%;">{{overtime.timeStart | date: 'dd/MM/yyyy, h:mm a'}}</td>
                        <td style="width: 20%;">{{overtime.timeFinish | date: 'dd/MM/yyyy, h:mm a'}}</td>
                        <td style="width: 10%;">{{overtime.rateType}}</td>
                        <td style="width: 5%;">{{overtime.workTime}}</td>
                        <td class="description-table" style="width: 35%;" >{{overtime.description}}</td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </ng-container>
              </tr>
            </ng-container>
            </tbody>
          </nz-table>
        </div>
      </div>
    </div>
  </div>
</div>
