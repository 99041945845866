import { UserIdsModel } from './user';
import { UserNameModel } from './overtime.model';

export class CreateRewardModel {
    constructor() {
        this.userIds = new UserIdsModel();
    }
    id: number;

    template: string;

    subject: string;

    isSendReward: boolean;

    value: number;

    userIds: UserIdsModel;
}

export class RewardResponseModel {
    constructor(){
        this.users = [];
    }
    id: number;

    template: string;

    subject: string;

    isSendReward: boolean;

    value: number;

    users: UserNameModel[];

    created: Date;
}