import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { UntypedFormControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DialogService } from 'src/app/services/dialog.service';
import { GetCustomerModel, CustomerDetailModel } from '../../../models/customer.models';
import { CustomerService } from 'src/app/services/customer.service';
declare var $: any;
@Component({
  selector: 'list-customer',
  templateUrl: './list-customer.component.html',
  styleUrls: ['./list-customer.component.css']
})
export class ListCustomerComponent implements OnInit {
  projectName = new UntypedFormControl();
  customers: CustomerDetailModel[];
  originList: CustomerDetailModel[];
  isAdmin = false;
  pageIndex: number;
  totalCustomer: number;
  statusCode: number;
  limit: number;

  constructor(private formBuilder: UntypedFormBuilder, private customerService: CustomerService, private dialogService: DialogService) { }

  ngOnInit() {

    this.isAdmin = AuthService.isAdmin();

    this.customers = [];

    this.getCustomers();

    this.pageIndex = 1;
    this.totalCustomer = 0;
    this.statusCode = 0;
    this.limit = 9;
  }

  isAllowModify():boolean {
    return this.isAdmin;
  }

  getCustomers() {
    this.customerService.getCustomers(new GetCustomerModel())
      .subscribe(customers => {
        this.totalCustomer = customers.length;
        this.originList = customers.sort((a, b) => (a.customerId?.toLowerCase() > b.customerId?.toLowerCase()) ?  1 : -1);
        this.customers = !this.isAdmin ? this.originList : (this.originList && this.originList.length >= this.limit ? this.originList.slice(0, this.limit) : this.originList);
        
      });
  }

  onPageIndexChange($event) {
    var start = ($event - 1) * this.limit;
    var end = $event * this.limit;
    this.pageIndex = $event;
    this.customers = this.statusCode == 0 ? this.originList.slice(start, end) : this.originList.filter(p => p.status == this.statusCode).slice(start, end);
  }

  onPageSizeChange(size: number): void {
    var start = (this.pageIndex - 1) * size;
    var end = this.pageIndex * size;
    this.limit = size;
    this.customers = this.statusCode == 0 ? this.originList.slice(start, end) : this.originList.filter(p => p.status == this.statusCode).slice(start, end);
  }

  deleteCustomer(id: number) {
    this.dialogService.confirm("Xóa khách hàng", "Bạn có chắc muốn xóa khách hàng này?", () => {
        this.customerService.deleteCustomer(id)
          .subscribe(data => {
            if (data)
              this.getCustomers();
          });
    });
  }
}
