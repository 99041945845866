<div class="row">
  <div class="col-12 grid-margin">
    <div class="card">
      <div class="card-body">
        <full-calendar *ngIf="options && !isListView" #fullcalendar (dateClick)="dateClick($event)"
          (eventClick)="eventClick($event)" [events]="eventsModel" [eventLimitText]="options.eventLimitText"
          [editable]="options.editable" [eventLimit]="options.eventLimit" [listDayAltFormat]="options.listDayAltFormat"
          [noEventsMessage]="options.noEventsMessage" [header]="options.header" [aspectRatio]="options.aspectRatio"
          [plugins]="options.plugins" [footer]="options.footer" [customButtons]="options.customButtons"
          [locale]="options.locale" [buttonText]="options.buttonText">
        </full-calendar>
        <div *ngIf="isListView">
          <button type="button" class="fc-viewListLeaving-button fc-button fc-button-primary float-right"
            (click)="onViewListWorkRemote()"><i class="mdi mdi-calendar-today"></i></button>
          <h4 class="card-title mb-4">Danh sách xin làm việc ở nhà</h4>

          <span class="d-flex align-items-center justify-content-end mr-0 mb-3">
            Tìm: &nbsp;
            <app-text-box type="text" [placeholder]="'nội dung..'" [(ngModel)]="nameSearch"
              (ngModelChange)="searchByName()">
            </app-text-box>
          </span>

          <div class="table-data">
            <nz-table #filterTable nzShowPagination nzShowSizeChanger [nzData]="listOfDisplayWorkRemoteDays" nzPageSize="50">
              <thead (nzSortChange)="sort($event)" nzSingleSort>
                <tr>
                  <th nzShowSort nzSortKey="user.userName">
                    Nhân viên
                  </th>
                  <th nzShowSort nzSortKey="reason">
                    Lý do
                  </th>
                  <th nzShowSort nzSortKey="dateTime">
                    Ngày làm việc từ xa
                  </th>
                  <th nzShowSort nzSortKey="dayOffStatus">
                    Trạng thái
                  </th>
                  <th>
                    Tác vụ
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let dayoff of filterTable.data">
                  <td>
                    <app-shared-image [srcImg]="dayoff.user.userImage | image" [classImg]="'rounded-circle img-cover'"
                      [altImg]="'image'" [height]="48" [width]="48">
                    </app-shared-image>
                    <label class="ml-2">{{dayoff.user.userName}}</label>
                  </td>
                  <td>
                    {{dayoff.reason}}
                  </td>
                  <td>
                    {{dayoff.dateTime | date: 'dd/MM/yyyy'}}
                  </td>
                  <td>
                    <span *ngIf="dayoff.dayOffStatus == 1" class="badge badge-success font-13">Đã chấp nhận</span>
                    <span *ngIf="dayoff.dayOffStatus == 3" class="badge badge-danger font-13">Đã từ chối</span>
                    <span *ngIf="dayoff.dayOffStatus == 2" class="badge badge-warning font-13">Chờ xác nhận</span>
                  </td>
                  <td>
                    <button [attr.data-item-id]="dayoff.id" [attr.data-item-status]="true"
                      *ngIf="isAdmin&&dayoff.dayOffStatus == 2" type="button"
                      class="leving-detail-bt btn btn-icon btn-sm waves-effect waves-light btn-custom mr-2"
                      title="Chấp nhận"> <i class="fa fa-check"></i>
                    </button>
                    <button [attr.data-item-id]="dayoff.id" [attr.data-item-status]="false"
                      *ngIf="isAdmin&&dayoff.dayOffStatus == 2" type="button"
                      class="leving-detail-bt btn btn-icon btn-sm waves-effect waves-light btn-danger" title="Từ chối">
                      <i class="fa fa-times"></i>
                    </button>
                    <button [attr.data-item-id]="dayoff.id" [attr.data-item-date]="dayoff.dateTime" *ngIf="!isAdmin"
                      title="Xóa" class="remove-dayoff-bt btn btn-icon btn-sm waves-effect waves-light btn-danger"
                      type="button">
                      <i class="mdi mdi-delete-circle"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </nz-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #modalContent let-close="close">
  <div class="modal-header">
    <h5 class="modal-title">Xin làm việc từ xa</h5>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form #form="ngForm" (ngSubmit)="submitWorkRemoteDate()" autocomplete="off">
    <div class="modal-body">
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <app-text-box class="form-group" title="Lý do" type="text" name="title" required="true" [disabled]="isAdmin"
              [(ngModel)]="modalData.reason">
            </app-text-box>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="modal-footer">
    <button class="btn btn-custom save-event waves-effect waves-light" (click)="submitWorkRemoteDate()" type="button"
      [disabled]="!form.valid">Xác
      nhận</button>
    <button type="button" class="btn btn-light waves-effect" data-dismiss="modal" (click)="closeModal()">Đóng</button>
  </div>
</ng-template>

<ng-template #modalContentOnlyView let-close="close">
  <div class="modal-header">
    <h5 class="modal-title">Thông tin ngày làm việc từ xa</h5>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form #formView="ngForm" autocomplete="off">
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <app-text-box class="form-group" title="Nhân viên" type="text" name="name" disabled
              [(ngModel)]="modalData.userName">
            </app-text-box>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="form-group">
            <app-text-box class="form-group" title="Lý do" type="text" name="title" disabled
              [(ngModel)]="modalData.reason">
            </app-text-box>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="form-group">
            <label>Ngày làm việc từ xa</label>
            <input class="form-control" disabled type="text" value="{{ modalData.start | date:'dd/MM/yyyy' }}">
          </div>
        </div>
        
        <div class="col-sm-12">
          <div class="form-group">
            <app-text-box class="form-group" title="Trạng thái" type="text" name="start" disabled
              [ngModel]="modalData.status">
            </app-text-box>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button *ngIf="!isAdmin" class="btn btn-danger save-event waves-effect waves-light"
      (click)="deleteWorkRemoteDate(currentEventId,modalData.start)" type="button">Xóa</button>
    <button type="button" class="btn btn-light waves-effect" data-dismiss="modal" (click)="closeModal()">Đóng</button>
  </div>
</ng-template>

<ng-template #modalContentHoliday let-close="close">
  <div class="modal-header">
    <h5 class="modal-title">{{"Xem ngày làm việc từ xa"}}</h5>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form #form="ngForm" (ngSubmit)="onAddWorkRemoteDate()" autocomplete="off">
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <app-text-box class="form-group" title="Tiêu đề" type="text" name="title" required="true"
              [disabled]="!isAdmin" [(ngModel)]="modalShowData.titleDay">
            </app-text-box>
          </div>
        </div>
        <div *ngIf="!modalShowData.isMakeup" class="col-sm-12">
          <div class="form-group">
            <label>Ngày nghỉ</label>
            <input class="form-control" disabled type="text" value="{{ modalShowData.date | date:'dd/MM/yyyy' }}">
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button *ngIf="isAdmin" class="btn btn-success save-event waves-effect waves-light" (click)="onAddWorkRemoteDate()"
      type="button" [disabled]="!form.valid">Xác
      nhận</button>
    <button *ngIf="isEditHoliday && isAdmin" class="btn btn-danger delete-event waves-effect waves-light" type="button"
      (click)="onDeleteHoliday()" [disabled]="!form.valid">Xóa</button>
    <button type="button" class="btn btn-light waves-effect" data-dismiss="modal" (click)="closeModal()">Đóng</button>
  </div>
</ng-template>

<ng-template #dialogModal let-close="close">
  <app-confirm-dialog [model]="dialogData"></app-confirm-dialog>
</ng-template>
