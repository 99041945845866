import { DEFAULT_SETTINGS, FORMAT_DATE_CONSTANT } from '../app.constants';
import { DatetimeUtils } from '../utils';
import { DropdownModel } from './dropdown.model';
import { CurrencyType, Gender, SpecialDayType } from './enums';
export class UserShortInfo {
  id: number;
  role: number;
  avatar: string;
  fullName: string;
  gender: Gender;
  isInterns: boolean;
  specialDayType: SpecialDayType;
  dayGoBack: string;
  isDeactive: boolean;
  isChecked: boolean;
  isLeader: boolean;
  isLockedSalary: boolean;
  userCode: string;
  birthday: Date;
  dateJoinCompany: Date;
  roleId: number;
  isStatusTakeOff: boolean;
  currencyType: CurrencyType;
};

export class UserIdentity {
  userIdentity: string;
  idIssueDate: string;
  idIssuePlace: string;
  isDeleteIdFrontImage: boolean;
  isDeleteIdBackImage: boolean;
  idFrontImageUpload: File;
  idBackImageUpload: File;
}

export class UserInfo {
  constructor() {
    this.roleModel = new DropdownModel();
    this.genderModel = new DropdownModel();
    this.currencyTypeModel = new DropdownModel();
    this.dateJoinCompany = DatetimeUtils.toDateStandard((new Date).toString(), FORMAT_DATE_CONSTANT.date_format_mm_dd_yyyy);
    this.birthday = DatetimeUtils.toDateStandard((new Date).toString(), FORMAT_DATE_CONSTANT.date_format_mm_dd_yyyy);
    this.timeZone = DEFAULT_SETTINGS.time_zone;
  }
  userId: number;
  userCode: string;
  avatar: string;
  email: string;
  fullName: string;
  userName: string;
  idIssueDate: string;
  idFrontImage: string;
  idBackImage: string;
  idIssuePlace: string;
  userIdentity: string;
  phone: string;
  skypeId: string;
  linkedId: string;
  facebookId: string;
  bankAccount: string;
  BankName: string;
  BankBeneficiary: string;
  BankBranch: string;
  genderOption: DropdownModel;
  gender: number;
  salaryTypeModel: DropdownModel;
  salaryTypeOption: DropdownModel;
  salaryType: number;
  genderModel: DropdownModel;
  address: string;
  role: number;
  roleOption: DropdownModel
  roleModel: DropdownModel;
  isinterns: boolean;
  birthday: string;
  uploadedFile: File;
  dateJoinCompany: string;
  hourSalary: number = 0;
  basicSalary: number = 0;
  lunchMoney: number = 0;
  telephoneFee: number = 0;
  petrolMoney: number = 0;
  numberOfDependents: number = 0;
  housingSupport: number = 0;
  reduceYourself: number = 0;
  realSalary: number = 0;
  salaryPerformance: number;
  timeZone: string;
  timeZoneOption: DropdownModel;
  timeZoneName: string;
  isLockedSalary: boolean = false;
  workingTimeCounting: string;
  lastLogin: string;
  startDateTakeOff: string;
  endDateTakeOff: string;
  isTakeOffPermanent: boolean;  
  userSalaryCustomer: string;
  customerUserSalary: CustomerUserSalaryModel;
  currencyType: CurrencyType;
  currencyTypeModel: DropdownModel;
  currencyTypeOption: DropdownModel;
  currencyBasicType: CurrencyType;
  currencyBasicTypeOption: DropdownModel;
  isResetContract: boolean;
}

export class EmployeesInformation {
  totalEmployee = 0;
  employeeOffInDay = 0;
  employeeOffInWeek = 0;
  employeeOffInMonth = 0;
}

export class UserSalaryCustomer {
    customerModel: DropdownModel;
    hourSalaryCustomer: number;
    isIncludePIT: boolean;
    id: number;
}

export class UserSalaryCustomerData {
  customerId: DropdownModel;
  hourSalary: number;
  isIncludePIT: boolean;
  id: number;
}

export class CustomerUserSalaryModel {
  customerId: number;
  hourSalary: number;
  isIncludePIT: boolean;
  id: number;
}
