import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { UserInfo } from 'src/app/models/userinfo.model';
import { DEFAULT_SETTINGS, HARDCODE_CONST, FORMAT_DATE_CONSTANT } from 'src/app/app.constants';
import { HardcodeService } from 'src/app/services/hardcode.service';
import { DropdownModel } from 'src/app/models/dropdown.model';
import { NotificationService } from 'src/app/services/notify.service';
import { BsDatepickerConfig } from 'ngx-bootstrap';
import { DatetimeUtils } from 'src/app/utils/dateutil';
import { NotificationHelper } from 'src/app/utils';

@Component({
    selector: 'add-user',
    templateUrl: './add-user.component.html',
    styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {

    imageInfo: string;
    model = new UserInfo();
    role: DropdownModel[];
    gender: DropdownModel[];
    currencyType: DropdownModel[];
    salaryType: DropdownModel[];
    maritalstatus: DropdownModel[];
    bsConfig: Partial<BsDatepickerConfig>;
    timeZones: DropdownModel[];
    dateJoinCompany = '';

    timeZoneDefault: string;
    roleTypeDefault: DropdownModel;
    genderTypeDefault: DropdownModel;
    salaryTypeDefault: DropdownModel;
    currencyTypeDefault: DropdownModel;

    constructor(
        private userService: UserService,
        private hardcodeService: HardcodeService,
        private router: Router,
        private notifyService: NotificationService,
        private notify: NotificationHelper
    ) {
    }
    ngOnInit() {
        // this.notify = new NotificationHelper(this.notifyService);

        this.bsConfig = Object.assign({}, {
            containerClass: 'theme-blue',
            dateInputFormat: 'DD/MM/YYYY'
        });
        this.hardcodeService.getHardcode(HARDCODE_CONST.userRole)
            .subscribe(data => {
                this.role = data;
                this.roleTypeDefault = this.role.find(p=>p.value === 3);
                this.model.roleOption = this.role.find(p=>p.value === this.model.role);
            });

        this.hardcodeService.getHardcode(HARDCODE_CONST.gender)
            .subscribe(data => {
                this.gender = data;
                this.genderTypeDefault = this.gender.find(p=>p.value === 1);
                this.model.genderOption = this.gender.find(p=>p.value === this.model.gender);
            });

        this.hardcodeService.getHardcode(HARDCODE_CONST.salaryType)
            .subscribe(data => {
                this.salaryType = data;
                this.salaryTypeDefault = this.salaryType.find(p=>p.value === 1);
                this.model.salaryTypeOption = this.salaryType.find(p=>p.value === this.model.salaryType);
            });

        this.userService.GetTimeZones().subscribe(data => {
            if (data) {
                this.timeZones = data.map(q => {
                    return new DropdownModel(q.name, q.id);
                });
                this.model.timeZoneOption = this.timeZones.find(p=>p.value === this.model.timeZone) || this.timeZones.find(p=>p.value === DEFAULT_SETTINGS.time_zone_linux);
            }
        });

        this.userService.getNewUserId().subscribe(data => {
            if (data) {
                this.model.userCode = data.data;
            }
        });
    }
    onChange(event) {
        if (event.target.files && event.target.files[0]) {
            if (this.notify.isValidationSizeUpload(event.target.files[0])) {
                const filesUpload: File = event.target.files[0];
                this.model.uploadedFile = filesUpload;
                this.imageInfo = filesUpload.name.toString();
            }
        }
    }

    addUser(form: NgForm) {

        if (form.invalid) {
            return;
        }
        if (!this.model.birthday) {
            this.model.birthday = DatetimeUtils.toShortDateTimeFormat(Date.now().toString());
        }
        else {
            if (Date.parse(this.model.birthday) > Date.now()) {
                this.notifyService.error("Nhập lại ngày sinh!!!");
                return;
            }

            if (this.model.birthday !== (DatetimeUtils.toShortDateFormat(Date.now().toString()) + ' 00:00:00')) {
              console.log(this.model.birthday)
              console.log(DatetimeUtils.toShortDateFormat(Date.now().toString()))
              this.model.birthday = DatetimeUtils.toShortDateTimeFormat(this.model.birthday);
            }
        }

        if (!this.dateJoinCompany) {
            this.model.dateJoinCompany = DatetimeUtils.toShortDateTimeFormat(Date.now().toString());
        }
        else {
            this.model.dateJoinCompany = DatetimeUtils.toShortDateTimeFormat(this.dateJoinCompany);
        }

        this.model.timeZone = this.model.timeZoneOption.value;
        this.model.salaryType = this.model.salaryTypeOption.value;
        this.model.gender = this.model.genderOption.value;
        this.model.role = this.model.roleOption.value;

        this.userService.addUser(this.model)
            .subscribe(data => {
                if (data) {
                    this.router.navigate(['/users']);
                }
            });
    }
}
