<div class="row">

    <!-- Right Sidebar -->
    <div class="col-lg-12">
        <div class="card-box">
            <!-- Left sidebar -->
            <div class="inbox-leftbar">

                <h6 class="m-b-15 font-16">Loại thông báo</h6>

                <div class="list-group b-0 mail-list">
                    <a class="list-group-item border-0"><span class="fa fa-circle text-info mr-2"></span>Tin
                        nhắn</a>
                    <a class="list-group-item border-0"><span class="fa fa-circle text-warning mr-2"></span>Tài
                        khoản</a>
                    <a class="list-group-item border-0"><span class="fa fa-circle text-purple mr-2"></span>Dự
                        án</a>
                    <a class="list-group-item border-0"><span class="fa fa-circle text-pink mr-2"></span>Ngày
                        nghỉ</a>
                    <a class="list-group-item border-0"><span class="fa fa-circle text-success mr-2"></span>Tăng 
                        ca</a>
                    <a class="list-group-item border-0"><span class="fa fa-circle text-primary mr-2"></span>Sinh
                        nhật</a>
                    <a class="list-group-item border-0"><span class="fa fa-circle text-secondary mr-2"></span>Tạm
                        ứng</a>
                </div>

            </div>
            <!-- End Left sidebar -->

            <div class="inbox-rightbar">
                <div class="" role="toolbar">
                    <div class="btn-group">
                        <button type="button" class="btn btn-sm btn-light waves-effect" (click)="markAsUnReadOrUnreadNotifies(false)" title="Đánh dấu chưa đọc"><i
                                class="mdi mdi-email-variant font-18 vertical-middle"></i></button>
                        <button type="button" class="btn btn-sm btn-light waves-effect" (click)="markAsUnReadOrUnreadNotifies(true)" title="Đánh dấu đã đọc"><i
                                class="mdi mdi mdi-email-open-outline font-18 vertical-middle"></i></button>
                        <button type="button" class="btn btn-sm btn-light waves-effect" (click)="removeNotifies()" title="Xóa thông báo đã chọn"><i
                                class="mdi mdi-delete-variant font-18 vertical-middle"></i></button>
                    </div>
                    <div class="float-right">
                        <app-dropdown-list [defaultValue]="defaulAlertGroup" (onChangeCallBack)= "onChangeNotifi($event)" class="app-text" name="alertGroups" [data]="alertGroups">
                        </app-dropdown-list>
                    </div>
                </div>

                <div class="message-list-height">
                    <div class="mt-4">
                        <div class="">
                            <ul class="message-list" *ngFor="let notify of notifications">
                                <li class="{{notify.isRead ? '' : 'unread'}}" title="{{notify.message}}">
                                    <div class="col-mail col-mail-1">
                                        <div class="checkbox-wrapper-mail">
                                            <input type="checkbox" id="chk{{notify.id}}" [(ngModel)]="notify.isChecked"
                                            [ngModelOptions]="{standalone: true}">
                                            <label for="chk{{notify.id}}" class="toggle"></label>
                                        </div>
                                        <span *ngIf="notify.alertGroups == 0" class="fa fa-circle text-info"></span>
                                        <span *ngIf="notify.alertGroups == 1" class="fa fa-circle text-warning"></span>
                                        <span *ngIf="notify.alertGroups == 2" class="fa fa-circle text-purple"></span>
                                        <span *ngIf="notify.alertGroups == 3" class="fa fa-circle text-pink"></span>
                                        <span *ngIf="notify.alertGroups == 4" class="fa fa-circle text-success"></span>
                                        <span *ngIf="notify.alertGroups == 5" class="fa fa-circle text-primary"></span>
                                        <span *ngIf="notify.alertGroups == 6" class="fa fa-circle text-secondary"></span>
                                    </div>
                                    <div class="col-mail col-mail-2">
                                        <a class="subject pointer" (click)="notifyOnClick(notify.id)">{{notify.message}}</a>
                                        <div class="date">{{notify.pushedTime | date: 'dd/MM, h:mm a'}}</div>
                                    </div>
                                </li>
                            </ul>
                            <div *ngIf="notifications.length == 0" class="row">
                                <p class="text-muted font-18">Hiện tại không có thông báo nào !!!</p>
                              </div>
                        </div>

                    </div> <!-- card body -->
                </div> <!-- card -->    

                <div *ngIf = "notifications.length != 0" class="row">
                    <div class="col-7">
                        Đang xem {{currentFrom}} đến {{currentTo}} trong tổng số {{totalNotification}}
                    </div>
                    <div class="col-5">
                        <div class="btn-group float-right">
                            <button  (click)="pagingPrev()" type="button" class="btn btn-custom waves-light waves-effect btn-sm"><i
                                    class="fa fa-chevron-left"></i></button>
                            <span class="pagging">
                                {{currentPage}}
                            </span>
                            <button (click)="pagingNext()" type="button" class="btn btn-custom waves-effect waves-light btn-sm"><i
                                    class="fa fa-chevron-right"></i></button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="clearfix"></div>
        </div>

    </div> <!-- end Col -->

</div>