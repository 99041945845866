<div class="row">
    <div class="select-datetime">
        <span class="mb-2">
          Tháng {{date | date: 'MM/yyyy'}}
        </span>
        <div class="text-dark font-18 font-600 mb-2">
          <button type="button" class="btn btn-light" (click)="updateMonth('previous')">
            <i class="mdi mdi-arrow-left-bold"></i>
          </button>
          <button type="button" class="btn btn-light mr-1" (click)="updateMonth('next')">
            <i class="mdi mdi-arrow-right-bold"></i>
          </button>
          <button type="button" class="btn btn-custom" (click)="updateMonth('today')">
            Hôm nay
          </button>
        </div>
      </div>
    <div class="col-12">

        <div class="card-box">
            <div class="text-center">
                <div class="row">

                    <div class="col-md-6 col-xl-3">
                        <div class="card-box card-normal bg-primary widget-flat border-primary text-white">
                            <i class="fi-stack-2"></i>
                            <h3 class="m-b-10">{{model.totalProject}}</h3>
                            <p class="text-uppercase m-b-5 font-13 font-600">Dự án</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-xl-3">
                        <div class="card-box card-normal widget-flat border-success bg-success text-white">
                            <i class="fi-marquee-plus"></i>
                            <h3 class="m-b-10">{{model.totalInsurrance | appNumber}} VNĐ</h3>
                            <p class="text-uppercase m-b-5 font-13 font-600">Tiền bảo hiểm trong tháng</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-xl-3">
                        <div class="card-box card-normal bg-danger widget-flat border-danger text-white">
                            <i class="fi-clock"></i>
                            <h3 class="m-b-10">{{model.totalOverTime | appNumber}} VNĐ</h3>
                            <p class="text-uppercase font-13 font-600">Tiền làm thêm trong tháng</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-xl-3">
                        <div class="card-box card-normal widget-flat border-custom bg-custom text-white">
                            <i class="fi-paper"></i>
                            <h3 class="m-b-10">{{model.totalTax| appNumber}} VNĐ</h3>
                            <p class="text-uppercase m-b-5 font-13 font-600">Tiền thuế trong tháng</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

<div class="row">
    <div class="col-md-6 col-lg-4">
        <div class="card-box ribbon-box">
            <div class="ribbon ribbon-custom">Danh sách xin nghỉ phép</div>
            <div class="clearfix"></div>
            <div class="inbox-widget  slimscroll" *ngIf="listRequestOff.length > 0">
                <div class="inbox-item " *ngFor="let request of listRequestOff" title="Lí do xin nghỉ: {{request.reason}}">
                    <div class="inbox-item-img">
                      <app-shared-image [srcImg]="request.userImage | image"
                                  [classImg]="'rounded-circle'"
                                  [altImg]="'image'">
                      </app-shared-image>
                    </div>
                    <p class="inbox-item-author">{{request.userName}}</p>
                    <p class="inbox-item-text">Xin nghỉ <span class="text-dark font-500">
                        {{request.option == 1 ? "cả ngày" : (request.option==2 ? "buổi sáng ngày" : "buổi chiều ngày")}}
                        {{request.dateTime | date: 'dd/MM/yyyy'}}</span></p>
                    <p class="inbox-item-date m-t-10">
                        <button type="button" class="btn btn-icon btn-sm waves-effect waves-light btn-custom mr-2"
                            title="Chấp nhận" (click)="updateDayoff(request.dayOffId, true)"> <i class="fa fa-check"></i>
                        </button>
                        <button type="button" class="btn btn-icon btn-sm waves-effect waves-light btn-danger"
                            title="Từ chối" (click)="updateDayoff(request.dayOffId, false)"> <i class="fa fa-times"></i>
                        </button>
                    </p>
                </div>

            </div>
            <div class="inbox-widget" *ngIf="listRequestOff.length == 0">
                <div class="inbox-item">

                    <p class="inbox-item-text">Không có nhân viên nào xin nghỉ phép.</p>

                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6 col-lg-4">
        <div class="card-box ribbon-box">
            <div class="ribbon ribbon-info">Danh sách tạm ứng lương</div>
            <div class="clearfix"></div>
            <div class="inbox-widget  slimscroll" *ngIf="listCashadvance.length > 0">
                <div class="inbox-item" *ngFor="let cash of listCashadvance" title="Lí do ứng lương: {{cash.reason}}">
                    <div class="inbox-item-img">
                      <app-shared-image [srcImg]="cash.userImage | image"
                                  [classImg]="'rounded-circle'"
                                  [altImg]="'image'">
                      </app-shared-image>
                    </div>
                    <p class="inbox-item-author">{{cash.userName}}</p>
                    <p class="inbox-item-text">Tạm ứng <span class="font-500 text-dark">{{cash.cash | appNumber}}</span> VNĐ</p>
                    <p class="inbox-item-date m-t-10">
                        <button type="button" class="btn btn-icon btn-sm waves-effect waves-light btn-custom mr-2"
                            title="Chấp nhận" (click)="updateCashAdvance(cash.userId, cash.id, true)"> <i class="fa fa-check"></i>
                        </button>
                        <button type="button" class="btn btn-icon btn-sm waves-effect waves-light btn-danger"
                            title="Từ chối" (click)="updateCashAdvance(cash.userId, cash.id, false)"> <i class="fa fa-times"></i>
                        </button>
                    </p>
                </div>

            </div>
            <div class="inbox-widget" *ngIf="listCashadvance.length == 0">
                <div class="inbox-item">

                    <p class="inbox-item-text">Không có nhân viên nào xin tạm ứng lương.</p>

                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6 col-lg-4">
        <div class="card-box ribbon-box">
            <div class="ribbon ribbon-danger">Sinh nhật trong tháng</div>
            <div class="clearfix"></div>
            <div class="inbox-widget  slimscroll" *ngIf="listUserBirthday.length > 0">
                <div class="inbox-item" *ngFor="let user of listUserBirthday">
                    <div class="inbox-item-img">
                      <app-shared-image [srcImg]="user.avatar | image"
                                  [classImg]="'rounded-circle'"
                                  [altImg]="'image'">
                      </app-shared-image>
                    </div>
                    <p class="inbox-item-author">{{user.fullName}}</p>
                    <p class="inbox-item-text">Sinh nhật ngày {{user.birthday | date: 'dd/MM/yyyy'}}</p>
                    <p class="inbox-item-date m-t-10">
                        <i class="mdi mdi-cake-variant mdi-24px"></i>
                    </p>
                </div>
            </div>
            <div class="inbox-widget" *ngIf="listUserBirthday.length == 0">
                <div class="inbox-item">

                    <p class="inbox-item-text">Không có nhân viên nào có sinh nhật trong tháng này.</p>

                </div>
            </div>
        </div>
    </div>
</div>
