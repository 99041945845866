import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DropdownModel } from 'src/app/models/dropdown.model';
import { GetProjectModel, ProjectDetailModel } from 'src/app/models/project.models';
import { ReportCreateModel } from 'src/app/models/report.model';
import { LocalService } from 'src/app/services/common/local.service';
import { ProjectService } from 'src/app/services/project.service';
import { ReportService } from 'src/app/services/report.service';
declare var $: any;
declare function initSummerNoteTemplate(id:string,content: string): any;
declare function saveFormInputValue(): string;

@Component({
  selector: 'app-edit-daily-report',
  templateUrl: './edit-daily-report.component.html',
  styleUrls: ['./edit-daily-report.component.css']
})
export class EditDailyReportComponent implements OnInit {

  reportId: number = 0;
  isAdd = true;
  model: ReportCreateModel = new ReportCreateModel();
  projects: DropdownModel[] = [];

  projectOption: DropdownModel;
  projectId: number = 0;
  currDate: Date = new Date();

  constructor(
    private route: ActivatedRoute,
    private reportService: ReportService,
    private projectService: ProjectService,
    private router: Router,
    private cd: ChangeDetectorRef
    ) { }

  ngOnInit() {
    this.getReportId();
  }

  getReportId() {
    this.route.params.subscribe(params => {
      if (params['id']) {
        this.reportId = +params['id'];
        this.isAdd = false;
        this.reportService.getReportById(this.reportId).subscribe((res)=> {
          this.model = res;
          this.projectId = this.model.projectId;
          initSummerNoteTemplate("#summernote", this.model.content);
          this.initRangeSlider();
          this.getProjects();

        })
      }else {
        initSummerNoteTemplate("#summernote", "");
        this.getProjects();
      }
    })
  }

  initRangeSlider() {
    $("#completionRate").ionRangeSlider({
      grid: false,
      from: this.model.successfully / 10,
      values: ["0%","10%","20%","30%","40%","50%","60%","70%","80%","90%","100%"]
  });
  }

  getProjects() {
    let prModel = new GetProjectModel(LocalService.getUserId());

    this.projectService.getProjects(prModel)
      .subscribe(projects => {
        this.convertToDropDownModel(projects)
      });
  }

  convertToDropDownModel(data: ProjectDetailModel[]) {
    data.forEach(element => {
      this.projects.push(new DropdownModel(element.projectName, element.id));
      if (element.id === this.projectId) {
        this.projectOption = new DropdownModel(element.projectName, element.id);
        console.log(this.projectOption);
      }
    });
    this.cd.detectChanges();
  }


  addReport() {
    this.model.projectId = this.projectOption.value;
    this.model.content = saveFormInputValue();
    this.reportService.createReport(this.model).subscribe((res)=> {
      this.router.navigate(['/daily-report']);
    })
  }

  editReport() {
    this.model.reportId = this.reportId;
    this.model.projectId = this.projectOption.value;
    this.model.content = saveFormInputValue();
    this.model.successfully = +($("#completionRate").val().split('%')[0]);
    this.reportService.editReport(this.model).subscribe((res)=> {
      this.router.navigate(['/daily-report']);
    })
  }
}
