import { DropdownModel } from './dropdown.model';
import { UserShortInfo } from './userinfo.model';

export class DepartmentModel {
    constructor() {
    }
    id: number;
    departmentName: string;
    statusModel: DropdownModel;
    status: string;
    description: string;
    usersInfo: UserShortInfo[];
    departmentLogo: string;
}

export class DepartmentDetailModel {
    constructor() {
        this.statusModel = new DropdownModel();
        this.owner = new UserShortInfo();
      this.usersInfo = [];
    }
    id: number;
    departmentName: string;
    status: number;
    statusModel: DropdownModel;
    description: string;
    departmentLogo: string;
    owner: UserShortInfo;
    usersInfo: UserShortInfo[];
    //managerId: number;
    isChecked: boolean;
}

export class GetDepartmentModel {
  constructor(userId = 0, departmentId = 0, departmentName = "", roleId = 0) {
        this.roleId = roleId;
        this.userId = userId;
        this.departmentId = departmentId;
        this.departmentName = departmentName;
    }
    roleId: number;
    userId: number;
    departmentId: number;
    departmentName: string;
}
