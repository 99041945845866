import { BaseService } from './common/base.service';
import { Injectable } from '@angular/core';
import { ErrorService } from './common/error.service';
import { GlobalService } from './global.service';
import { HttpClient } from '@angular/common/http';
import { CreateRewardModel } from '../models/reward.model';
import { API_ENDPOINT } from '../app.constants';
import { RewardStatus } from '../models/enums';

@Injectable({
    providedIn: 'root'
})
export class RewardService extends BaseService {
    constructor(protected http: HttpClient,
        protected errorHandler: ErrorService,
        protected globalService: GlobalService) {
        super(http, errorHandler, globalService);
  }
  createOrSendReward(model: CreateRewardModel){
      return this.post(API_ENDPOINT.create_reward, model, false);
  }
  getRewardTemplate(status: RewardStatus, year: number){
    return this.get(`${API_ENDPOINT.get_reward}?status=${status}&year=${year}`, null, true);
  }
  deleteRewardTemplate(rewardId: number){
    return this.get(`${API_ENDPOINT.delete_reward}?rewardId=${rewardId}`, null, true);
  }
  getRewardTemplateById(rewardId: number){
    return this.get(`${API_ENDPOINT.get_reward_by_id}?rewardId=${rewardId}`, null, true);
  }
}