import { Component, OnInit } from '@angular/core';
import { SettingService } from '../../services/setting.service';
import { Background } from 'src/app/models/background.model';

@Component({
  selector: 'app-maintainance',
  templateUrl: './maintainance.component.html',
  styleUrls: ['./maintainance.component.css']
})
export class MaintainanceComponent implements OnInit {
    background = new Background();
  constructor(private settingService: SettingService) { }

  ngOnInit() {
      this.settingService.getBackground()
          .subscribe(data => {
              if (data) {
                  this.background = data;
              }
          })
  }
}
